import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import SelectCustom from '../../../../components/selectCustom/SelectCustom';
import './style.scss';
import ModalCreatives from '../modal-Creatives/ModalCreatives';
import { useQuery } from 'react-query';
import { processPromotionData } from './dataProcessor';
import { fetchUserData } from './PromotionsApi';
import Loader from '../../../../components/loader';
import ResponseError from '../../../../components/response-error';
import EmptyList from '../../../../components/empty-list';

const osValues = {
  webapp: 'Web App',
  android: 'Android',
  ios: 'iOS'
};
const AppOS = ({ os }) => {
  if (!os) return '-';
  const osValue = osValues[os.toLowerCase()];

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4rem' }}>
      {osValue && (
        <span
          className={`icon-os`}
          style={{
            background: `url(${process.env.PUBLIC_URL}/img/${os.toLowerCase()}.svg)`
          }}></span>
      )}
      {osValues[os.toLowerCase()] || os}
    </div>
  );
};

export default function AllPromotions({ activeUserId, isActive, setCount }) {
  const [groupPromotions, setGroupPromotions] = useState([]);
  const [blockIndex, setBlockIndex] = useState(0);

  const [rowsPromotions, setRowsPromotions] = useState([]);

  const sorts = [
    { value: 'createdAt', label: 'Newest' },
    { value: 'createdAt1', label: 'Oldest' }
  ];

  const defaultValue = { value: 'createdAt', label: 'Newest' };
  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });
  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value == 'createdAt1') {
      setSelectedSort({ ['createdAt']: 'asc' });
    } else {
      setSelectedSort({ ['createdAt']: 'desc' });
    }
  };

  const [openBlock, setOpenBlock] = useState(false);

  const handleOpenBlock = (value) => {
    setBlockIndex(value);
    setOpenBlock(true);
  };

  const {
    isLoading,
    error,
    data: promotionData
  } = useQuery(
    ['promotionFacebook', activeUserId, selectedSort],
    () => fetchUserData(activeUserId, selectedSort),
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );
  useEffect(() => {
    if (!isLoading && !error && promotionData && promotionData.items) {
      const { groupedAds, dataRow } = processPromotionData(promotionData);
      setGroupPromotions(groupedAds);
      setRowsPromotions(dataRow);
      setCount(dataRow.length);
    }
  }, [isLoading, error, promotionData]);

  if (!isActive) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <ResponseError refetch={refetch} />;
  }
  if (!rowsPromotions || !rowsPromotions.length) {
    return <EmptyList />;
  }

  return (
    <div className="table" style={{ marginBottom: '40rem' }}>
      <div className="table-top">
        <div className="table-top-right">
          <SelectCustom
            sorts={sorts}
            onSelectChange={handleSelectChange}
            defaultValue={defaultValue}
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>General</TableCell>
              <TableCell></TableCell>
              <TableCell>Platform</TableCell>
              <TableCell>Start date & end date</TableCell>
              <TableCell>Total spent</TableCell>
              <TableCell>Budget </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsPromotions.map((row, index) => (
              <TableRow
                className="table-row"
                onClick={() => handleOpenBlock(index)}
                key={row.facebookAdSetId}>
                <TableCell>
                  <div className="promotionsNew-AllPromotions-img">
                    <img src={row.promationImg} alt="" />
                    <img src={process.env.PUBLIC_URL + `/img/pimg.png`} alt="" />
                    <span>{row.promationCreatives}</span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="promotionsNew-AllPromotions-info">
                    <p>{row.promationPrimaryText}</p>
                    <div className="promotionsNew-AllPromotions-info-bottom">
                      <div
                        className={`promotionsNew-AllPromotions-status ${row.promationStatus === 'Active' ? 'promotionsNew-AllPromotions-status--Active' : ''} ${row.promationStatus === 'Rejected' || row.promationStatus === 'Disapproved' ? 'promotionsNew-AllPromotions-status--Rejected' : ''} ${row.promationStatus === 'Pending_review' || row.promationStatus === 'In_process' ? 'promotionsNew-AllPromotions-status--Pending' : ''}`}>
                        <span></span>
                        {row.promationStatus}
                      </div>
                      <div className="promotionsNew-AllPromotions-website">
                        {row.promationWebsite}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <AppOS os={row.appOs} />
                </TableCell>
                <TableCell>
                  <div
                    className="promotionsNew-AllPromotions-date"
                    dangerouslySetInnerHTML={{ __html: row.promationDate }}></div>
                </TableCell>
                <TableCell>
                  <span>{row.promationSpent}</span>
                </TableCell>
                <TableCell>
                  <span>
                    {row.promationBudget}
                    <br />(
                    {row.differenceDays
                      ? `$${Number(row.budget / row.differenceDays).toFixed(2)}/day`
                      : '$0/day'}
                    )
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalCreatives
        blockIndex={blockIndex}
        openBlock={openBlock}
        setOpenBlock={setOpenBlock}
        groupPromotions={groupPromotions}
      />
    </div>
  );
}
