import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../../../config';

export const fetchUserData = async (activeUserId, selectedSort) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/facebook-ads`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const skip = 1 * 20 - 20;
  const params = {
    skip: skip,
    limit: 200,
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, {
    headers,
    params
  });
  return response.data;
};
