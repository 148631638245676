import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import PromotionsPageCreatives from './PromotionsPageCreatives';

export default function ModalCreatives({ openBlock, setOpenBlock, groupPromotions, blockIndex }) {
  const styleBlock = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    outline: 'none',
    maxHeight: '90vh',
    height: '95vh',
    // overflow: 'hidden',
    borderRadius: '16rem'
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };
  <div className="modal-close">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round"></path>
      <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round"></path>
    </svg>
  </div>;
  return (
    <Modal
      keepMounted
      open={openBlock}
      onClose={handleCloseBlock}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className="modal"
    >
      <Box sx={styleBlock} className="modal-container">
        <div className="creatives-modal-close" onClick={handleCloseBlock}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 10L26 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M26 10L10 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
        <div style={{ overflow: 'hidden', width: '100%' }}>
          <PromotionsPageCreatives groupPromotions={groupPromotions} blockIndex={blockIndex} />
        </div>
      </Box>
    </Modal>
  );
}
