import React, { useState, useEffect } from 'react';
import Filter from '../../components/filter/Filter';
import Header from '../../components/header/Header';
import Search from '../../components/search/Search';

import './promotions.scss';
import axios from 'axios';

import { useQuery, useQueryClient } from 'react-query';
import Cookies from 'js-cookie';
import config from '../../config';
import PromotionsTable from './components/promotions-table/PromotionsTable';
import PromotionsFilter from './components/promotions-filter/PromotionsFilter';
import SideBarPromotionsHistory from '../../components/sidebar/SideBarPromotionsHistory.jsx';
import Sidebar from 'react-sidebar';

const fetchPromationData = async (activePage, selectedSort, filter) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
    // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkremVCJ9.eyJpZCI6IjA5YjIwNzc3LTVlODEtNDM1NC1hMzk1LWNjNDllMmEwNDhhMSIsImVtYWlsIjoiYWRtaW5AemVlbHkuYXBwIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNzExMzQyNjAzLCJleHAiOjE3MTE0MjkwMDN9.GW3yB8N8VJcXojgDedxnKp1qNfUAedFA44eQnmnjvSw`,
  };

  const skip = activePage * 10 - 10;
  const url = `${config.apiBaseUrl}admins/facebook-ads`;

  const params = {
    skip: skip,
    limit: 10,
    // filter:  JSON.stringify({"createdAt": {"from": "12-17-2023", "to": "12-18-2023"}}),
    // filter:  JSON.stringify({"pushToken":true}),
    // filter: JSON.stringify({createdAt: '21.10.23'}),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, {
    headers,
    params
  });
  return response.data.data;
};

const PromotionsPageAllNew = ({ status, promationsPageTotal }) => {
  const [total, setTotal] = useState('0');
  const pages = Math.ceil(total / 10);
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  function createData(
    email,
    promationImg,
    promationPrimaryText,
    promationHeadlineText,
    promationSite,
    promationBudget,
    promationDailyBudget,
    promationDuration,
    promationLocation,
    promationDate,
    promationStatus,
    promationId,
    promationAction,
    index,
    promationRejectTitle,
    promationRejectDescription,
    promationRejectedAt,
    adminEmail,
    userId,
    leadFormUrl,
    promationTime,
    promationAds,
    promotionData
  ) {
    return {
      email,
      promationImg,
      promationPrimaryText,
      promationHeadlineText,
      promationSite,
      promationBudget,
      promationDailyBudget,
      promationDuration,
      promationLocation,
      promationDate,
      promationStatus,
      promationId,
      promationAction,
      index,
      promationRejectTitle,
      promationRejectDescription,
      promationRejectedAt,
      adminEmail,
      userId,
      leadFormUrl,
      promationTime,
      promationAds,
      promotionData
    };
  }

  const [rows, setRows] = useState([
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      1,
      1,
      '5 days',
      'New York',
      '12.02.2024',
      'APPROVED',
      'ssadsa',
      'sadsa',
      0,
      'sadsa',
      'sadsa'
    )
  ]);

  const sorts = [
    { value: 'createdAt', label: 'Newest' },
    { value: 'createdAt1', label: 'Oldest' }
  ];

  const defaultValue = { value: 'createdAt', label: 'Newest' };
  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });
  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value == 'createdAt1') {
      setSelectedSort({ ['createdAt']: 'asc' });
    } else {
      setSelectedSort({ ['createdAt']: 'desc' });
    }
  };

  const [activeFilters, setActiveFilters] = useState({});

  const getActivePlatform = (filters) => {
    const activePlatform = Object.keys(filters).find(
      (key) => filters[key] === true && (key === 'android' || key === 'iOS')
    );
    return activePlatform || null;
  };

  const activePlatform = getActivePlatform(activeFilters);

  const [searchTerm, setSearchTerm] = useState('');

  const filter = {};

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  if (searchTerm !== '') {
    filter.email = searchTerm;
  }
  if (status == 'rejected') {
    filter.status = 'REJECTED';
  }

  if (status == 'approved') {
    filter.status = 'APPROVED';
  }

  if (status == 'deffered') {
    filter.status = ['ERROR', 'SKIPED', 'PROCESSING'];
  }

  if (activePlatform !== null) {
    filter.os = activePlatform;
  }

  if (activeFilters.createdDate == true) {
    filter.createdAt = { from: fromData, to: toData };
  }

  const [moderId, setModerId] = useState('');

  if (moderId !== '') {
    filter.adminIds = [moderId];
  }

  const {
    isLoading: isPromationAllLoading,
    error: promationAllError,
    data: promationAllData
  } = useQuery(['promationAllData', activePage, selectedSort, filter], () =>
    fetchPromationData(activePage, selectedSort, filter)
  );

  const [loading, setLoading] = useState(false);

  const rowsLoading = [
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Succeeded'
    ),
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Succeeded'
    ),
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Rejected'
    ),
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Rejected'
    ),
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Succeeded'
    ),
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Rejected'
    ),
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Succeeded'
    ),
    createData(
      'jamesmaddison@gmail.com',
      'creative3.jpg',
      'Elegant Gemstone Bracelet - Your Path to True Beauty!',
      'Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!',
      'https://test2212.zee.am/',
      '$10.00',
      '5 days',
      'New York',
      '12.02.2024',
      'Succeeded'
    )
  ];

  useEffect(() => {
    // setRows(rowsLoading);
    if (isPromationAllLoading) {
      setRows(rowsLoading);
      setLoading(true);
    }
    if (
      !isPromationAllLoading &&
      !promationAllError &&
      promationAllData &&
      promationAllData.items
    ) {
      setTotal(promationAllData.total);
      const data = promationAllData.items.map((ads, index) => {
        const inputDateString = ads.createdAt;
        const date = new Date(inputDateString);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
        var hours = date.getHours();
        var minutes = date.getMinutes();
        hours = (hours < 10 ? '0' : '') + hours;
        minutes = (minutes < 10 ? '0' : '') + minutes;
        var formattedTime = hours + ':' + minutes;
        const targetingLocations = ads.body.targetingLocations;
        let adminEmail = '';
        if (ads.admin) {
          adminEmail = ads.admin.email;
        }
        let promationAds = [];
        if (ads.body.ads) {
          promationAds = ads.body.ads;
        }
        return createData(
          ads.user.email,
          ads.images,
          ads.body.primaryText,
          ads.body.headline,
          ads.body.websiteUrl,
          ads.body.budget,
          ads.body.dailyBudget,
          ads.body.budget / ads.body.dailyBudget,
          targetingLocations.map((location) => location.name).join(', '),
          formatDateString(ads.createdAt),
          ads.status,
          ads.id,
          ads.body.actionButton,
          index,
          ads.rejectTitle,
          ads.rejectDescription,
          formatDate(ads.moderatedAt),
          adminEmail,
          ads.userId,
          ads.body.leadFormUrl,
          formattedTime,
          promationAds,
          ads
        );
      });
      setRows(data);

      setLoading(false);
    }
  }, [isPromationAllLoading, promationAllError, promationAllData]);

  const formatDateString = (inputDateString) => {
    const date = new Date(inputDateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    return formattedDate;
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year} at ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

    return formattedDate;
  };

  const [blockIndex, setblockIndex] = useState(0);

  const handleOpenImg = (index) => {
    setblockIndex(index);
    setOpen(true);
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  return (
    <Sidebar
      sidebar={<SideBarPromotionsHistory onSetSidebarOpen={onSetSidebarOpen} />} // Замените этот div на ваше содержимое
      open={sidebarOpen}
      onSetOpen={onSetSidebarOpen}
      pullRight={true}
      docked={false}
      sidebarClassName="sidebar  promotions-sidebar"
    >
      <div className="main promotionsPage">
        <Header />
        <div className="content">
          <div className="container">
            <div className="users-search">
              <Search onSearchChange={handleSearchChange} />
              <PromotionsFilter
                showStatus={true}
                onSetOpen={onSetSidebarOpen}
                setModerId={setModerId}
              />
              {/* <Filter setActiveFilters={setActiveFilters} additionalValues={additionalValues} setFormData={setFormData} setToData={setToData} /> */}
            </div>

            <PromotionsTable
              pages={pages}
              activePage={activePage}
              handlePageChange={handlePageChange}
              sorts={sorts}
              handleSelectChange={handleSelectChange}
              defaultValue={defaultValue}
              rows={rows}
              loading={loading}
              status={status}
              onSetOpen={onSetSidebarOpen}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default PromotionsPageAllNew;
