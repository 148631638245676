import React, { useState, useEffect } from 'react';
import './creatives.scss';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useQuery } from 'react-query';
import config from '../../config';
import Loader from '../../components/loader';
import EmptyList from '../../components/empty-list';
import ResponseError from '../../components/response-error';

const osValues = {
  webapp: 'Web App',
  android: 'Android',
  ios: 'iOS'
};
const AppOS = ({ os }) => {
  if (!os) return '-';
  const osValue = osValues[os.toLowerCase()];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4rem',
        fontSize: '12rem',
        padding: '0 16rem',
        marginBottom: '16rem'
      }}>
      {osValue && (
        <span
          className={`icon-os`}
          style={{
            background: `url(${process.env.PUBLIC_URL}/img/${os.toLowerCase()}.svg)`,
            margin: '0'
          }}></span>
      )}
      {osValues[os.toLowerCase()] || os}
    </div>
  );
};

const fetchUserData = async (activeUserId, activePage, selectedSort, total) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/creatives`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const skip = activePage * 20 - 20;
  const params = {
    skip: skip,
    limit: 1000,
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, { headers, params });
  return response.data;
};

function Creatives({ activeUserId, isActive, setCount }) {
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [imgModal, setImgModal] = React.useState('');
  const [unsplashPhotos, setUnsplashPhotos] = useState([]);
  const handleOpen = (img) => {
    setImgModal(img);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 475
  };

  const sorts = [{ value: 'createdAt', label: 'Created Date' }];

  const defaultValue = { value: 'createdAt', label: 'Created Date' };

  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });

  const handleSelectChange = (selectedOption) => {
    if (
      selectedOption.value == 'price' ||
      selectedOption.value == 'oldPrice' ||
      selectedOption.value == 'title'
    ) {
      setSelectedSort({ [selectedOption.value]: 'asc' });
    } else {
      setSelectedSort({ [selectedOption.value]: 'desc' });
    }
  };

  const [total, setTotal] = useState('0');

  const {
    isLoading: isLoading,
    error: error,
    data: creativeData,
    isFetching,
    refetch
  } = useQuery(['creativeData', activeUserId, activePage, selectedSort, total], () =>
    fetchUserData(activeUserId, activePage, selectedSort, total)
  );

  useEffect(() => {
    if (!isLoading && !error && creativeData && creativeData.items) {
      const data = creativeData.items
        .filter((image) => !image.isDeleted)
        .filter((image) => image.creativeImages && image.creativeImages[0])
        .map((image) => {
          const inputDateString = image.createdAt;
          const inputDate = new Date(inputDateString);
          const formattedDate = `${inputDate.getDate()}.${(inputDate.getMonth() + 1).toString().padStart(2, '0')}.${inputDate.getFullYear()}`;

          let platform = '';
          let format = '';

          platform = image.creativeImages[0].creativeTheme?.creativeFormat?.platform || '';
          format = image.creativeImages[0].creativeTheme?.creativeFormat?.format || '';

          return {
            id: image.id,
            creativeImages: image.creativeImages,
            src: image.creativeImages[0].url,
            width: image.creativeImages[0].width,
            height: image.creativeImages[0].height,
            social: `${platform} ${format}`,
            title: image.data.title,
            date: formattedDate,
            ai: image.isAIGenerated,
            version: image.version,
            appOs: image.appOs
          };
        });
      let countAiTrue = 0;

      data.forEach((item) => {
        if (item?.ai === true) {
          countAiTrue++;
        }
      });

      setCount(data.length);
      setTotal(data.length);
      setUnsplashPhotos(data);
    }
  }, [isLoading, error, creativeData]);

  if (!isActive) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <ResponseError refetch={refetch} />;
  }
  if (!unsplashPhotos || !unsplashPhotos.length) {
    return <EmptyList />;
  }
  return (
    <div className="creatives">
      <div className="table">
        <div className="table-top">
          <div className="table-top-right">
            <SelectCustom
              sorts={sorts}
              onSelectChange={handleSelectChange}
              defaultValue={defaultValue}
            />
          </div>
        </div>
        <div className="creatives-album">
          {unsplashPhotos.map((photo) => (
            <div
              className="creatives-item"
              onClick={() => handleOpen(photo.creativeImages)}
              key={photo.id}>
              <div className="creatives-item__img">
                <img src={photo.src} style={{ width: '100%', padding: 0 }} />

                <div className="creatives-item__ai">
                  {photo.version ? <span>v{photo.version}.0</span> : null}
                  {photo.ai ? (
                    <span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1255_24490)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.61659 1.08422L0.851074 5.19206C0.362403 5.72515 0.339597 6.53629 0.797542 7.096L7.24093 14.9713C7.67741 15.5047 8.49309 15.5047 8.92956 14.9713L15.373 7.096C15.8309 6.53629 15.8081 5.72515 15.3194 5.19206L11.5539 1.08422C11.3473 0.85881 11.0555 0.730469 10.7497 0.730469H5.42075C5.11497 0.730469 4.82322 0.85881 4.61659 1.08422ZM9.32229 2.84017C9.20066 3.61384 8.95587 4.75426 8.5286 5.18389C8.08243 5.63241 6.84492 5.90139 6.06351 6.03154C5.97752 6.04586 5.978 6.20775 6.06403 6.22187C6.8329 6.34812 8.04062 6.60935 8.48683 7.05314C8.9451 7.50901 9.22152 8.77597 9.35193 9.54383C9.36638 9.62893 9.52055 9.62912 9.5352 9.54406C9.66754 8.77553 9.9467 7.5063 10.4027 7.04769C10.8421 6.60596 12.0204 6.33935 12.7834 6.20771C12.8695 6.19285 12.8684 6.02817 12.782 6.01463C12.0004 5.89205 10.7803 5.63704 10.3355 5.19454C9.90511 4.76637 9.652 3.61715 9.52499 2.8395C9.51076 2.75238 9.336 2.75297 9.32229 2.84017Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1255_24490">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      AI generated
                    </span>
                  ) : null}
                </div>
              </div>

              <div>
                <div className="creatives-item-info">
                  <div className="creatives-item-top">
                    <div className="creatives-item__social"></div>
                    <div className="creatives-item__size"></div>
                  </div>
                  <div className="creatives-item__title">{photo.title}</div>
                  <div className="creatives-item__date">Creation date: {photo.date}</div>
                  <AppOS os={photo.appOs} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {open && (
          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            className="modal">
            <Box sx={style} className="modal-container">
              <div className="creatives-modal-close" onClick={handleClose}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 10L26 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
                  <path d="M26 10L10 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </div>
              <div className="creatives-modal__img">
                <img src={`${imgModal[2].url}`} alt="" />
                <img src={`${imgModal[1].url}`} alt="" />
                <img src={`${imgModal[0].url}`} alt="" />
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default Creatives;
