import React, { useState, useEffect } from 'react';
import './products.scss';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import Cookies from 'js-cookie';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import config from '../../config';
import ProductsTable from './components/products-table/ProductsTable';
import ProductsInfo from './components/products-info/ProductsInfo';
import ModalProduct from './components/modal-product/ModalProduct';
import Loader from '../../components/loader';
import ResponseError from '../../components/response-error';
import EmptyList from '../../components/empty-list';

const fetchUserData = async (activeUserId, activePage, selectedSort) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/external-products`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const skip = activePage * 20 - 20;
  const params = {
    skip: skip,
    limit: 20,
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, { headers, params });
  return response.data;
};

const fetchStatData = async (activeUserId) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/external-products-statistic`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

const Products = ({ activeUserId, isActive, setCount }) => {
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const sorts = [{ value: 'createdAt', label: 'Created Date' }];

  const defaultValue = { value: 'createdAt', label: 'Created Date' };

  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });

  const handleSelectChange = (selectedOption) => {
    if (
      selectedOption.value == 'price' ||
      selectedOption.value == 'oldPrice' ||
      selectedOption.value == 'title'
    ) {
      setSelectedSort({ [selectedOption.value]: 'asc' });
    } else {
      setSelectedSort({ [selectedOption.value]: 'desc' });
    }
  };

  const [total, setTotal] = useState('');
  const pages = Math.ceil(total / 20);

  const {
    isLoading: isLoading,
    isFetching,
    error: error,
    data: productsData,
    refetch
  } = useQuery(['productsData', activeUserId, activePage, selectedSort], () =>
    fetchUserData(activeUserId, activePage, selectedSort)
  );

  useEffect(() => {
    if (productsData?.data?.total) {
      setCount(productsData?.data?.total);
    }
  }, [productsData?.data?.total]);

  const {
    isLoading: productsStatLoading,
    error: statError,
    data: productsStatData
  } = useQuery(['productsStatData', activeUserId], () => fetchStatData(activeUserId));

  useEffect(() => {
    if (!productsStatLoading && productsStatData) {
      setTotal(productsStatData.data.total);
    }
  }, [productsStatData]);

  const [openBlock, setOpenBlock] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleOpenBlock = (row) => {
    setOpenBlock(true);
    setModalData(row);
  };

  if (!isActive) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <ResponseError refetch={refetch} />;
  }
  if (!productsData?.data?.items || !productsData?.data?.items.length) {
    return <EmptyList />;
  }

  return (
    <div className="products">
      <div className="table">
        <div className="table-top">
          <div className="table-top-right">
            <SelectCustom
              sorts={sorts}
              onSelectChange={handleSelectChange}
              defaultValue={defaultValue}
            />
          </div>
        </div>

        <ProductsTable
          rows={productsData?.data?.items}
          isLoading={isLoading || isFetching}
          handleOpenBlock={handleOpenBlock}
          refetch={refetch}
          error={error}
        />
        <div className="table-bottom">
          <div className="pagination">
            <Stack spacing={2}>
              <Pagination
                count={pages}
                siblingCount={1}
                page={activePage}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem slots={{ previous: ArrowBack, next: ArrowNext }} {...item} />
                )}
              />
            </Stack>

            <div className="pagination__page">20/Page</div>
          </div>
        </div>
      </div>
      <ModalProduct data={modalData} openBlock={openBlock} setOpenBlock={setOpenBlock} />
    </div>
  );
};

export default Products;
