import React, { useState, useRef, useEffect } from 'react';
import './filter.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Arrow from '../../svg/Arrow';
import Tick from '../../svg/Tick';
import CloseIcon from '../../svg/CloseIcon';
import DatePicker from 'react-datepicker';
import zIndex from '@mui/material/styles/zIndex';

const FilterPromotions = ({ setActiveFilters, additionalValues, setFormData, setToData }) => {
  const [startDate, setStartDate] = useState(new Date('2023-01-1'));
  const [endDate, setEndDate] = useState(new Date());
  const [endDateRequest, setEndDateRequest] = useState(new Date());

  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const monthStart = padZero(startDate.getMonth() + 1); // Месяцы в JavaScript нумеруются с 0
  const dayStart = padZero(startDate.getDate());
  const yearStart = startDate.getFullYear();
  const yearStartShort = padZero(startDate.getFullYear() % 100);
  const startDateFormattedDate = `${dayStart}.${monthStart}.${yearStartShort}`;
  setFormData(`${monthStart}-${dayStart}-${yearStart}`);
  //const startDateFormattedDate = `${monthStart}-${dayStart}-${yearStart}`;

  const monthEnd = padZero(endDateRequest.getMonth() + 1); // Месяцы в JavaScript нумеруются с 0
  const dayEnd = padZero(endDateRequest.getDate());
  const yearEnd = endDateRequest.getFullYear();
  const yearEndShort = padZero(endDateRequest.getFullYear() % 100);
  //const endDateFormattedDate = `${monthEnd}-${dayEnd}-${yearEnd}`;
  const endDateFormattedDate = `${dayEnd}.${monthEnd}.${yearEndShort}`;
  setToData(`${monthEnd}-${dayEnd}-${yearEnd}`);
  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    setStartDate(firstDayOfMonth);
  }, []);

  const [isBlockVisible, setIsBlockVisible] = useState(false);
  const dateShow = () => {
    setIsBlockVisible(true);
  };

  const dateHide = () => {
    setIsBlockVisible(false);
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      ['createdDate']: true
    }));
  };
  const dataPickerLogoutRef = useRef(null);

  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const profileLogoutRef = useRef(null);

  const handleProfileWrapClick = () => {
    setIsLogoutVisible(!isLogoutVisible);
  };

  const handleClickOutside = (event) => {
    if (profileLogoutRef.current && !profileLogoutRef.current.contains(event.target)) {
      setIsLogoutVisible(false);
    }
  };

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate) {
      setEndDateRequest(newEndDate);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isVisibleMore, setIsVisibleMore] = useState(false);
  const [countMore, setCountMore] = useState(0);

  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const opacityValue = isVisibleMore ? 1 : 0;
  const pointerValue = isVisibleMore ? 'auto' : 'none';

  const [selectedFilters, setSelectedFilters] = useState({
    android: false,
    iOS: false,
    pushTokenYes: false,
    pushTokenNo: false,
    createdDate: false
  });

  setActiveFilters(selectedFilters);

  const clear = () => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      Object.keys(updatedFilters).forEach((key) => {
        updatedFilters[key] = false;
      });
      return updatedFilters;
    });
  };

  const [valueLabelMap, setValueLabelMap] = useState({
    android: 'Android',
    iOS: 'iOS',
    pushTokenYes: 'Push-Token: Yes',
    pushTokenNo: 'Push-Token: No',
    createdDate: `${startDateFormattedDate} - ${endDateFormattedDate}`
  });

  const updateDateLabels = () => {
    setValueLabelMap((prevValues) => ({
      ...prevValues,
      createdDate: `${startDateFormattedDate} - ${endDateFormattedDate}`
    }));
  };

  useEffect(() => {
    updateDateLabels();
  }, [startDate, endDate]);

  const handleCheckboxChange = (filterKey) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey]
    }));
  };

  const handleRadioChange = (filterKey) => {
    if (filterKey == 'android') {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        android: !prevFilters[filterKey],
        iOS: false
      }));
    }
    if (filterKey == 'iOS') {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        iOS: !prevFilters[filterKey],
        android: false
      }));
    }
  };

  const filterData = () => {
    const filterOrder = [];
    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key]) {
        filterOrder.push(key);
      }
    });
    const filteredResults = {};
    filterOrder.forEach((key) => {
      filteredResults[key] = valueLabelMap[key];
    });
    return filteredResults;
  };

  const filteredResults = filterData();

  const filterResultsRef = useRef(null);

  useEffect(() => {
    const updatedValueLabelMap = { ...valueLabelMap };
    const updatedSelectedFilters = { ...selectedFilters };

    additionalValues.forEach((value) => {
      updatedValueLabelMap[value] = value;
      updatedSelectedFilters[value] = false;
    });

    setValueLabelMap(updatedValueLabelMap);
    setSelectedFilters(updatedSelectedFilters);
  }, [additionalValues]);

  // function getPlans() {
  //     const url = `https://staging.zeely.link/admins/subscriptions/pricing-plans`;
  //     const headers = {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${Cookies.get('authToken')}`,
  //     };

  //     axios.get(url, { headers })
  //         .then(response => {
  //
  //             console.log(selectedFilters);
  //         })
  //         .catch(error => {

  //         });
  // }
  // getPlans();

  useEffect(() => {
    const filterResultsElement = filterResultsRef.current;

    if (filterResultsElement) {
      const items = filterResultsElement.querySelectorAll('.filter-results-item');
      items.forEach((item) => {
        item.style.display = 'flex';
      });

      let currentIndex = 0;
      setIsVisibleMore(false);
      setCountMore(0);
      const showNextItem = () => {
        if (currentIndex < items.length) {
          if (filterResultsElement.clientHeight <= 50) {
            currentIndex++;
            showNextItem();
          } else {
            items[currentIndex].style.display = 'none';
            let count = items.length - (items.length - currentIndex);
            setCountMore(count);
            setIsVisibleMore(true);
            if (count == 0) {
              setIsVisibleMore(false);
            }
            currentIndex++;
            showNextItem();
          }
        }
      };
      showNextItem();
    }
  }, [filteredResults]);
  const isAllFalse = Object.values(selectedFilters).every((value) => value === false);

  return (
    <div className="filter" style={{ zIndex: '5' }}>
      <div className="analytics-top-date-wrap promotionsPage-date-wrap">
        <div className={`filter-btn`} onClick={dateShow}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3265_44337)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.3977 5.55156H3.59766C2.93491 5.55156 2.39766 6.08882 2.39766 6.75156V14.5516C2.39766 15.2143 2.93491 15.7516 3.59766 15.7516H14.3977C15.0604 15.7516 15.5977 15.2143 15.5977 14.5516V6.75156C15.5977 6.08882 15.0604 5.55156 14.3977 5.55156ZM3.59766 1.35156C1.9408 1.35156 0.597656 2.69471 0.597656 4.35156V14.5516C0.597656 16.2084 1.9408 17.5516 3.59766 17.5516H14.3977C16.0545 17.5516 17.3977 16.2084 17.3977 14.5516V4.35156C17.3977 2.69471 16.0545 1.35156 14.3977 1.35156H3.59766Z"
                fill="black"
              />
              <rect x="3.59766" y="0.453125" width="1.8" height="2.4" rx="0.9" fill="black" />
              <rect x="12.5977" y="0.453125" width="1.8" height="2.4" rx="0.9" fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_3265_44337">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Filter by date
        </div>

        {isBlockVisible && (
          <div className="analytics-top-date-block" ref={dataPickerLogoutRef}>
            <DatePicker
              selected={startDate}
              onChange={handleChange}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              inline
            />
            <div className="analytics-top-date__btn" onClick={dateHide}>
              Select
            </div>
          </div>
        )}
      </div>
      {isLogoutVisible && (
        <div className="filter-dropdown" ref={profileLogoutRef}>
          <div className="filter-dropdown-top">
            <div className="filter-dropdown__title">Filters</div>
            <div className="filter-dropdown__close" onClick={clear}>
              Clear all
            </div>
          </div>
          <div className="filter-dropdown-accordion">
            <Accordion>
              <AccordionSummary>
                OS
                <Arrow />
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  <input
                    type="checkbox"
                    value="Android"
                    checked={selectedFilters.android}
                    onChange={() => handleRadioChange('android')}
                  />
                  <div className="filter-dropdown-checkbox">
                    <Tick />
                  </div>
                  Android
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="iOS"
                    checked={selectedFilters.iOS}
                    onChange={() => handleRadioChange('iOS')}
                  />
                  <div className="filter-dropdown-checkbox">
                    <Tick />
                  </div>
                  iOS
                </label>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                Push-Token
                <Arrow />
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  <input
                    type="checkbox"
                    value="Push-Token: Yes"
                    checked={selectedFilters.pushTokenYes}
                    onChange={() => handleCheckboxChange('pushTokenYes')}
                  />
                  <div className="filter-dropdown-checkbox">
                    <Tick />
                  </div>
                  Yes
                </label>
                {/* <label>
                                    <input
                                        type="checkbox"
                                        value='Push-Token: No'
                                        checked={selectedFilters.pushTokenNo}
                                        onChange={() => handleCheckboxChange('pushTokenNo')}
                                    />
                                    <div className="filter-dropdown-checkbox">
                                        <Tick />
                                    </div>
                                    No
                                </label> */}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                Created date
                <Arrow />
              </AccordionSummary>
              <AccordionDetails>
                <div className="createdAt">
                  <label>
                    <input
                      type="checkbox"
                      value="21.07.2023"
                      checked={selectedFilters.createdDate}
                      onChange={() => handleCheckboxChange('createdDate')}
                    />
                    <div className="filter-dropdown-checkbox">
                      <Tick />
                    </div>
                  </label>
                  <div className="analytics-top-date-wrap">
                    <div className="analytics-top-date" onClick={dateShow}>
                      {/* <div className="analytics-top-date-icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.7979 4.93255H3.19792C2.60881 4.93255 2.13125 5.41012 2.13125 5.99922V12.9326C2.13125 13.5217 2.60881 13.9992 3.19792 13.9992H12.7979C13.387 13.9992 13.8646 13.5217 13.8646 12.9326V5.99922C13.8646 5.41012 13.387 4.93255 12.7979 4.93255ZM3.19792 1.19922C1.72516 1.19922 0.53125 2.39313 0.53125 3.86589V12.9326C0.53125 14.4053 1.72516 15.5992 3.19792 15.5992H12.7979C14.2707 15.5992 15.4646 14.4053 15.4646 12.9326V3.86589C15.4646 2.39313 14.2707 1.19922 12.7979 1.19922H3.19792Z" fill="black" />
                                                    <rect x="3.19824" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                                    <rect x="11.1982" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                                </svg>
                                            </div> */}
                      <div className="analytics-top-date-text">
                        {startDateFormattedDate} -{endDateFormattedDate}
                      </div>
                      <div className="analytics-top-date-arrow">
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 1L6 6L1 1"
                            stroke="#A7A8AA"
                            strokeWidth="1.9"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {isBlockVisible && (
                      <div className="analytics-top-date-block" ref={dataPickerLogoutRef}>
                        <DatePicker
                          selected={startDate}
                          onChange={handleChange}
                          selectsRange
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          inline
                        />
                        <div className="analytics-top-date__btn" onClick={dateHide}>
                          Select
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                Subscription rate
                <Arrow />
              </AccordionSummary>
              <AccordionDetails>
                {additionalValues.map((value) => (
                  <label key={value}>
                    <input
                      type="checkbox"
                      value={value}
                      checked={selectedFilters[value]}
                      onChange={() => handleCheckboxChange(value)}
                    />
                    <div className="filter-dropdown-checkbox">
                      <Tick />
                    </div>
                    {value}
                  </label>
                ))}
                {/* <label>
                                    <input
                                        type="checkbox"
                                        value='Growth'
                                        checked={selectedFilters.Growth}
                                        onChange={() => handleCheckboxChange('Growth')}
                                    />
                                    <div className="filter-dropdown-checkbox">
                                        <Tick />
                                    </div>
                                    Growth
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        value='Starter'
                                        checked={selectedFilters.Starter}
                                        onChange={() => handleCheckboxChange('Starter')}
                                    />

                                    <div className="filter-dropdown-checkbox">
                                        <Tick />
                                    </div>
                                    Starter
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        value='Free'
                                        checked={selectedFilters.Free}
                                        onChange={() => handleCheckboxChange('Free')}
                                    />

                                    <div className="filter-dropdown-checkbox">
                                        <Tick />
                                    </div>
                                    Free
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        value='Refund'
                                        checked={selectedFilters.Refund}
                                        onChange={() => handleCheckboxChange('Refund')}
                                    />

                                    <div className="filter-dropdown-checkbox">
                                        <Tick />
                                    </div>
                                    Refund
                                </label> */}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
      <div className={`filter-results ${isActive ? 'active' : ''}`} ref={filterResultsRef}>
        {Object.entries(filteredResults).map(([key, value], index) => (
          <div key={key}>
            {value && (
              <div className={`filter-results-item`}>
                {value}
                <label>
                  <input
                    type="checkbox"
                    checked={selectedFilters[key]}
                    onChange={() => handleCheckboxChange(key)}
                    value={key}
                  />
                  <CloseIcon />
                </label>
              </div>
            )}
          </div>
        ))}
        <div onClick={toggleActive} className={`filter-results-less ${isActive ? 'active' : ''}`}>
          Less
          <Arrow />
        </div>
      </div>

      <div
        onClick={toggleActive}
        className={`filter-results-more ${isActive ? 'active' : ''}`}
        style={{ opacity: opacityValue, pointerEvents: pointerValue }}
      >
        More
        <span>{countMore}</span>
        <Arrow />
      </div>
      {isAllFalse ? (
        <div style={{ display: 'none' }}></div>
      ) : (
        <div className="filter-result-clear" onClick={clear}>
          Clear
        </div>
      )}
    </div>
  );
};

export default FilterPromotions;
