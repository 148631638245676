import './index.scss';

const EmptyList = () => {
  return (
    <div className="empty-list">
      <img
        className="empty-list__icon"
        src={process.env.PUBLIC_URL + '/img/plastic-shopping-basket.png'}
        alt=""
      />
      <div className="empty-list__title">Creatives not found</div>
    </div>
  );
};

export default EmptyList;
