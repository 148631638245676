import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import './snackbar.scss';

const Snackbar = ({
  snackbarOpen,
  setSnackbarOpen,
  snackbarText,
  snackbarCount,
  setSnackbarCount
}) => {
  const [value, setValue] = useState(100);
  const [count, setCount] = useState(snackbarCount);

  useEffect(() => {
    setCount(snackbarCount);
    setValue(100);
  }, [snackbarCount, snackbarText]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (value > 0) {
        // Пересчитываем value в зависимости от snackbarCount
        setValue((prevValue) => {
          const newValue = prevValue - 10 / snackbarCount;
          return newValue < 0 ? 0 : newValue;
        });
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [value, snackbarCount]);

  useEffect(() => {
    const timerCount = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        setSnackbarOpen(false);
      }
    }, 1000);
    return () => {
      clearInterval(timerCount);
    };
  }, [count]);

  return (
    <div className="snackbar">
      <div className="snackbar-progress">
        <div className="snackbar-progress-count">{count}</div>
        <div className="snackbar-progress-main">
          <Stack spacing={2} direction="row">
            <CircularProgress
              variant="determinate"
              value={value}
              size={30}
              thickness={5}
              style={{ color: '#FFF' }}
              sx={{ scale: '-1 1' }}
            />
          </Stack>
        </div>
        <div className="snackbar-progress-bg">
          <Stack spacing={2} direction="row">
            <CircularProgress
              variant="determinate"
              value={100}
              size={30}
              thickness={5}
              style={{ color: '#FFF' }}
            />
          </Stack>
        </div>
      </div>
      <div className="snackbar-text" dangerouslySetInnerHTML={{ __html: snackbarText }}></div>
    </div>
  );
};

export default Snackbar;
