import './search.scss';
import React, { useState } from 'react';
const Search = ({ onSearchChange }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    onSearchChange(newValue); // Вызываем функцию обратного вызова с новым значением
  };

  return (
    <div className="search-input">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle cx="8" cy="8" r="6.25" stroke="black" strokeWidth="1.5" />
        <path d="M12.5 13L15.5 16" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
      <input
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        placeholder="Search user by email, site, number"
      />
    </div>
  );
};

export default Search;
