import './index.scss';

const ResponseError = ({ refetch }) => {
  return (
    <div className="response-error">
      <img
        className="response-error__icon"
        src={process.env.PUBLIC_URL + '/img/icons/attention.svg'}
        alt=""
      />
      <div className="response-error__title">Something went wrong</div>
      <div className="response-error__sub-title">We seem to have encountered an issue</div>
      {refetch && (
        <button type="button" className="response-error__btn" onClick={refetch}>
          <img
            className="response-error__btn__icon"
            src={process.env.PUBLIC_URL + '/img/icons/arrow-rotate.svg'}
            alt=""
          />
          Try again
        </button>
      )}
    </div>
  );
};

export default ResponseError;
