import React from 'react';

const Arrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 6L8 11L3 6" stroke="#ABABAB" strokeWidth="1.9" strokeLinecap="round" />
    </svg>
  );
};

export default Arrow;
