import React, { useState, useEffect } from 'react';
import './history.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules';
import Cookies from 'js-cookie';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import config from '../../config';
import Invoice from './components/invoice/Invoice';
import Snackbar from '../../components/snackbar/Snackbar';

const fetchUserData = async (activeUserId, activePage) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/payments`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const skip = activePage * 20 - 20;
  const params = {
    skip: skip,
    limit: 20
  };
  const response = await axios.get(url, { headers, params });
  return response.data;
};

const History = ({ activeUserId, setSnackbarOpen, setSnackbarText, setSnackbarCount }) => {
  const [total, setTotal] = useState('0');
  const pages = Math.ceil(total / 20);
  const [activePage, setActivePage] = useState(1);
  const [openInvoice, setOpenInvoice] = useState(false);

  useEffect(() => {
    setSnackbarCount(10);
    setSnackbarText('Invoice successfully generated');
  }, []);

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };
  const [rows, setRows] = useState([]);
  function createData(
    amount,
    statusColor,
    status,
    description,
    payment,
    date,
    external,
    invoiceDate,
    invoiceAmount,
    invoicePlan,
    id
  ) {
    return {
      amount,
      statusColor,
      status,
      description,
      payment,
      date,
      date,
      external,
      invoiceDate,
      invoiceAmount,
      invoicePlan,
      id
    };
  }

  function getUsers() {
    const url = `${config.apiBaseUrl}admins/users/${activeUserId}/payments`;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('authToken')}`
    };

    const skip = activePage * 20 - 20;
    const params = {
      skip: skip,
      limit: 20
    };

    axios
      .get(url, { headers, params })
      .then((response) => {
        setTotal(response.data.total);

        const data = response.data.items.map((history) => {
          const date = new Date(history.createdAt);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          };
          const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

          let color;
          if (history.status == 'SUCCESS') {
            color = 'green';
          } else {
            color = 'red';
          }
          return createData(
            `${history.amount} ${history.currency}`,
            color,
            history.status,
            history.description,
            history.provider,
            formattedDate
          );
        });
        setRows(data);
      })
      .catch((error) => {});
  }

  // useEffect(() => {
  //     getUsers();
  //     // getStats();
  // }, [activeUserId]);

  const {
    isLoading: isLoading,
    error: error,
    data: historyData
  } = useQuery(['historyData', activeUserId, activePage], () =>
    fetchUserData(activeUserId, activePage)
  );
  const [showBlock, setShowBlock] = useState(true);
  const rowsLoading = [createData(), createData(), createData(), createData(), createData()];
  useEffect(() => {
    if (isLoading) {
      setRows(rowsLoading);
      // setUnsplashPhotos(unsplashLoading);
      setShowBlock(true);
    }
    if (!isLoading && !error && historyData && historyData.items) {
      if (historyData.total == 0) {
        setShowBlock(false);
      } else {
        setShowBlock(true);
      }
      setTotal(historyData.total);

      const data = historyData.items.map((history) => {
        const date = new Date(history.createdAt);
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        let color;
        if (history.status == 'SUCCESS') {
          color = 'green';
        } else {
          color = 'red';
        }
        return createData(
          `${(history.amount / 100).toFixed(2)}$`,
          color,
          history.status,
          history.description,
          history.provider,
          formattedDate,
          history.externalId,
          convertDate(history.createdAt),
          (history.amount / 100).toFixed(2),
          history.subscriptionPlan,
          history.id
        );
      });
      setRows(data);
    }
  }, [isLoading, error, historyData]);

  const [external, setExternal] = useState('');
  const [date, setDate] = useState('');
  const [amount, setAmount] = useState('');
  const [plan, setPlan] = useState('');
  const [id, setId] = useState('');

  const handleOpenInvoice = (external, date, amount, plan, id) => {
    setOpenInvoice(true);
    setExternal(external);
    setDate(date);
    setAmount(amount);
    setPlan(plan);
    setId(id);
  };

  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="products">
      <div className="table">
        <div className="table-top">
          <div className="sidebars-tab__title">Payment history</div>
        </div>
        <TableContainer component={Paper}>
          <Swiper
            slidesPerView={'auto'}
            freeMode={true}
            scrollbar={true}
            mousewheel={false}
            modules={[FreeMode, Scrollbar, Mousewheel]}
          >
            <SwiperSlide>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {showBlock ? (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell className="table-head-cell">Amount</TableCell>
                        <TableCell className="table-head-cell">Status</TableCell>
                        <TableCell className="table-head-cell">Description</TableCell>
                        <TableCell className="table-head-cell">Payment method</TableCell>
                        <TableCell className="table-head-cell">Date</TableCell>
                        <TableCell className="table-head-cell"></TableCell>
                      </TableRow>
                    </TableHead>{' '}
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell className="table-body-cell">
                            {isLoading ? (
                              <Skeleton variant="rounded" width={'70rem'} height={'17rem'} />
                            ) : (
                              <strong>{row.amount}</strong>
                            )}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {isLoading ? (
                              <Skeleton variant="rounded" width={'150rem'} height={'17rem'} />
                            ) : (
                              <div style={{ width: '130rem' }} className="table-status">
                                <div className={`icon-${row.statusColor}`}></div>
                                {row.status}
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {isLoading ? (
                              <Skeleton variant="rounded" width={'180rem'} height={'17rem'} />
                            ) : (
                              <div style={{ width: '180rem' }}>{row.description}</div>
                            )}
                          </TableCell>

                          <TableCell className="table-body-cell">
                            {isLoading ? (
                              <Skeleton variant="rounded" width={'180rem'} height={'17rem'} />
                            ) : (
                              <div style={{ width: '180rem' }}>{row.payment}</div>
                            )}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {isLoading ? (
                              <Skeleton variant="rounded" width={'180rem'} height={'17rem'} />
                            ) : (
                              <div>{row.date}</div>
                            )}
                          </TableCell>

                          <TableCell className="table-body-cell">
                            {isLoading ? (
                              <Skeleton variant="rounded" width={'16rem'} height={'17rem'} />
                            ) : (
                              row.status === 'SUCCESS' &&
                              row.payment === 'SOLIDGATE' && (
                                <div className="table-download">
                                  <div className="table-tooltip-info">
                                    {/* <Link /> */}
                                    <div
                                      className="table-tooltip-info-copy table-tooltip-info__btn"
                                      onClick={() =>
                                        handleOpenInvoice(
                                          row.external,
                                          row.invoiceDate,
                                          row.invoiceAmount,
                                          row.invoicePlan,
                                          row.id
                                        )
                                      }
                                    >
                                      Download Invoice
                                    </div>
                                  </div>
                                  <span
                                    className="table-tooltip"
                                    onClick={() =>
                                      handleOpenInvoice(
                                        row.external,
                                        row.invoiceDate,
                                        row.invoiceAmount,
                                        row.invoicePlan,
                                        row.id
                                      )
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <svg
                                      width="16rem"
                                      height="16rem"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
                                        stroke="#1C274C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
                                        stroke="#1C274C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                ) : null}
              </Table>
            </SwiperSlide>
          </Swiper>
        </TableContainer>
        {showBlock ? (
          <div className="table-bottom">
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={pages}
                  siblingCount={1}
                  page={activePage}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem slots={{ previous: ArrowBack, next: ArrowNext }} {...item} />
                  )}
                />
              </Stack>

              <div className="pagination__page">20/Page</div>
            </div>
          </div>
        ) : (
          <div className="empty">
            <span>No data found</span>
          </div>
        )}
      </div>
      {openInvoice && (
        <Invoice
          openInvoice={openInvoice}
          setOpenInvoice={setOpenInvoice}
          external={external}
          date={date}
          amount={amount}
          plan={plan}
          id={id}
          setSnackbarOpen={setSnackbarOpen}
        />
      )}
    </div>
  );
};

export default History;
