import Header from '../../components/header/Header';
// import AiNavbar from "../../components/AiGenerationNavbar/AiNavbar";
import React from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import AiNavbar from '../../components/ai-navbar/AiNavbar';
import config from '../../config';

const AiVideoScriptGeneration = () => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [scriptNames, setScriptNames] = React.useState([
    'product-scripts-problem_and_solution',
    'product-scripts-product_overview',
    'product-scripts-three_benefits'
  ]);
  const [generationResult, setGenerationResult] = React.useState('');

  const handleTitleChange = (e) => {
    const newValue = e.target.value;
    setTitle(newValue);
  };

  const handleDescriptionChange = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
  };

  const handleScriptNamesChange = (e) => {
    const newValue = e.target.value;
    setScriptNames(newValue);
  };

  const generateVideoScript = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('authToken')}`
      };

      const response = await axios.post(
        `${config.apiBaseUrl}admins/ai/video-scripts`,
        {
          title,
          description
        },
        { headers }
      );

      const { data } = response.data;

      setGenerationResult(JSON.stringify(data, null, '\t'));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Header />
      {/* <AiNavbar /> */}
      <div className="content">
        <div className="container">
          <AiNavbar />
          <div className="aiPrompts">
            <h3>AiVideoScriptGeneration</h3>
            <div className="aiPrompts-inputs">
              <div className="input">
                <span>Title</span>
                <input type="text" value={title} onChange={handleTitleChange} placeholder="Title" />
              </div>

              <div className="input">
                <span>Description</span>
                <input
                  type="text"
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="Description"
                />
              </div>
            </div>

            <div style={{ marginBottom: '10rem' }} className="input">
              <span>Script Names</span>
              <input
                type="text"
                value={scriptNames}
                onChange={handleScriptNamesChange}
                placeholder="Script Names"
              />
            </div>

            <button style={{ marginBottom: '32rem' }} className="btn" onClick={generateVideoScript}>
              Generate
            </button>
            <div className="input">
              <span>Result</span>
              <textarea
                placeholder="Result"
                name="aiResult"
                rows="40"
                cols="200"
                value={generationResult}
              >
                {generationResult}
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AiVideoScriptGeneration;
