import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import './sidebar.scss';
import Cookies from 'js-cookie';
import axios from 'axios';
import config from '../../config';
import UserInfo from '../../pages/UserInfo/UserInfo';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import Creatives from '../../pages/creatives/Creatives';
import Activity from '../../pages/activity/Activity';
import Orders from '../../pages/orders/Orders';
import Products from '../../pages/products/Products';
import History from '../../pages/history/History';
import About from '../../pages/about/About';
import { gsap } from 'gsap';
import AllPromotions from '../../pages/promotionsNew/components/all-promotions/AllPromotions';
import Videos from '../../pages/videos/Videos';
import classNames from 'classnames';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const updateUser = async (activeUserId, data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('authToken')}`
    };
    const requestBody = data;
    const response = await axios.patch(
      `${config.apiBaseUrl}admins/users/${activeUserId}`,
      requestBody,
      { headers }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const SideBar = ({
  onSetSidebarOpen,
  snackbarOpen,
  setSnackbarOpen,
  setSnackbarText,
  activeUserId,
  setSnackbarCount
}) => {
  const handleOpenSnackbar = () => {
    setSnackbarOpen(true);
    setSnackbarText('0db31bf4-9b05-4e37-9430-a39079076440 <span>successfully deleted</span>');
    setSnackbarCount(10);
  };
  const [passwordSnackbarOpen, setPasswordSnackbarOpen] = useState(false);
  const [productsCount, setProductsCount] = useState(0);
  const [adsCount, setAdsCount] = useState(0);
  const [creativesCount, setCreativesCount] = useState(0);
  const [videosCount, setVideosCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    buttonAnimation(e.target);
    setTimeout(() => {
      setOpen(true);
    }, 500);
  };
  const handleClose = (e) => {
    if (e.target.className.includes('MuiModal-backdrop')) {
      setOpenInvoice(false);
      return;
    }
    buttonAnimation(e.target);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const [openReset, setOpenReset] = React.useState(false);
  const [openResetFin, setOpenResetFin] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(false);
  const handleOpenReset = (e) => {
    buttonAnimation(e.target);
    setTimeout(() => {
      setOpenReset(true);
    }, 500);
  };
  const handleOpenUpdateEmail = (e) => {
    buttonAnimation(e.target);
    setTimeout(() => {
      setShowChangeEmailModal(true);
    }, 500);
  };
  const handleCloseReset = (e) => {
    if (e.target.className.includes && e.target.className.includes('MuiModal-backdrop')) {
      setOpenInvoice(false);
      return;
    }
    buttonAnimation(e.target);
    setOpenReset(false);
  };

  const handleResetPassword = () => {
    updateUser(activeUserId, { passwordReset: true })
      .then((data) => {
        if (data.data.password) {
          setNewPassword(data.data.password);
          setOpenResetFin(true);
          setOpenReset(false);
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Error in reset password');
      });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '540rem',
    background: '#fff',
    borderRadius: '24rem',
    padding: '60rem 68rem 60rem'
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [listTab, setListTab] = React.useState('products');
  const [showChangeEmailModal, setShowChangeEmailModal] = React.useState(false);

  const handleChangeListTab = (newValue) => {
    setListTab(newValue);
  };

  const buttonAnimation = (element) => {
    const tl = gsap.timeline();
    tl.to(element, {
      duration: 0.085,
      scale: 0.85,
      opacity: 0.85,
      ease: 'power4.out'
    });
    tl.to(element, {
      duration: 0.55,
      scale: 1,
      opacity: 1,
      ease: 'elastic.out(1,0.6)'
    });
  };

  const [userInfoSubscription, setUserInfoSubscription] = React.useState(true);
  return (
    <Box sx={{ width: '100%' }}>
      <div className="sidebar-wrapper">
        <div className="sidebar-nav">
          <div className="sidebar-close" onClick={() => onSetSidebarOpen(false, '')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                icon={
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.9991 17.3889C19.6253 17.3889 20.9436 16.0706 20.9436 14.4444C20.9436 12.8183 19.6253 11.5 17.9991 11.5C16.373 11.5 15.0547 12.8183 15.0547 14.4444C15.0547 16.0706 16.373 17.3889 17.9991 17.3889ZM17.9991 18.8889C20.4537 18.8889 22.4436 16.899 22.4436 14.4444C22.4436 11.9898 20.4537 10 17.9991 10C15.5445 10 13.5547 11.9898 13.5547 14.4444C13.5547 16.899 15.5445 18.8889 17.9991 18.8889Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23.6109 24.5027V22.9319C23.6109 21.8673 22.5618 20.8971 21.2893 21.0362C20.2741 21.1471 19.1428 21.2282 17.9998 21.2282C16.8567 21.2282 15.7255 21.1471 14.7102 21.0362C13.4378 20.8971 12.3887 21.8673 12.3887 22.9319V24.5027H23.6109ZM14.8731 19.545C12.8218 19.3209 10.8887 20.8684 10.8887 22.9319V24.8027C10.8887 25.4655 11.4259 26.0027 12.0887 26.0027H23.9109C24.5736 26.0027 25.1109 25.4655 25.1109 24.8027V22.9319C25.1109 20.8684 23.1777 19.3209 21.1264 19.545C20.1497 19.6517 19.0748 19.7282 17.9998 19.7282C16.9248 19.7282 15.8498 19.6517 14.8731 19.545Z"
                      fill="black"
                    />
                  </svg>
                }
                {...a11yProps(0)}
              />
              <Tab
                icon={
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.6589 12.7969H26.2352V20.2192H24.7352V14.2969H19.6589V12.7969Z"
                      fill="white"
                    />
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.5607 15L18.7656 21.7951L15.0597 18.501L10.5 23.0607L9.43933 22L14.9991 16.4402L18.705 19.7343L24.5 13.9393L25.5607 15Z"
                      fill="white"
                    />
                  </svg>
                }
                {...a11yProps(1)}
              />
              <Tab
                icon={
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.0334 12.2507C16.352 12.2649 16.6267 12.479 16.7184 12.7845L19.1013 20.7274L20.3036 17.7215C20.4175 17.4367 20.6933 17.25 21 17.25H26C26.4142 17.25 26.75 17.5858 26.75 18C26.75 18.4142 26.4142 18.75 26 18.75H21.5078L19.6964 23.2785C19.5779 23.5746 19.2852 23.7635 18.9666 23.7493C18.648 23.7351 18.3733 23.521 18.2816 23.2155L15.8987 15.2726L14.6964 18.2785C14.5825 18.5633 14.3067 18.75 14 18.75H10C9.58579 18.75 9.25 18.4142 9.25 18C9.25 17.5858 9.58579 17.25 10 17.25H13.4922L15.3036 12.7215C15.4221 12.4254 15.7148 12.2366 16.0334 12.2507Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 25.5C22.1421 25.5 25.5 22.1421 25.5 18C25.5 13.8579 22.1421 10.5 18 10.5C13.8579 10.5 10.5 13.8579 10.5 18C10.5 22.1421 13.8579 25.5 18 25.5ZM18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z"
                      fill="black"
                    />
                  </svg>
                }
                {...a11yProps(2)}
              />
              <Tab
                icon={
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.25 11.0772C10.25 10.0681 11.068 9.25 12.0772 9.25H23.9228C24.932 9.25 25.75 10.068 25.75 11.0772V26.2511C25.75 27.1748 24.7053 27.7115 23.9544 27.1735L22.4564 26.1001L20.9383 27.3692C20.517 27.7213 19.904 27.7213 19.4828 27.3692L18 26.1297L16.5172 27.3692C16.096 27.7213 15.483 27.7213 15.0617 27.3692L13.5436 26.1001L12.0456 27.1735C11.2947 27.7115 10.25 27.1748 10.25 26.2511V11.0772ZM12.0772 10.75C11.8965 10.75 11.75 10.8965 11.75 11.0772V25.54L12.8999 24.716C13.3205 24.4147 13.8916 24.436 14.2886 24.7678L15.7895 26.0225L17.2723 24.783C17.6935 24.4308 18.3065 24.4308 18.7277 24.783L20.2105 26.0225L21.7114 24.7678C22.1084 24.436 22.6795 24.4147 23.1001 24.716L24.25 25.54V11.0772C24.25 10.8965 24.1035 10.75 23.9228 10.75H12.0772Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M13.209 13.9219H19.105V15.3269H13.209V13.9219Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M13.209 16.7326H19.105V18.1376H13.209V16.7326Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M13.209 19.5391H19.105V20.9441H13.209V19.5391Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M20.5787 13.9219H22.7897V15.3269H20.5787V13.9219Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M20.5787 16.7326H22.7897V18.1376H20.5787V16.7326Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M20.5787 19.5391H22.7897V20.9441H20.5787V19.5391Z"
                      fill="black"
                    />
                  </svg>
                }
                {...a11yProps(3)}
              />
              <Tab
                icon={
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="fill"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.97852 13.6342C9.97852 12.4167 10.9655 11.4297 12.1831 11.4297H23.8194C25.037 11.4297 26.024 12.4167 26.024 13.6342V22.3615C26.024 23.579 25.037 24.5661 23.8194 24.5661H12.1831C10.9655 24.5661 9.97852 23.579 9.97852 22.3615V13.6342ZM12.1831 12.9297C11.794 12.9297 11.4785 13.2451 11.4785 13.6342V22.3615C11.4785 22.7506 11.794 23.0661 12.1831 23.0661H23.8194C24.2085 23.0661 24.524 22.7506 24.524 22.3615V13.6342C24.524 13.2451 24.2085 12.9297 23.8194 12.9297H12.1831Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M10.7266 14.8529C10.7266 14.5803 10.9475 14.3594 11.2201 14.3594H24.7785C25.0511 14.3594 25.272 14.5803 25.272 14.8529V15.6436C25.272 15.9162 25.0511 16.1372 24.7785 16.1372H11.2201C10.9475 16.1372 10.7266 15.9162 10.7266 15.6436V14.8529Z"
                      fill="black"
                    />
                    <path
                      className="fill"
                      d="M12.1836 20.907C12.1836 20.5053 12.5092 20.1797 12.9109 20.1797H17.2745C17.6762 20.1797 18.0018 20.5053 18.0018 20.907V20.907C18.0018 21.3086 17.6762 21.6342 17.2745 21.6342H12.9109C12.5092 21.6342 12.1836 21.3086 12.1836 20.907V20.907Z"
                      fill="black"
                    />
                  </svg>
                }
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
        </div>

        <div className="sidebar-right">
          <div className="sidebar-top">
            <div className="sidebar-top__id">User ID: {activeUserId}</div>
            {value === 0 && (
              <div className="sidebar-top-right">
                <ul>
                  <li onClick={(e) => handleOpenUpdateEmail(e)}>
                    <span>Change email</span>
                  </li>
                  <li onClick={(e) => handleOpenReset(e)}>
                    <span>Password Reset</span>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <CustomTabPanel value={value} index={0}>
            <UserInfo
              activeUserId={activeUserId}
              setUserInfoSubscription={setUserInfoSubscription}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarText={setSnackbarText}
              setSnackbarCount={setSnackbarCount}
              showChangeEmailModal={showChangeEmailModal}
              setShowChangeEmailModal={setShowChangeEmailModal}
            />
            <div className="lists__container">
              <div className="lists-tabs">
                <button
                  className={classNames({ active: listTab == 'products' })}
                  data-count={productsCount}
                  onClick={() => handleChangeListTab('products')}>
                  Products
                </button>
                <button
                  className={classNames({ active: listTab == 'ads' })}
                  data-count={adsCount}
                  onClick={() => handleChangeListTab('ads')}>
                  Ads
                </button>
                <button
                  className={classNames({ active: listTab == 'creatives' })}
                  data-count={creativesCount}
                  onClick={() => handleChangeListTab('creatives')}>
                  Creatives
                </button>
                <button
                  className={classNames({ active: listTab == 'video' })}
                  data-count={videosCount}
                  onClick={() => handleChangeListTab('video')}>
                  Video
                </button>
              </div>
              <Products
                activeUserId={activeUserId}
                isActive={listTab == 'products'}
                setCount={setProductsCount}
              />
              <AllPromotions
                activeUserId={activeUserId}
                isActive={listTab == 'ads'}
                setCount={setAdsCount}
              />
              <Creatives
                activeUserId={activeUserId}
                isActive={listTab == 'creatives'}
                setCount={setCreativesCount}
              />
              <Videos
                activeUserId={activeUserId}
                isActive={listTab == 'video'}
                setCount={setVideosCount}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <About activeUserId={activeUserId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Activity activeUserId={activeUserId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Orders activeUserId={activeUserId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <History
              activeUserId={activeUserId}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarText={setSnackbarText}
              setSnackbarCount={setSnackbarCount}
            />
          </CustomTabPanel>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal">
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path
                d="M40.0006 23.9297C43.0559 23.9297 45.0472 25.5157 45.6857 28.4291L53.5006 28.4297C54.2106 28.4297 54.7863 29.0053 54.7863 29.7154C54.7863 30.4255 54.2106 31.0011 53.5006 31.0011H52.0798L50.0069 50.6906C49.8003 52.6537 48.1449 54.144 46.171 54.144H33.8301C31.8562 54.144 30.2008 52.6537 29.9942 50.6906L27.9213 31.0011H26.5006C25.7905 31.0011 25.2148 30.4255 25.2148 29.7154C25.2148 29.0053 25.7905 28.4297 26.5006 28.4297L34.3155 28.4291C34.9539 25.5157 36.9452 23.9297 40.0006 23.9297ZM49.493 31.0011H30.5068L32.5515 50.4214C32.6204 51.0758 33.1722 51.5725 33.8301 51.5725H46.171C46.829 51.5725 47.3807 51.0758 47.4496 50.4214L49.493 31.0011ZM40.0006 33.5725C40.7106 33.5725 41.2863 34.1482 41.2863 34.8583V47.7154C41.2863 48.4255 40.7106 49.0011 40.0006 49.0011C39.2905 49.0011 38.7148 48.4255 38.7148 47.7154V34.8583C38.7148 34.1482 39.2905 33.5725 40.0006 33.5725ZM45.2076 33.5741C45.9168 33.6096 46.463 34.2133 46.4275 34.9225L45.7847 47.7796C45.7492 48.4888 45.1455 49.035 44.4364 48.9995C43.7272 48.9641 43.181 48.3604 43.2164 47.6512L43.8593 34.7941C43.8948 34.0849 44.4984 33.5387 45.2076 33.5741ZM34.7935 33.5741C35.5027 33.5387 36.1064 34.0849 36.1418 34.7941L36.7847 47.6512C36.8201 48.3604 36.274 48.9641 35.5648 48.9995C34.8556 49.035 34.2519 48.4888 34.2164 47.7796L33.5736 34.9225C33.5381 34.2133 34.0843 33.6096 34.7935 33.5741ZM40.0006 26.5011C38.3719 26.5011 37.4295 27.085 36.9763 28.4291H43.0248C42.5716 27.085 41.6292 26.5011 40.0006 26.5011Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="modal-title">
            Do you really want to <br />
            delete this account?
          </div>
          <div className="modal-text">This action is irreversible</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleClose}>
              Cancel
            </div>
            <div
              className="modal__delete"
              onClick={(e) => {
                buttonAnimation(e.target);
                handleClose(e);

                setTimeout(() => {
                  onSetSidebarOpen(false, '');
                  handleOpenSnackbar();
                }, 500);
              }}>
              Delete
            </div>
          </div>
        </Box>
      </Modal>

      {openReset && (
        <Modal
          keepMounted
          open={openReset}
          onClose={handleCloseReset}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          className="modal">
          <Box sx={style} className="modal-container">
            <div className="modal-close" onClick={handleCloseReset}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              </svg>
            </div>
            <div className="modal-icon">
              <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
                <g clip-path="url(#a)">
                  <path
                    d="M32.571 36.5v-5.357C32.571 27.198 35.898 24 40 24s7.429 3.198 7.429 7.143V36.5m1.857 19.643H30.714c-2.052 0-3.714-1.598-3.714-3.572v-12.5c0-1.973 1.662-3.571 3.714-3.571h18.572c2.052 0 3.714 1.598 3.714 3.571v12.5c0 1.974-1.662 3.572-3.714 3.572Z"
                    stroke="#323232"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" transform="translate(22 22)" d="M0 0h36v36H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="modal-title">Change password</div>
            <div className="modal-text">
              Do you really want to reset user password?
              <br />
              This action cannot be canceled
            </div>
            <div className="modal-action">
              <div className="modal__cancel" onClick={handleCloseReset}>
                Cancel
              </div>
              <div className="modal__send" onClick={handleResetPassword}>
                Reset password
              </div>
            </div>
          </Box>
        </Modal>
      )}

      {openResetFin && (
        <Modal
          keepMounted
          open={openResetFin}
          onClose={() => {
            setOpenResetFin(false);
            setNewPassword('');
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          className="modal">
          <Box sx={style} className="modal-container">
            <div
              className="modal-close"
              onClick={() => {
                setOpenResetFin(false);
                setNewPassword('');
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              </svg>
            </div>
            <div className="modal-icon">
              <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
                <g clip-path="url(#a)">
                  <path
                    d="M32.571 36.5v-5.357C32.571 27.198 35.898 24 40 24s7.429 3.198 7.429 7.143V36.5m1.857 19.643H30.714c-2.052 0-3.714-1.598-3.714-3.572v-12.5c0-1.973 1.662-3.571 3.714-3.571h18.572c2.052 0 3.714 1.598 3.714 3.571v12.5c0 1.974-1.662 3.572-3.714 3.572Z"
                    stroke="#323232"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" transform="translate(22 22)" d="M0 0h36v36H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="modal-title">Password changed</div>
            <div className="modal-text">Copy this password and send to user</div>
            <div className="input__container">
              <label htmlFor="new-password">New password</label>
              <input id="new-password" type="text" readOnly value={newPassword} />
              <button
                className="input-btn"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(newPassword);
                  setPasswordSnackbarOpen(true);
                  setTimeout(() => setPasswordSnackbarOpen(false), 1000);
                }}>
                Copy
              </button>
              <Snackbar
                open={passwordSnackbarOpen}
                message="Password copied"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              />
            </div>
            <div className="modal-action" style={{ width: '100%' }}>
              <div
                className="modal__send"
                style={{ width: '100%' }}
                onClick={() => {
                  setOpenResetFin(false);
                  setNewPassword('');
                }}>
                Ok
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default SideBar;
