import React, { useState, useEffect } from 'react';
import './userInfo.scss';
import Cookies from 'js-cookie';
import axios from 'axios';
import AwesomePhoneNumber from 'awesome-phonenumber';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { gsap } from 'gsap';
import config from '../../config';

const fetchUserData = async (activeUserId) => {
  if (!activeUserId) {
    return null;
  }

  const url = `${config.apiBaseUrl}admins/users/${activeUserId}`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const response = await axios.get(url, { headers });
  return response.data;
};

const fetchUserSite = async (activeUserId) => {
  if (!activeUserId) {
    return null;
  }

  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/sites`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const response = await axios.get(url, { headers });
  return response.data;
};

const fetchUserSubscriptions = async (activeUserId) => {
  if (!activeUserId) {
    return null;
  }

  const url = `${config.apiBaseUrl}admins/subscriptions/${activeUserId}`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const response = await axios.get(url, { headers });
  return response.data;
};

const fetchUserBalances = async () => {
  const url = `${config.apiBaseUrl}admins/credits/balances`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

const fetchUserLimits = async (activeUserId) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/credits/limits/${activeUserId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

const fetchUserCredits = async (activeUserId) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/credits/${activeUserId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

const addCredits = async (activeUserId, videoCredits) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('authToken')}`
    };
    const requestBody = {
      videoCredits
    };
    const response = await axios.patch(
      `${config.apiBaseUrl}admins/credits/${activeUserId}`,
      requestBody,
      { headers }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

const updateUser = async (activeUserId, data) => {
  console.log('asdasd');
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('authToken')}`
    };
    const requestBody = data;
    const response = await axios.patch(
      `${config.apiBaseUrl}admins/users/${activeUserId}`,
      requestBody,
      { headers }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

const UserInfo = ({
  activeUserId,
  setSnackbarOpen,
  setSnackbarText,
  setSnackbarCount,
  setUserInfoSubscription,
  showChangeEmailModal,
  setShowChangeEmailModal
}) => {
  const [activeBlockId, setActiveBlockId] = useState(null);

  const handleEditClick = (event, id) => {
    buttonAnimation(event.target);
    setTimeout(() => {
      setActiveBlockId(id);
    }, 500);
  };

  const [value, setValue] = useState(16);

  const colors = ['#F43535', '#FFA500', '#FFD700', '#0073e6', '#17CC37'];

  const getColor = (value) => {
    const index = Math.floor((value / 100) * (colors.length - 1));
    return colors[index];
  };

  const circularProgressStyle = {
    color: getColor(value)
  };

  const [dataSubscriptions, setData] = useState([
    {
      id: '2',
      name: 'Growth',
      price: '$79.99',
      payment: 'Apple Pay',
      next: '10.09.2023',
      last: '10.08.2023'
    },
    {
      id: '3',
      name: 'Trial',
      price: '$79.99',
      payment: 'Apple Pay',
      next: '10.09.2023',
      last: '10.08.2023'
    }
  ]);

  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [connectedWebsite, setConnectedWebsite] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [os, setOs] = useState('');
  const [pushTokens, setPushTokens] = useState('');

  const {
    isLoading: isLoading,
    error: error,
    data: infoData,
    refetch: userInfoRefetch
  } = useQuery(['userInfo', activeUserId], () => fetchUserData(activeUserId));

  const {
    isLoading: isLoadingSubscriptions,
    error: errorSubscriptions,
    data: infoSubscriptions
  } = useQuery(['userSubscriptions', activeUserId], () => fetchUserSubscriptions(activeUserId));

  const {
    isLoading: isLoadingBalances,
    error: errorBalances,
    data: infoBalances
  } = useQuery(['userBalances', activeUserId], () => fetchUserBalances());
  const {
    isLoading: isLoadingLimits,
    error: errorLimits,
    data: infoLimits,
    refetch: refetchLimits
  } = useQuery(['userLimits', activeUserId], () => fetchUserLimits(activeUserId));
  // const {
  //   isLoading: isLoadingCredits,
  //   error: errorCredits,
  //   data: infoCredits
  // } = useQuery(['userCredits', activeUserId], () => fetchUserCredits(activeUserId));
  // console.log(
  //   JSON.stringify(infoBalances),
  //   JSON.stringify(infoLimits),
  //   JSON.stringify(infoCredits)
  // );

  useEffect(() => {
    if (!isLoading && !error && infoData) {
      if (infoData.email) {
        setEmail(infoData.email);
      } else {
        setEmail('—');
      }
      if (infoData.name) {
        setName(infoData.name);
      } else {
        setName('—');
      }
      if (infoData.phone && infoData.phone.length > 0) {
        setPhone(infoData.phone);
      } else {
        setPhone('—');
      }
      const phoneNumberString = infoData.phone;
      setCountry('');
      if (phoneNumberString !== null) {
        const phoneNumber = new AwesomePhoneNumber(phoneNumberString);
        setCountry(phoneNumber.g.regionCode.toLowerCase());
      }

      const dateString = infoData.createdAt;
      const dateObject = new Date(dateString);

      const formattedDate = dateObject.toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
      setCreatedAt(formattedDate);

      if (infoData.os && infoData.os.length > 0) {
        setOs(infoData.os.toLowerCase());
      } else {
        setOs('safari');
      }

      if (infoData.pushTokens.length === 0) {
        setPushTokens('no');
      } else {
        setPushTokens('yes');
      }
    }
  }, [isLoading, error, infoData]);

  const {
    isLoading: isLoadingSite,
    error: errorSite,
    data: infoSiteData
  } = useQuery(['userInfoSite', activeUserId], () => fetchUserSite(activeUserId));

  useEffect(() => {
    if (!isLoadingSite && !errorSite && infoSiteData) {
      setWebsite('—');
      setConnectedWebsite('—');
      infoSiteData.items.forEach((item) => {
        if (item.url.endsWith('.zee.am/')) {
          setWebsite(item.url);
        } else {
          setConnectedWebsite(item.url);
        }
      });
    }
  }, [isLoadingSite, errorSite, infoSiteData, infoData]);

  const [activeRowsLoading, setActiveRowsLoading] = useState([]);

  useEffect(() => {
    if (!isLoadingSubscriptions && !errorSubscriptions && infoSubscriptions) {
      if (infoSubscriptions.data.subscription) {
        if (infoSubscriptions.data.subscription.pricingPlan === 'FREE') {
          setActiveRowsLoading([
            createActiveRows(
              'ended',
              infoSubscriptions.data.subscription.pricingPlan,
              infoSubscriptions.data.subscription.status,
              'Billing weekly',
              ' ',
              false,
              false
            )
          ]);
        } else {
          setActiveRowsLoading([
            createActiveRows(
              'green',
              infoSubscriptions.data.subscription.pricingPlan,
              infoSubscriptions.data.subscription.status,
              'Billing Monthly',
              `Next invoice on ${formatDate(infoSubscriptions.data.subscription.nextPaymentAt)} for $${(infoSubscriptions.data.subscription.amount / 100).toFixed(2)}`, // Assuming price is a property of subscription
              false,
              false
            )
          ]);
        }
        setUserInfoSubscription(true);
      } else {
        setUserInfoSubscription(false);
        setActiveRowsLoading([]);
      }
    }
  }, [isLoadingSubscriptions, errorSubscriptions, infoSubscriptions]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const monthIndex = date.getMonth();
    const day = date.getDate();

    return months[monthIndex] + ' ' + day;
  }

  const sendWeb = (website) => {
    window.open(website, '_blank');
  };
  const handleCopyClick = (value, text) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setSnackbarOpen(true);
        setSnackbarText(`${text} was copied`);
        setSnackbarCount(3);
      })
      .catch((error) => {
        console.error('Copy failed: ', error);
      });
  };

  function createActiveRows(
    status,
    name,
    statusDescription,
    billing,
    NextBilling,
    isPlayVisible,
    isPauseVisible
  ) {
    return { status, name, statusDescription, billing, NextBilling, isPlayVisible, isPauseVisible };
  }

  const togglePlayVisibility = (index) => {
    if (index >= 0 && index < activeRowsLoading.length) {
      const updatedRows = [...activeRowsLoading];
      updatedRows[index].isPlayVisible = true;
      setActiveRowsLoading(updatedRows);
    }
  };

  const closePlay = (index) => {
    if (index >= 0 && index < activeRowsLoading.length) {
      const updatedRows = [...activeRowsLoading];
      updatedRows[index].isPlayVisible = false;
      setActiveRowsLoading(updatedRows);
    }
  };

  const togglePauseVisibility = (index) => {
    if (index >= 0 && index < activeRowsLoading.length) {
      const updatedRows = [...activeRowsLoading];
      updatedRows[index].isPauseVisible = true;
      setActiveRowsLoading(updatedRows);
    }
  };

  const closePause = (index) => {
    if (index >= 0 && index < activeRowsLoading.length) {
      const updatedRows = [...activeRowsLoading];
      updatedRows[index].isPauseVisible = false;
      setActiveRowsLoading(updatedRows);
    }
  };

  const [paymentsRows, setPaymentsRows] = useState([]);

  function createPaymentsRows(amount, currency, status, description, date) {
    return { amount, currency, status, description, date };
  }
  const paymentsRowsLoading = [
    createPaymentsRows('$119.95', 'USD', 'Refund', 'Subscription update', 'Dec 19, 11:36 AM'),
    createPaymentsRows('$1.00', 'USD', 'Succeeded', 'Subscription update', 'Dec 19, 11:36 AM'),
    createPaymentsRows('$1.00', 'USD', 'Succeeded', 'Subscription update', 'Dec 18, 15:36 AM'),
    createPaymentsRows('$1.00', 'USD', 'Canceled', 'Subscription update', 'Dec 18, 11:36 AM'),
    createPaymentsRows('$1.00', 'USD', 'Succeeded', 'Subscription update', 'Dec 18, 11:36 AM')
  ];

  // setActiveRows(activeRowsLoading)

  const [methodRows, setMethodRows] = useState([]);

  function createMethodRows(card, number, defaultCard, date) {
    return { card, number, defaultCard, date };
  }
  const methodRowsLoading = [
    createMethodRows('Visa', '1202', true, 'Exspires May 2029'),
    createMethodRows('MasterCard', '4907', false, 'Exspires May 2029'),
    createMethodRows('Apple pay', '', false, 'Exspires May 2029')
  ];

  // setActiveRows(activeRowsLoading)

  function createData(date, adminName, adminEmail, color, action, description) {
    return { date, adminName, adminEmail, color, action, description };
  }
  const rows = [
    createData(
      '22.08. 2023, 06:17PM',
      'Veronika',
      'supzeely@app.com',
      'red',
      'Refund',
      '<b>$195.95</b>  from the Growth plan'
    ),
    createData(
      '22.08. 2023, 06:17PM',
      'Veronika',
      'supzeely@app.com',
      'gray',
      'Unsubscribe',
      'from Plus tariff'
    ),
    createData(
      '22.08. 2023, 06:17PM',
      'Veronika',
      'supzeely@app.com',
      'gray',
      'Paused tariff',
      'Paused daily plan for Jan 2, 2024'
    )
  ];

  const [openPlay, setOpenPlay] = useState(false);
  const handleOpenPlay = () => setOpenPlay(true);
  const handleClosePlay = () => setOpenPlay(false);
  const [openAddCredits, setOpenAddCredits] = useState(false);
  const handleOpenAddCredits = () => setOpenAddCredits(true);
  const handleCloseAddCredits = () => setOpenAddCredits(false);

  const [openPause, setOpenPause] = useState(false);
  const handleOpenPause = () => setOpenPause(true);
  const handleClosePause = () => setOpenPause(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openRefund, setOpenRefund] = useState(false);
  const handleOpenRefund = () => setOpenRefund(true);
  const handleCloseRefund = () => setOpenRefund(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '540rem',
    background: '#fff',
    borderRadius: '24rem',
    padding: '60rem 68rem 60rem',
    outline: 'none'
  };

  const buttonAnimation = (element) => {
    const tl = gsap.timeline();
    tl.to(element, {
      duration: 0.085,
      scale: 0.85,
      opacity: 0.85,
      ease: 'power4.out'
    });
    tl.to(element, {
      duration: 0.55,
      scale: 1,
      opacity: 1,
      ease: 'elastic.out(1,0.6)'
    });
  };

  const handleAddCredits = (e) => {
    e.preventDefault();
    if (isLoadingBalances || errorBalances || !infoBalances?.data?.videoCreditsLeft) {
      return;
    }
    const formData = new FormData(e.target);
    const amount = formData.get('amount');
    addCredits(activeUserId, amount).then((data) => {
      refetchLimits();
      handleCloseAddCredits();
    });
  };

  const handleChangeEmail = (e) => {
    e.preventDefault();
    if (isLoading || error || !infoData.email) {
      return;
    }
    const formData = new FormData(e.target);
    const newemail = formData.get('newemail');
    updateUser(activeUserId, { email: newemail }).then((data) => {
      setShowChangeEmailModal(false);
      userInfoRefetch();
    });
  };

  if (!infoLimits) {
    return null;
  }

  const aiBuiltVideos = infoLimits?.subscriptionLimits?.aiBuiltVideos || 0;
  const videoCredits = infoLimits?.credits?.videoCredits || 0;
  const limitsAiBuiltVideos = infoLimits?.limits?.aiBuiltVideos || 0;
  const createdCreatives = infoLimits?.subscriptionLimits?.createdCreatives || 0;
  const limitsCreatedCreatives = infoLimits?.limits?.createdCreatives || 0;

  return (
    <div className="userInfo">
      <div className="userInfo-info">
        <div className={`userInfo-block ${activeBlockId === 1 ? 'active' : ''}`} id={1}>
          <div className="userInfo-block-top">
            <div className="userInfo-block__title">User information</div>
            {activeBlockId === 1 && (
              <div className="userInfo-block-action">
                <div className="userInfo-block__cancel" onClick={(e) => handleEditClick(e, 0)}>
                  Cancel
                </div>
                <div className="userInfo-block__save" onClick={(e) => handleEditClick(e, 0)}>
                  Save
                </div>
              </div>
            )}
          </div>

          <div className="userInfo-block-wrapper">
            <div className="userInfo-block-item">
              <span>Email</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <div className="userInfo-block-item-wrapper">
                  <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <i onClick={() => handleCopyClick(email, 'Email')}></i>
                </div>
              )}
            </div>
            <div className="userInfo-block-item">
              <span>Name</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              )}
            </div>
            <div className="userInfo-block-item">
              <span>Connected website</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <div
                  className="userInfo-block-item-bottom"
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={process.env.PUBLIC_URL + `/img/shopify.svg`}
                    alt=""
                    style={{ marginBottom: '5rem' }}
                  />
                  <div className="userInfo-block-item-wrapper">
                    <input
                      type="text"
                      value={connectedWebsite}
                      onChange={(e) => setConnectedWebsite(e.target.value)}
                    />
                    <i onClick={() => sendWeb(connectedWebsite)}></i>
                  </div>
                </div>
              )}
            </div>
            <div className="userInfo-block-item">
              <span>Zeely website</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <div className="userInfo-block-item-wrapper">
                  <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} />
                  <i onClick={() => sendWeb(website)}></i>
                </div>
              )}
            </div>

            <div className="userInfo-block-item">
              <span>Phone</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <label className="userInfo-block-item__phone">
                  {country && (
                    <img src={process.env.PUBLIC_URL + `/img/flags/4x3/${country}.svg`} alt="" />
                  )}
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    style={{ paddingLeft: country ? '30rem' : '0' }}
                  />
                </label>
              )}
            </div>
            <div className="userInfo-block-item">
              <span>Os</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <div className="userInfo-block-item-bottom">
                  <img src={process.env.PUBLIC_URL + `/img/${os}.svg`} alt="" />
                  <p>{os}</p>
                </div>
              )}
            </div>
            <div className="userInfo-block-item">
              <span>Push-Token</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <div className="userInfo-block-item-bottom">
                  <img src={process.env.PUBLIC_URL + `/img/${pushTokens}.svg`} alt="" />
                  <p>{pushTokens}</p>
                </div>
              )}
            </div>
            <div className="userInfo-block-item">
              <span>Created date</span>
              {isLoading ? (
                <Skeleton variant="rounded" width={'220rem'} height={'26rem'} />
              ) : (
                <div className="userInfo-block-item-bottom">
                  <p>{createdAt}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeRowsLoading.length > 0 && (
        <div className="userInfo-active">
          <div className="userInfo__title">Active Subscriptions</div>
          <div className="table">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" style={{ marginBottom: '0' }}>
                <TableBody>
                  {activeRowsLoading.map((row, index) => (
                    <TableRow
                      key={`${row.name} ${row.billing} ${row.NextBilling}`.replace(' ', '_')}>
                      <TableCell className="table-body-cell">
                        {isLoadingSubscriptions ? (
                          <Skeleton variant="rounded" width={'38rem'} height={'38rem'} />
                        ) : (
                          <>
                            {row.status === 'ended' && (
                              <>
                                <svg
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect width="38" height="38" rx="6" fill="#F2F4F6" />
                                  <g clipPath="url(#clip0_2851_40490)">
                                    <circle
                                      cx="19"
                                      cy="19"
                                      r="7.25"
                                      stroke="#A7A8AA"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      d="M19 14C20.0572 14 21.0871 14.3351 21.9419 14.9571C22.7967 15.5791 23.4324 16.456 23.7576 17.4619L19 19V14Z"
                                      fill="#A7A8AA"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2851_40490">
                                      <rect
                                        x="11"
                                        y="11"
                                        width="16"
                                        height="16"
                                        rx="8"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </>
                            )}

                            {row.status === 'green' && (
                              <>
                                <svg
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect width="38" height="38" rx="6" fill="#17CC37" />
                                  <g clipPath="url(#clip0_2851_40574)">
                                    <circle
                                      cx="19"
                                      cy="19"
                                      r="7.25"
                                      stroke="white"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      d="M19 14C20.0572 14 21.0871 14.3351 21.9419 14.9571C22.7967 15.5791 23.4324 16.456 23.7576 17.4619L19 19V14Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2851_40574">
                                      <rect
                                        x="11"
                                        y="11"
                                        width="16"
                                        height="16"
                                        rx="8"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </>
                            )}

                            {row.status === 'pause-gray' && (
                              <>
                                <svg
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect width="38" height="38" rx="6" fill="#F2F4F6" />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15 12.25C15.4142 12.25 15.75 12.5858 15.75 13L15.75 25C15.75 25.4142 15.4142 25.75 15 25.75C14.5858 25.75 14.25 25.4142 14.25 25L14.25 13C14.25 12.5858 14.5858 12.25 15 12.25Z"
                                    fill="#A7A8AA"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M23 12.25C23.4142 12.25 23.75 12.5858 23.75 13L23.75 25C23.75 25.4142 23.4142 25.75 23 25.75C22.5858 25.75 22.25 25.4142 22.25 25L22.25 13C22.25 12.5858 22.5858 12.25 23 12.25Z"
                                    fill="#A7A8AA"
                                  />
                                </svg>
                              </>
                            )}

                            {row.status === 'pause-yellow' && (
                              <>
                                <svg
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect width="38" height="38" rx="6" fill="#FFD948" />
                                  <circle
                                    cx="19"
                                    cy="20"
                                    r="6.25"
                                    stroke="white"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    d="M18.25 13V13.75H19.75V13H18.25ZM19.75 12C19.75 11.5858 19.4142 11.25 19 11.25C18.5858 11.25 18.25 11.5858 18.25 12H19.75ZM19.75 13V12H18.25V13H19.75Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M19 17V20.5L21 22.5"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell className="table-body-cell active-info">
                        {isLoadingSubscriptions ? (
                          <Skeleton variant="rounded" width={'200rem'} height={'38rem'} />
                        ) : (
                          <>
                            {' '}
                            <div className="active-info-top">
                              <strong>{row.name}</strong>
                              <span className={row.status}>{row.statusDescription}</span>
                            </div>
                            <p>
                              {/* {row.billing} <span></span>  */}
                              {row.NextBilling}
                            </p>
                          </>
                        )}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {isLoadingSubscriptions ? (
                          <Skeleton variant="rounded" width={'32.4rem'} height={'32.4rem'} />
                        ) : (
                          <div className="active-action">
                            {(row.status === 'pause-gray' ||
                              row.status === 'pause-yellow' ||
                              row.status === 'ended') && (
                              <div className="active-action-btn active-action-btn--play">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M13.3561 7.11867C14.0594 7.49574 14.0594 8.50427 13.3561 8.88133L5.97249 12.8397C5.30632 13.1969 4.5 12.7143 4.5 11.9584L4.5 4.04159C4.5 3.28572 5.30632 2.80311 5.97249 3.16025L13.3561 7.11867Z"
                                    fill="black"
                                  />
                                </svg>
                                <i onClick={() => togglePlayVisibility(index)}>
                                  <span>Renew subscription</span>
                                </i>

                                {row.isPlayVisible && (
                                  <ul onClick={() => closePlay(index)}>
                                    <li onClick={handleOpenPlay}>Change subscription</li>
                                    <li onClick={handleOpenPlay}>Extend the trial</li>
                                  </ul>
                                )}
                              </div>
                            )}
                            {row.status === 'green' && (
                              <>
                                <div className="active-action-btn active-action-btn--pause">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M4 1.25C4.41421 1.25 4.75 1.58579 4.75 2L4.75 14C4.75 14.4142 4.41421 14.75 4 14.75C3.58579 14.75 3.25 14.4142 3.25 14L3.25 2C3.25 1.58579 3.58579 1.25 4 1.25Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2L12.75 14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14L11.25 2C11.25 1.58579 11.5858 1.25 12 1.25Z"
                                      fill="black"
                                    />
                                  </svg>
                                  <i onClick={() => togglePauseVisibility(index)}>
                                    <span>Pause subscription</span>
                                  </i>
                                  {row.isPauseVisible && (
                                    <ul onClick={() => closePause(index)}>
                                      <li onClick={handleOpenPause}>Pause subscription</li>
                                      <li onClick={handleOpenPause}>Temporary pause</li>
                                    </ul>
                                  )}
                                </div>
                                <div className="active-action-btn active-action-btn--delete">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M3.22711 3.22748C3.52 2.93459 3.99487 2.93459 4.28777 3.22748L12.773 11.7128C13.0659 12.0057 13.0659 12.4805 12.773 12.7734C12.4802 13.0663 12.0053 13.0663 11.7124 12.7734L3.22711 4.28814C2.93421 3.99525 2.93421 3.52038 3.22711 3.22748Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M12.7731 3.22564C13.066 3.51854 13.066 3.99341 12.7731 4.2863L4.28785 12.7716C3.99495 13.0645 3.52008 13.0645 3.22719 12.7716C2.93429 12.4787 2.93429 12.0038 3.22719 11.7109L11.7125 3.22564C12.0054 2.93275 12.4802 2.93275 12.7731 3.22564Z"
                                      fill="black"
                                    />
                                  </svg>
                                  <i onClick={handleOpenDelete}>
                                    <span>Cancel subscription</span>
                                  </i>
                                </div>
                              </>
                            )}
                            {(row.status === 'pause-gray' ||
                              row.status === 'pause-yellow' ||
                              row.status === 'green') && (
                              <div className="active-action-btn active-action-btn--setting">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="3.5" cy="8" r="1.5" fill="black" />
                                  <circle cx="8" cy="8" r="1.5" fill="black" />
                                  <circle cx="12.5" cy="8" r="1.5" fill="black" />
                                </svg>
                                <i>
                                  <span>More options</span>
                                </i>
                              </div>
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}

      {!errorLimits && (
        <div className="userInfo-active">
          <div className="userInfo__title">Limits</div>
          <div className="table">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" style={{ marginBottom: '0' }}>
                <TableBody>
                  <TableRow>
                    <TableCell className="table-body-cell">
                      {isLoadingLimits ? (
                        <Skeleton variant="rounded" width={'38rem'} height={'38rem'} />
                      ) : (
                        <div className="limits__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <g clipPath="url(#clip0_9275_25774)">
                              <path
                                d="M18.75 10C18.75 14.8329 14.8329 18.75 10 18.75C5.16708 18.75 1.25 14.8329 1.25 10C1.25 5.16708 5.16708 1.25 10 1.25C14.8329 1.25 18.75 5.16708 18.75 10Z"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.5387 7.5867L12.2434 9.47041C12.5855 9.70834 12.5855 10.2917 12.2434 10.5296L9.5387 12.4133C9.19034 12.6561 8.75 12.36 8.75 11.8833V8.1167C8.75 7.64003 9.19034 7.34385 9.5387 7.5867Z"
                                fill="black"
                                stroke="#323232"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_9275_25774">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="table-body-cell active-info">
                      {isLoadingLimits ? (
                        <Skeleton variant="rounded" width={'200rem'} height={'38rem'} />
                      ) : (
                        <>
                          {' '}
                          <div className="active-info-top">
                            <strong>
                              Video: {aiBuiltVideos + videoCredits - limitsAiBuiltVideos} credits
                            </strong>
                          </div>
                          <p>
                            <span className="limits__total-count">
                              Total: {aiBuiltVideos + videoCredits} credits
                            </span>
                            <span></span>
                            Used: {limitsAiBuiltVideos} credits
                          </p>
                        </>
                      )}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {isLoadingLimits ? (
                        <Skeleton variant="rounded" width={'32.4rem'} height={'32.4rem'} />
                      ) : (
                        <div className="active-action">
                          <div className="active-action-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.25134 8.00052C1.25134 7.58631 1.58713 7.25052 2.00134 7.25052H14.0013C14.4156 7.25052 14.7513 7.58631 14.7513 8.00052C14.7513 8.41473 14.4156 8.75052 14.0013 8.75052H2.00134C1.58713 8.75052 1.25134 8.41473 1.25134 8.00052Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.00073 1.24854C8.41495 1.24854 8.75073 1.58432 8.75073 1.99854V13.9985C8.75073 14.4127 8.41495 14.7485 8.00073 14.7485C7.58652 14.7485 7.25073 14.4127 7.25073 13.9985V1.99854C7.25073 1.58432 7.58652 1.24854 8.00073 1.24854Z"
                                fill="black"
                              />
                            </svg>
                            <i onClick={handleOpenAddCredits}>
                              <span>Add credits</span>
                            </i>
                          </div>
                          <div className="active-action-btn active-action-btn--setting">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="3.5" cy="8" r="1.5" fill="black" />
                              <circle cx="8" cy="8" r="1.5" fill="black" />
                              <circle cx="12.5" cy="8" r="1.5" fill="black" />
                            </svg>
                            <i
                              onClick={() => {
                                alert('Feature in development');
                              }}>
                              <span>More options</span>
                            </i>
                          </div>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table-body-cell">
                      {isLoadingLimits ? (
                        <Skeleton variant="rounded" width={'38rem'} height={'38rem'} />
                      ) : (
                        <div className="limits__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <g clipPath="url(#clip0_9275_25835)">
                              <path
                                d="M16.6642 17.5H3.33583C2.41499 17.5 1.66916 16.7542 1.66916 15.8333V4.16667C1.66916 3.24583 2.41499 2.5 3.33583 2.5H16.6642C17.585 2.5 18.3308 3.24583 18.3308 4.16667V15.8333C18.3308 16.7542 17.5842 17.5 16.6642 17.5Z"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18.3333 13.3333L14.8175 10.5208C14.4583 10.2333 13.9333 10.2917 13.6458 10.6508L10.8658 14.1258C10.5783 14.485 10.0541 14.5433 9.69415 14.2558L8.13748 13.01C7.78331 12.7267 7.26748 12.7783 6.97665 13.1275L3.33331 17.5M7.84515 6.3215C8.49603 6.97237 8.49603 8.02765 7.84515 8.67852C7.19428 9.3294 6.139 9.3294 5.48813 8.67852C4.83726 8.02765 4.83726 6.97237 5.48813 6.3215C6.139 5.67062 7.19428 5.67062 7.84515 6.3215Z"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_9275_25835">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="table-body-cell active-info">
                      {isLoadingLimits ? (
                        <Skeleton variant="rounded" width={'200rem'} height={'38rem'} />
                      ) : (
                        <>
                          {' '}
                          <div className="active-info-top">
                            <strong>
                              Static ad: {createdCreatives - limitsCreatedCreatives} credits
                            </strong>
                          </div>
                          <p>
                            <span className="limits__total-count">
                              Total: {createdCreatives} credits
                            </span>
                            <span></span>
                            Used: {limitsCreatedCreatives} credits
                          </p>
                        </>
                      )}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {isLoadingLimits ? (
                        <Skeleton variant="rounded" width={'32.4rem'} height={'32.4rem'} />
                      ) : (
                        <div className="active-action">
                          <div className="active-action-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.25134 8.00052C1.25134 7.58631 1.58713 7.25052 2.00134 7.25052H14.0013C14.4156 7.25052 14.7513 7.58631 14.7513 8.00052C14.7513 8.41473 14.4156 8.75052 14.0013 8.75052H2.00134C1.58713 8.75052 1.25134 8.41473 1.25134 8.00052Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.00073 1.24854C8.41495 1.24854 8.75073 1.58432 8.75073 1.99854V13.9985C8.75073 14.4127 8.41495 14.7485 8.00073 14.7485C7.58652 14.7485 7.25073 14.4127 7.25073 13.9985V1.99854C7.25073 1.58432 7.58652 1.24854 8.00073 1.24854Z"
                                fill="black"
                              />
                            </svg>
                            <i
                              onClick={() => {
                                alert('Feature in development');
                              }}>
                              <span>Add credits</span>
                            </i>
                          </div>
                          <div className="active-action-btn active-action-btn--setting">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="3.5" cy="8" r="1.5" fill="black" />
                              <circle cx="8" cy="8" r="1.5" fill="black" />
                              <circle cx="12.5" cy="8" r="1.5" fill="black" />
                            </svg>
                            <i
                              onClick={() => {
                                alert('Feature in development');
                              }}>
                              <span>More options</span>
                            </i>
                          </div>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}

      <Modal
        keepMounted
        open={openPlay}
        onClose={handleClosePlay}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal">
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleClosePlay}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path
                d="M52.0511 38.017C53.6337 38.8654 53.6337 41.1346 52.0511 41.983L35.4381 50.8894C33.9392 51.693 32.125 50.6071 32.125 48.9064L32.125 31.0936C32.125 29.3929 33.9392 28.307 35.4381 29.1106L52.0511 38.017Z"
                fill="black"
              />
            </svg>
          </div>

          <div className="modal-title">
            Do you really want to <br />
            renew subscription?
          </div>
          <div className="modal-text">This action is irreversible</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleClosePlay}>
              Close
            </div>
            <div
              className="modal__send"
              onClick={() => {
                handleClosePlay();
              }}>
              Renew
            </div>
          </div>
        </Box>
      </Modal>

      {openAddCredits && infoBalances?.data?.videoCreditsLeft && (
        <Modal
          keepMounted
          open={openAddCredits}
          onClose={handleCloseAddCredits}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          className="modal">
          <Box sx={style} className="modal-container">
            <div className="modal-close" onClick={handleCloseAddCredits}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              </svg>
            </div>
            <div className="modal-icon">
              <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M40 80c22.091 0 40-17.909 40-40S62.091 0 40 0 0 17.909 0 40s17.909 40 40 40Z"
                  fill="#F2F4F6"
                />
                <path
                  d="M40 32.889V47.11M47.111 40H32.89M40 56c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16Z"
                  stroke="#000"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <div className="modal-title">Add credits</div>
            <form onSubmit={handleAddCredits}>
              <div className="input__container">
                <label htmlFor="credits-amount">Credits amount</label>
                <input
                  id="credits-amount"
                  name="amount"
                  type="number"
                  step={1}
                  min={1}
                  max={infoBalances.data.videoCreditsLeft}
                  defaultValue={1}
                />
              </div>
              <div className="modal-action">
                <div className="modal__cancel" onClick={handleCloseAddCredits}>
                  Cancel
                </div>
                <button type="submit" className="modal__send">
                  Add
                </button>
              </div>
            </form>
          </Box>
        </Modal>
      )}

      {showChangeEmailModal && infoData && (
        <Modal
          keepMounted
          open={showChangeEmailModal}
          onClose={() => setShowChangeEmailModal(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          className="modal">
          <Box sx={style} className="modal-container">
            <div className="modal-close" onClick={() => setShowChangeEmailModal(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              </svg>
            </div>
            <div className="modal-icon">
              <svg fill="none" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
                <path
                  d="M24.427 38.573c.788 0 1.427.639 1.427 1.427 0 7.8 6.346 14.146 14.146 14.146 7.8 0 14.146-6.346 14.146-14.146 0-7.8-6.346-14.145-14.146-14.145-2.983 0-5.852.938-8.23 2.64h1.884a1.427 1.427 0 1 1 0 2.854h-5.495a1.427 1.427 0 0 1-1.427-1.427v-5.495a1.427 1.427 0 1 1 2.855 0v2.137A16.976 16.976 0 0 1 40 23c4.54 0 8.81 1.768 12.02 4.98A16.887 16.887 0 0 1 57 40c0 4.54-1.768 8.81-4.98 12.02A16.887 16.887 0 0 1 40 57c-4.54 0-8.81-1.768-12.02-4.98A16.887 16.887 0 0 1 23 40c0-.788.64-1.427 1.427-1.427Z"
                  fill="#000"
                />
              </svg>
            </div>

            <div className="modal-title">Change user email</div>
            <form onSubmit={handleChangeEmail}>
              <div className="input__container">
                <label htmlFor="old-email">Old email</label>
                <input id="old-email" name="oldemail" type="text" readOnly value={infoData.email} />
              </div>
              <div className="input__container">
                <label htmlFor="new-email">New email</label>
                <input id="new-email" name="newemail" type="text" required />
              </div>
              <div className="modal-action">
                <div className="modal__cancel" onClick={() => setShowChangeEmailModal(false)}>
                  Cancel
                </div>
                <button type="submit" className="modal__send">
                  Confirm
                </button>
              </div>
            </form>
          </Box>
        </Modal>
      )}

      <Modal
        keepMounted
        open={openPause}
        onClose={handleClosePause}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal">
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleClosePause}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 24.8125C31.932 24.8125 32.6875 25.568 32.6875 26.5L32.6875 53.5C32.6875 54.432 31.932 55.1875 31 55.1875C30.068 55.1875 29.3125 54.432 29.3125 53.5L29.3125 26.5C29.3125 25.568 30.068 24.8125 31 24.8125Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49 24.8125C49.932 24.8125 50.6875 25.568 50.6875 26.5L50.6875 53.5C50.6875 54.432 49.932 55.1875 49 55.1875C48.068 55.1875 47.3125 54.432 47.3125 53.5L47.3125 26.5C47.3125 25.568 48.068 24.8125 49 24.8125Z"
                fill="black"
              />
            </svg>
          </div>

          <div className="modal-title">
            Do you really want to
            <br />
            pause subscription?
          </div>
          <div className="modal-text">This action is irreversible</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleClosePause}>
              Close
            </div>
            <div
              className="modal__delete"
              onClick={() => {
                handleClosePause();
              }}>
              Pause
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal">
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleCloseDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path
                d="M33.25 25.375L25.375 33.25L33.25 41.125"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
              />
              <path
                d="M26.5 33.25H43.375C48.9669 33.25 53.5 37.7831 53.5 43.375V43.375C53.5 48.9669 48.9669 53.5 43.375 53.5H28.75"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
              />
            </svg>
          </div>

          <div className="modal-title">
            Do you really want to
            <br />
            cancel subscription?
          </div>
          <div className="modal-text">This action is irreversible</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleCloseDelete}>
              Close
            </div>
            <div
              className="modal__delete"
              onClick={() => {
                handleCloseDelete();
              }}>
              Cancel
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openRefund}
        onClose={handleCloseRefund}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal">
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleCloseRefund}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.261 29.2638C29.92 28.6048 30.9885 28.6048 31.6475 29.2638L50.7394 48.3557C51.3984 49.0147 51.3984 50.0831 50.7394 50.7422C50.0804 51.4012 49.0119 51.4012 48.3529 50.7422L29.261 31.6503C28.602 30.9913 28.602 29.9228 29.261 29.2638Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.7395 29.2596C51.3985 29.9187 51.3985 30.9871 50.7395 31.6461L31.6476 50.738C30.9886 51.397 29.9201 51.397 29.2611 50.738C28.6021 50.079 28.6021 49.0105 29.2611 48.3515L48.353 29.2596C49.012 28.6006 50.0805 28.6006 50.7395 29.2596Z"
                fill="black"
              />
            </svg>
          </div>

          <div className="modal-title">
            Do you really want to
            <br />
            do refund?
          </div>
          <div className="modal-text">This action is irreversible</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleCloseRefund}>
              Close
            </div>
            <div
              className="modal__delete"
              onClick={() => {
                handleCloseRefund();
              }}>
              Refund
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UserInfo;
