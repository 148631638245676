import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './sidebar.scss';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import { gsap } from 'gsap';
import AdminsSidebar from '../../pages/admins/AdminsSidebar';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';
import { error } from 'jquery';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const SideBarAdmins = ({
  onSetSidebarOpen,
  setSnackbarOpen,
  setSnackbarText,
  activeUserData,
  setSnackbarCount,
  defaultValue,
  setDefaultValue,
  open,
  setOpen
}) => {
  const handleOpen = (e) => {
    buttonAnimation(e.target);
    setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handleClose = (e) => {
    if (e.target.className.includes('MuiModal-backdrop')) {
      setOpenInvoice(false);
      return;
    }
    buttonAnimation(e.target);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const [openReset, setOpenReset] = React.useState(false);
  const handleOpenReset = (e) => {
    buttonAnimation(e.target);
    setTimeout(() => {
      setOpenReset(true);
    }, 500);
  };
  const handleCloseReset = (e) => {
    if (e.target.className.includes('MuiModal-backdrop')) {
      setOpenInvoice(false);
      return;
    }
    buttonAnimation(e.target);

    setTimeout(() => {
      setOpenReset(false);
      setModalPassword(false);
    }, 500);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '540rem',
    background: '#fff',
    borderRadius: '24rem',
    padding: '60rem 68rem 60rem'
  };

  const [value, setValue] = React.useState(0);

  const buttonAnimation = (element) => {
    const tl = gsap.timeline();
    tl.to(element, {
      duration: 0.085,
      scale: 0.85,
      opacity: 0.85,
      ease: 'power4.out'
    });
    tl.to(element, {
      duration: 0.55,
      scale: 1,
      opacity: 1,
      ease: 'elastic.out(1,0.6)'
    });
  };
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const deleteAdmin = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('authToken')}`
      };
      const response = await axios.delete(`${config.apiBaseUrl}admins/${activeUserData[0]}`, {
        headers
      });
      const { data } = response.data;
      setSnackbarOpen(true);
      setSnackbarText(`${activeUserData[0]} <span>successfully deleted</span>`);
      setSnackbarCount(10);
      onSetSidebarOpen(false, '');
    } catch (e) {
      console.error(e);
    }
  };

  const resetPassword = async (e) => {
    if (password !== confirmPassword) {
      return setErrorPassword('Passwords do not match.');
    }
    handleCloseReset(e);

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('authToken')}`
      };
      const requestBody = {
        password
      };
      const response = await axios.patch(
        `${config.apiBaseUrl}admins/${activeUserData[0]}`,
        requestBody,
        { headers }
      );
      const { data } = response.data;
      setSnackbarOpen(true);
      setSnackbarText(`${activeUserData[0]} <span>successfully updated</span>`);
      setSnackbarCount(10);
      onSetSidebarOpen(false, '');
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorPassword('');
  };
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrorPassword('');
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div className="sidebar-wrapper">
        <div className="sidebar-nav">
          <div className="sidebar-close" onClick={() => onSetSidebarOpen(false, '')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        <AdminsSidebar
          onSetSidebarOpen={onSetSidebarOpen}
          value={value}
          handleOpenReset={handleOpenReset}
          handleOpen={handleOpen}
          activeUserData={activeUserData}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarText={setSnackbarText}
          setSnackbarCount={setSnackbarCount}
        />
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal"
      >
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path
                d="M40.0006 23.9297C43.0559 23.9297 45.0472 25.5157 45.6857 28.4291L53.5006 28.4297C54.2106 28.4297 54.7863 29.0053 54.7863 29.7154C54.7863 30.4255 54.2106 31.0011 53.5006 31.0011H52.0798L50.0069 50.6906C49.8003 52.6537 48.1449 54.144 46.171 54.144H33.8301C31.8562 54.144 30.2008 52.6537 29.9942 50.6906L27.9213 31.0011H26.5006C25.7905 31.0011 25.2148 30.4255 25.2148 29.7154C25.2148 29.0053 25.7905 28.4297 26.5006 28.4297L34.3155 28.4291C34.9539 25.5157 36.9452 23.9297 40.0006 23.9297ZM49.493 31.0011H30.5068L32.5515 50.4214C32.6204 51.0758 33.1722 51.5725 33.8301 51.5725H46.171C46.829 51.5725 47.3807 51.0758 47.4496 50.4214L49.493 31.0011ZM40.0006 33.5725C40.7106 33.5725 41.2863 34.1482 41.2863 34.8583V47.7154C41.2863 48.4255 40.7106 49.0011 40.0006 49.0011C39.2905 49.0011 38.7148 48.4255 38.7148 47.7154V34.8583C38.7148 34.1482 39.2905 33.5725 40.0006 33.5725ZM45.2076 33.5741C45.9168 33.6096 46.463 34.2133 46.4275 34.9225L45.7847 47.7796C45.7492 48.4888 45.1455 49.035 44.4364 48.9995C43.7272 48.9641 43.181 48.3604 43.2164 47.6512L43.8593 34.7941C43.8948 34.0849 44.4984 33.5387 45.2076 33.5741ZM34.7935 33.5741C35.5027 33.5387 36.1064 34.0849 36.1418 34.7941L36.7847 47.6512C36.8201 48.3604 36.274 48.9641 35.5648 48.9995C34.8556 49.035 34.2519 48.4888 34.2164 47.7796L33.5736 34.9225C33.5381 34.2133 34.0843 33.6096 34.7935 33.5741ZM40.0006 26.5011C38.3719 26.5011 37.4295 27.085 36.9763 28.4291H43.0248C42.5716 27.085 41.6292 26.5011 40.0006 26.5011Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="modal-title">
            Do you really want to <br />
            delete this account?
          </div>
          <div className="modal-text">This action is irreversible</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleClose}>
              Cancel
            </div>
            <div
              className="modal__delete"
              onClick={(e) => {
                buttonAnimation(e.target);
                handleClose(e);
                deleteAdmin(activeUserData[0]);
              }}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openReset}
        onClose={handleCloseReset}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal"
      >
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleCloseReset}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path
                d="M43.9707 39.1597V36.5345C43.9707 34.2802 42.1358 32.4453 39.8815 32.4453C37.6272 32.4453 35.7923 34.2802 35.7923 36.5345V39.1597C34.9548 39.3669 34.332 40.1188 34.332 41.0207V45.626C34.332 46.688 35.193 47.549 36.255 47.549H43.5092C44.5712 47.549 45.4322 46.688 45.4322 45.626V41.0207C45.4309 40.1188 44.8081 39.3669 43.9707 39.1597ZM39.8815 34.6797C40.9038 34.6797 41.7363 35.5122 41.7363 36.5345V39.0977H38.0267V36.5345C38.0267 35.5122 38.8592 34.6797 39.8815 34.6797Z"
                fill="black"
              />
              <path
                d="M55.5727 38.573C54.7846 38.573 54.1457 39.2119 54.1457 40C54.1457 47.7999 47.7999 54.1457 40.0003 54.1457C32.2001 54.1457 25.8543 47.7999 25.8543 40C25.8543 32.2001 32.2001 25.8546 40 25.8546C42.9832 25.8546 45.8517 26.7927 48.23 28.4951H46.3461C45.558 28.4951 44.9188 29.1339 44.9188 29.9224C44.9188 30.7105 45.558 31.3494 46.3461 31.3494H51.8408C52.629 31.3494 53.2678 30.7105 53.2678 29.9224V24.4273C53.2678 23.6389 52.6293 23 51.8408 23C51.0524 23 50.4135 23.6389 50.4135 24.4273V26.5642C47.4582 24.2719 43.8073 23 40 23C35.4593 23 31.1899 24.7683 27.9793 27.9793C24.768 31.1903 23 35.4593 23 40C23 44.5407 24.768 48.8101 27.9793 52.0207C31.1899 55.232 35.4593 57 40 57C44.5404 57 48.8097 55.232 52.0204 52.0207C55.2317 48.8101 57 44.5407 57 40C57 39.2119 56.3608 38.573 55.5727 38.573Z"
                fill="black"
              />
            </svg>
          </div>

          {modalPassword ? (
            <>
              <div className="modal-title">
                Create and Confirm
                <br />
                new password
              </div>
              <div className="modal-admins-password">
                <div className="admins-sidebar-info__input">
                  <span>Password*</span>
                  <div className="input">
                    <input
                      value={password}
                      onChange={handleChangePassword}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Create password"
                    />
                    <i onClick={toggleShowPassword}>
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0006 0.710938C14.7102 0.710938 19.0363 4.29116 19.0363 6.99665C19.0363 9.70215 14.7102 13.2824 10.0006 13.2824C5.29094 13.2824 0.964844 9.70215 0.964844 6.99665C0.964844 4.29116 5.29094 0.710938 10.0006 0.710938ZM10.0006 2.28237C6.10006 2.28237 2.53627 5.2317 2.53627 6.99665C2.53627 8.7616 6.10006 11.7109 10.0006 11.7109C13.9011 11.7109 17.4648 8.7616 17.4648 6.99665C17.4648 5.2317 13.9011 2.28237 10.0006 2.28237ZM10.0006 3.85379C11.7363 3.85379 13.1434 5.2609 13.1434 6.99665C13.1434 8.7324 11.7363 10.1395 10.0006 10.1395C8.26481 10.1395 6.8577 8.7324 6.8577 6.99665C6.8577 5.2609 8.26481 3.85379 10.0006 3.85379ZM10.0006 5.42522C9.13268 5.42522 8.42913 6.12878 8.42913 6.99665C8.42913 7.86453 9.13268 8.56808 10.0006 8.56808C10.8684 8.56808 11.572 7.86453 11.572 6.99665C11.572 6.12878 10.8684 5.42522 10.0006 5.42522Z"
                          fill="#A7A8AA"
                        />
                      </svg>
                    </i>
                  </div>
                </div>
                <div className="admins-sidebar-info__input">
                  <span>Confirm password*</span>
                  <div className="input">
                    <input
                      value={confirmPassword}
                      onChange={handleChangeConfirmPassword}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Confirm password "
                    />
                    <i onClick={toggleShowPassword}>
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0006 0.710938C14.7102 0.710938 19.0363 4.29116 19.0363 6.99665C19.0363 9.70215 14.7102 13.2824 10.0006 13.2824C5.29094 13.2824 0.964844 9.70215 0.964844 6.99665C0.964844 4.29116 5.29094 0.710938 10.0006 0.710938ZM10.0006 2.28237C6.10006 2.28237 2.53627 5.2317 2.53627 6.99665C2.53627 8.7616 6.10006 11.7109 10.0006 11.7109C13.9011 11.7109 17.4648 8.7616 17.4648 6.99665C17.4648 5.2317 13.9011 2.28237 10.0006 2.28237ZM10.0006 3.85379C11.7363 3.85379 13.1434 5.2609 13.1434 6.99665C13.1434 8.7324 11.7363 10.1395 10.0006 10.1395C8.26481 10.1395 6.8577 8.7324 6.8577 6.99665C6.8577 5.2609 8.26481 3.85379 10.0006 3.85379ZM10.0006 5.42522C9.13268 5.42522 8.42913 6.12878 8.42913 6.99665C8.42913 7.86453 9.13268 8.56808 10.0006 8.56808C10.8684 8.56808 11.572 7.86453 11.572 6.99665C11.572 6.12878 10.8684 5.42522 10.0006 5.42522Z"
                          fill="#A7A8AA"
                        />
                      </svg>
                    </i>
                  </div>
                  {errorPassword && (
                    <p style={{ color: '#F43535', marginTop: '10rem' }}>{errorPassword}</p>
                  )}
                </div>
              </div>

              <div className="modal-action">
                <div className="modal__cancel" onClick={handleCloseReset}>
                  Cancel
                </div>
                <div
                  className="modal__send"
                  onClick={(e) => {
                    resetPassword(e);
                  }}
                >
                  Save
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal-title">
                Do you really want to
                <br />
                reset password this users?
              </div>
              <div className="modal-text">
                The user will receive a password recovery link to his email
              </div>
              <div className="modal-action">
                <div className="modal__cancel" onClick={handleCloseReset}>
                  Cancel
                </div>
                <div
                  className="modal__send"
                  onClick={(e) => {
                    setModalPassword(true);
                  }}
                >
                  Resent
                </div>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default SideBarAdmins;
