import React from 'react';
import './select.scss';
import Select, { components } from 'react-select';
import Arrow from '../../svg/Arrow';

const SelectAdminsSibebar = ({ onSelectChange, defaultValue, options }) => {
  // const customStyles = {
  //     control: (provided) => ({
  //         ...provided,
  //         // Добавьте здесь необходимые стили для контрола (корневого элемента).
  //     }),
  //     // indicatorSeparator: () => ({
  //     //     display: 'none',
  //     // }),
  //     option: (provided, state) => ({
  //         ...provided,
  //         backgroundColor: state.isSelected ? 'black' : '#fff',
  //         color: state.isSelected ? 'white' : 'black',
  //         whiteSpace: 'nowrap',
  //         fontSize: "24rem",
  //         padding: '0rem'
  //     }),
  //     singleValue: () => ({
  //         color: '#000',
  //         fontFamily: "Italian Plate No2 Expanded",
  //         fontSize: "24rem",
  //         fontWeight: 600,
  //         maxWidth: "100%",
  //         overflow: "hidden",
  //         textOverflow: "ellipsis",
  //         whiteSpace:" nowrap",
  //     }),

  // };
  const customStyles = {
    control: (provided) => ({
      ...provided
      // Add any necessary styles for the control (root element) here.
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
      height: '46rem',
      border: '1px solid #f2f4f6',
      borderRadius: '9rem',
      padding: '0rem 16rem 0rem 0rem'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#fff' : state.isFocused ? '#F2F4F6' : '#fff',
      color: state.isSelected ? '#000' : state.isFocused ? '#000' : '#000',
      whiteSpace: 'nowrap',
      padding: '0rem',
      borderBottom: '1rem solid #F2F4F6',
      fontFamily: 'Italian Plate No2 Expanded',
      fontWeight: 500,
      height: '56rem',
      fontSize: '16rem',
      display: 'flex',
      alignItems: 'center',
      ':last-child': {
        borderBottom: 'none',
        marginBottom: '0'
      },
      ':active': {
        backgroundColor: '#F2F4F6'
      }
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      overflowY: 'hidden',
      padding: '0',
      borderRadius: '8rem',
      border: '1rem solid var(--Gray-light, #F2F4F6)',
      boxShadow: '0rem 7rem 41rem -6rem rgba(0, 0, 0, 0.08)'
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      width: '100%'
    }),
    singleValue: (...provided) => ({
      ...provided,
      color: '#404040',
      fontFamily: 'Italian Plate No2 Expanded',
      fontSize: '16rem',
      width: '100%',
      fontWeight: 600,
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: ' nowrap',
      gridArea: '1/1/2/3',
      margin: '0',
      width: '100%',
      padding: '14rem 0rem 14rem 16rem',
      border: 'none'
    }),

    input: (...provided) => ({
      ...provided,

      flex: '1 1 auto',
      display: 'inline-grid',
      gridArea: '1/1/2/3',
      gridTemplateColumns: ' 0 min-content',
      margin: '0',
      padding: '0'
      // width: '100%'
    })
  };

  const customComponents = {
    DropdownIndicator: (props) => (
      <div className="custom-dropdown-indicator">
        <Arrow width="16rem" height="16rem" />
      </div>
    ),
    Option: (props) => (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center', height: '56rem', padding: '0 16rem' }}>
          <span className={`icon-${props.data.color}`}></span>
          <span>{props.data.label}</span>
        </div>
      </components.Option>
    ),
    SingleValue: (props) => (
      <components.SingleValue {...props}>
        {props.data.label || 'Choose role'}
      </components.SingleValue>
    )
  };
  // const options = [
  //     { value: 'Customer Support', label: 'Customer Support',color: "yellow" },
  //     { value: 'Ad Moderation Support', label: 'Ad Moderation Support', color: "green"},
  //     { value: 'Advanced Support', label: 'Advanced Support', color: "orange"},
  //     { value: 'Administrator', label: 'Administrator', color: "blue"},
  // ];

  return (
    <div className="select">
      <Select
        options={options}
        styles={customStyles}
        components={customComponents}
        // defaultValue={defaultValue}
        value={defaultValue}
        className="select-item"
        placeholder="Select an option"
        onChange={(selectedOption) => onSelectChange(selectedOption)}
      />
    </div>
  );
};

export default SelectAdminsSibebar;
