import React from 'react';
import './style.scss';
import useFormattedDate from '../../../../hooks/useFormattedDate';

const osValues = {
  webapp: 'Web App',
  android: 'Android',
  ios: 'iOS'
};
const AppOS = ({ os }) => {
  if (!os) return '-';
  const osValue = osValues[os.toLowerCase()];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4rem',
        fontSize: '12rem',
        padding: '0 16rem',
        marginBottom: '16rem'
      }}>
      {osValue && (
        <span
          className={`icon-os`}
          style={{
            background: `url(${process.env.PUBLIC_URL}/img/${os.toLowerCase()}.svg)`,
            margin: '0'
          }}></span>
      )}
      {osValues[os.toLowerCase()] || os}
    </div>
  );
};

function VideosTable({ rows, isLoading, handleOpenBlock }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 475
  };

  return (
    <div className="videos-table products-videos">
      {rows?.map((video, index) => (
        <div className="creatives-item" key={index}>
          <a onClick={() => handleOpenBlock(video)} className={`creatives-item__img`}>
            {video.status === 'FAILED' && (
              <div className="creatives-item__img-failed">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.5364 5.17398L27.1102 21.929C28.2227 23.8765 26.8164 26.3002 24.5739 26.3002H5.42644C3.18269 26.3002 1.77644 23.8765 2.89019 21.929L12.4639 5.17398C13.5852 3.21023 16.4152 3.21023 17.5364 5.17398Z"
                    fill="white"
                    stroke="#EF2C4F"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 16.3996V11.7246M15 20.7809C14.8275 20.7809 14.6875 20.6409 14.6875 20.4684C14.6863 20.2959 14.8263 20.1559 14.9988 20.1559L15.5 20.4995"
                    stroke="#EF2C4F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Rendering failed
              </div>
            )}
            {video.status === 'DRAFT' && (
              <div className="creatives-item__img-render">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.5001 22.5H17.5001M21.2239 3.75H8.77637C8.08637 3.75 7.52637 4.31 7.52637 5V7.9725C7.52637 8.49875 7.69262 9.0125 8.00137 9.43875L12.0264 15L8.00137 20.5612C7.69262 20.9875 7.52637 21.5013 7.52637 22.0275V25C7.52637 25.69 8.08637 26.25 8.77637 26.25H21.2226C21.9126 26.25 22.4726 25.69 22.4726 25V22.0725C22.4726 21.5175 22.2876 20.9787 21.9476 20.54L17.6514 15L21.9489 9.45875C22.2889 9.02 22.4739 8.48125 22.4739 7.92625V5C22.4739 4.31 21.9139 3.75 21.2239 3.75Z"
                    stroke="#CAD4DD"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Rendering
              </div>
            )}
            {video.status === 'FINISHED' && (
              <>
                {' '}
                <img style={{ ...style, width: '100%', padding: 0 }} src={video.coverUrl} />
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="33" height="33" rx="9.5" fill="white" />
                  <rect x="0.5" y="0.5" width="33" height="33" rx="9.5" stroke="#F2F4F6" />
                  <path
                    d="M22.3561 16.1187C23.0594 16.4957 23.0594 17.5043 22.3561 17.8813L14.9725 21.8397C14.3063 22.1969 13.5 21.7143 13.5 20.9584L13.5 13.0416C13.5 12.2857 14.3063 11.8031 14.9725 12.1603L22.3561 16.1187Z"
                    fill="black"
                  />
                </svg>
              </>
            )}

            <div
              className={`creatives-item__ai ${video.status === 'FINISHED' ? 'creatives-item__ai--finished' : ''} ${video.status === 'FAILED' ? 'creatives-item__ai--failed' : ''} ${video.status === 'DRAFT' ? 'creatives-item__ai--render' : ''}`}>
              <span>
                <i></i>
                {video.status}
              </span>
            </div>
          </a>
          <div>
            <div className="creatives-item-info">
              <div className="creatives-item-top">
                <div className="creatives-item__social">{/* {video.avatar} */}</div>
                <div className="creatives-item__size">{/* {video.template} */}</div>
              </div>
              <div className="creatives-item__date">
                Creation date: {video.createdAt && useFormattedDate(video.createdAt)}
              </div>
              <div className="creatives-item__title">{video.name}</div>
              <div className="creatives-item__duration">
                {video.avatarDuration?.toFixed(0)} sec · 9:16
              </div>
              <AppOS os={video.appOs} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default VideosTable;
