import React from 'react';
import './style.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import FormattedDate from '../../../../components/formatted-date/FormattedDate';

const osValues = {
  webapp: 'Web App',
  android: 'Android',
  ios: 'iOS'
};
const AppOS = ({ os }) => {
  if (!os) return '-';
  const osValue = osValues[os.toLowerCase()];

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4rem' }}>
      {osValue && (
        <span
          className={`icon-os`}
          style={{
            background: `url(${process.env.PUBLIC_URL}/img/${os.toLowerCase()}.svg)`
          }}></span>
      )}
      {osValues[os.toLowerCase()] || os}
    </div>
  );
};

function ProductsTable({ rows, isLoading, handleOpenBlock }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="table-head-cell">Cover</TableCell>
            <TableCell className="table-head-cell">Name</TableCell>
            <TableCell className="table-head-cell">Type</TableCell>
            <TableCell className="table-head-cell">Platform</TableCell>
            <TableCell className="table-head-cell">Price</TableCell>
            <TableCell className="table-head-cell">Create date</TableCell>
            <TableCell className="table-head-cell">CTA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              onClick={() => handleOpenBlock(row)}
              style={{ cursor: 'pointer' }}
              key={row.id}>
              <TableCell className="table-body-cell">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'60rem'} height={'60rem'} />
                ) : (
                  <div className="products-table__img">
                    <img src={row.photos?.[0]?.url || ''} alt="" />
                  </div>
                )}
              </TableCell>
              <TableCell className="table-body-cell">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'200rem'} height={'17rem'} />
                ) : (
                  <div className="products-table-info">
                    <h4>{row.description}</h4>
                    <p>{row.promotionUrl}</p>
                  </div>
                )}
              </TableCell>
              <TableCell className="table-body-cell">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'40rem'} height={'17rem'} />
                ) : (
                  <div>{row.productType}</div>
                )}
              </TableCell>
              <TableCell className="table-body-cell">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'16rem'} height={'16rem'} />
                ) : (
                  <AppOS os={row.appOs} />
                )}
              </TableCell>
              <TableCell className="table-body-cell">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'16rem'} height={'16rem'} />
                ) : (
                  <div className="products-table-price">
                    {row.price !== null && `$${row.price?.toFixed(2)}`}
                    {row.oldPrice !== null ? <span>${row.oldPrice?.toFixed(2)}</span> : null}
                  </div>
                )}
              </TableCell>
              <TableCell className="table-body-cell">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'40rem'} height={'17rem'} />
                ) : (
                  <div>
                    <FormattedDate date={row.createdAt} />
                  </div>
                )}
              </TableCell>
              <TableCell className="table-body-cell">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'100rem'} height={'17rem'} />
                ) : (
                  <div style={{ textAlign: 'left' }}>{row.callToAction}</div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProductsTable;
