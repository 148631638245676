import React, { useState, useRef, useEffect } from 'react';
import './filter.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Arrow from '../../svg/Arrow';
import Tick from '../../svg/Tick';
import CloseIcon from '../../svg/CloseIcon';
import DatePicker from 'react-datepicker';

const FilterLogs = ({ setActiveFilters, additionalValues, setFormData, setToData }) => {
  const [startDate, setStartDate] = useState(new Date('2023-01-1'));
  const [endDate, setEndDate] = useState(new Date());
  const [endDateRequest, setEndDateRequest] = useState(new Date());

  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const monthStart = padZero(startDate.getMonth() + 1); // Месяцы в JavaScript нумеруются с 0
  const dayStart = padZero(startDate.getDate());
  const yearStart = startDate.getFullYear();
  const yearStartShort = padZero(startDate.getFullYear() % 100);
  const startDateFormattedDate = `${dayStart}.${monthStart}.${yearStartShort}`;
  setFormData(`${monthStart}-${dayStart}-${yearStart}`);
  //const startDateFormattedDate = `${monthStart}-${dayStart}-${yearStart}`;

  const monthEnd = padZero(endDateRequest.getMonth() + 1); // Месяцы в JavaScript нумеруются с 0
  const dayEnd = padZero(endDateRequest.getDate());
  const yearEnd = endDateRequest.getFullYear();
  const yearEndShort = padZero(endDateRequest.getFullYear() % 100);
  //const endDateFormattedDate = `${monthEnd}-${dayEnd}-${yearEnd}`;
  const endDateFormattedDate = `${dayEnd}.${monthEnd}.${yearEndShort}`;
  setToData(`${monthEnd}-${dayEnd}-${yearEnd}`);
  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    setStartDate(firstDayOfMonth);
  }, []);

  const [isBlockVisible, setIsBlockVisible] = useState(false);
  const dateShow = () => {
    setIsBlockVisible(true);
  };

  const dateHide = () => {
    setIsBlockVisible(false);
  };
  const dataPickerLogoutRef = useRef(null);

  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const profileLogoutRef = useRef(null);

  const handleProfileWrapClick = () => {
    setIsLogoutVisible(!isLogoutVisible);
  };

  const handleClickOutside = (event) => {
    if (profileLogoutRef.current && !profileLogoutRef.current.contains(event.target)) {
      setIsLogoutVisible(false);
    }
  };

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate) {
      setEndDateRequest(newEndDate);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isVisibleMore, setIsVisibleMore] = useState(false);
  const [countMore, setCountMore] = useState(0);

  const [isActive, setIsActive] = useState(false); // Исходно класс не активен

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const opacityValue = isVisibleMore ? 1 : 0;
  const pointerValue = isVisibleMore ? 'auto' : 'none';

  const [selectedFilters, setSelectedFilters] = useState({
    createdDate: false
  });

  setActiveFilters(selectedFilters);

  const clear = () => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      Object.keys(updatedFilters).forEach((key) => {
        updatedFilters[key] = false;
      });
      return updatedFilters;
    });
  };

  const [valueLabelMap, setValueLabelMap] = useState({
    createdDate: `${startDateFormattedDate} - ${endDateFormattedDate}`
  });

  const updateDateLabels = () => {
    setValueLabelMap((prevValues) => ({
      ...prevValues,
      createdDate: `${startDateFormattedDate} - ${endDateFormattedDate}`
    }));
  };

  useEffect(() => {
    updateDateLabels();
  }, [startDate, endDate]);

  const handleCheckboxChange = (filterKey) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey]
    }));
  };

  // const handleRadioChange = (filterKey) => {
  //     setSelectedFilters((prevFilters) => {
  //         const updatedFilters = { ...prevFilters };

  //         additionalValues.forEach((value) => {
  //             if (filterKey === value.name) {
  //                 updatedFilters[filterKey] = !prevFilters[filterKey];
  //             } else {
  //                 updatedFilters[value.name] = false;
  //             }
  //         });

  //         return updatedFilters;
  //     });
  // };

  const filterData = () => {
    const filterOrder = [];

    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key]) {
        filterOrder.push(key);
      }
    });

    const filteredResults = {};
    filterOrder.forEach((key) => {
      filteredResults[key] = valueLabelMap[key];
    });

    return filteredResults;
  };

  const filteredResults = filterData();

  const filterResultsRef = useRef(null);

  useEffect(() => {
    const updatedValueLabelMap = { ...valueLabelMap };
    const updatedSelectedFilters = { ...selectedFilters };
    additionalValues.forEach((value) => {
      updatedValueLabelMap[value.name] = value.name;
      updatedSelectedFilters[value.name] = false;
    });

    setValueLabelMap(updatedValueLabelMap);
    setSelectedFilters(updatedSelectedFilters);
  }, [additionalValues]);

  useEffect(() => {
    const filterResultsElement = filterResultsRef.current;

    if (filterResultsElement) {
      const items = filterResultsElement.querySelectorAll('.filter-results-item');
      items.forEach((item) => {
        item.style.display = 'flex';
      });

      let currentIndex = 0;
      setIsVisibleMore(false);
      setCountMore(0);
      const showNextItem = () => {
        if (currentIndex < items.length) {
          if (filterResultsElement.clientHeight <= 50) {
            currentIndex++;
            showNextItem();
          } else {
            items[currentIndex].style.display = 'none';
            let count = items.length - (items.length - currentIndex);
            setCountMore(count);
            setIsVisibleMore(true);
            if (count == 0) {
              setIsVisibleMore(false);
            }
            currentIndex++;
            showNextItem();
          }
        }
      };
      showNextItem();
    }
  }, [filteredResults]);
  const isAllFalse = Object.values(selectedFilters).every((value) => value === false);

  return (
    <div className="filter">
      <div className="filter-btn" onClick={handleProfileWrapClick}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="4.18164" width="5" height="1.6364" rx="0.818198" fill="black" />
          <circle cx="6" cy="5" r="3" fill="black" />
          <path
            d="M11.5 4.99984C11.5 4.54796 11.8663 4.18164 12.3182 4.18164H17.1818C17.6337 4.18164 18 4.54796 18 4.99984C18 5.45172 17.6337 5.81804 17.1818 5.81804H12.3182C11.8663 5.81804 11.5 5.45172 11.5 4.99984Z"
            fill="black"
          />
          <path
            d="M0 12.9998C0 12.548 0.36632 12.1816 0.818198 12.1816H5.6818C6.13368 12.1816 6.5 12.548 6.5 12.9998C6.5 13.4517 6.13368 13.818 5.6818 13.818H0.818198C0.366319 13.818 0 13.4517 0 12.9998Z"
            fill="black"
          />
          <circle cx="12" cy="13" r="3" fill="black" />
          <rect x="13" y="12.1816" width="5" height="1.6364" rx="0.818198" fill="black" />
        </svg>
        Filters
      </div>
      {isLogoutVisible && (
        <div className="filter-dropdown" ref={profileLogoutRef}>
          <div className="filter-dropdown-top">
            <div className="filter-dropdown__title">Filters</div>
            <div className="filter-dropdown__close" onClick={clear}>
              Clear all
            </div>
          </div>
          <div className="filter-dropdown-accordion">
            <Accordion>
              <AccordionSummary>
                Date
                <Arrow />
              </AccordionSummary>
              <AccordionDetails>
                <div className="createdAt">
                  <label>
                    <input
                      type="checkbox"
                      value="21.07.2023"
                      checked={selectedFilters.createdDate}
                      onChange={() => handleCheckboxChange('createdDate')}
                    />
                    <div className="filter-dropdown-checkbox">
                      <Tick />
                    </div>
                  </label>
                  <div className="analytics-top-date-wrap">
                    <div className="analytics-top-date" onClick={dateShow}>
                      {/* <div className="analytics-top-date-icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.7979 4.93255H3.19792C2.60881 4.93255 2.13125 5.41012 2.13125 5.99922V12.9326C2.13125 13.5217 2.60881 13.9992 3.19792 13.9992H12.7979C13.387 13.9992 13.8646 13.5217 13.8646 12.9326V5.99922C13.8646 5.41012 13.387 4.93255 12.7979 4.93255ZM3.19792 1.19922C1.72516 1.19922 0.53125 2.39313 0.53125 3.86589V12.9326C0.53125 14.4053 1.72516 15.5992 3.19792 15.5992H12.7979C14.2707 15.5992 15.4646 14.4053 15.4646 12.9326V3.86589C15.4646 2.39313 14.2707 1.19922 12.7979 1.19922H3.19792Z" fill="black" />
                                                    <rect x="3.19824" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                                    <rect x="11.1982" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                                </svg>
                                            </div> */}
                      <div className="analytics-top-date-text">
                        {startDateFormattedDate} -{endDateFormattedDate}
                      </div>
                      <div className="analytics-top-date-arrow">
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 1L6 6L1 1"
                            stroke="#A7A8AA"
                            strokeWidth="1.9"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {isBlockVisible && (
                      <div className="analytics-top-date-block" ref={dataPickerLogoutRef}>
                        <DatePicker
                          selected={startDate}
                          onChange={handleChange}
                          selectsRange
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          selectsRange
                          inline
                        />
                        <div className="analytics-top-date__btn" onClick={dateHide}>
                          Select
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                Admin account
                <Arrow />
              </AccordionSummary>
              <AccordionDetails>
                {additionalValues.map((value) => (
                  <label key={value.name}>
                    <input
                      type="checkbox"
                      value={value.name}
                      checked={selectedFilters[value.name]}
                      onChange={() => handleCheckboxChange(value.name)}
                    />
                    <div className="filter-dropdown-checkbox">
                      <Tick />
                    </div>
                    {value.name}
                  </label>
                ))}
              </AccordionDetails>
            </Accordion>
            {/* <Accordion>
                            <AccordionSummary>
                                Change type
                                <Arrow />
                            </AccordionSummary>
                            <AccordionDetails>

                            </AccordionDetails>
                        </Accordion> */}
          </div>
        </div>
      )}
      <div className={`filter-results ${isActive ? 'active' : ''}`} ref={filterResultsRef}>
        {Object.entries(filteredResults).map(([key, value], index) => (
          <div>
            {value && (
              <div className={`filter-results-item`}>
                {value}
                <label>
                  <input
                    type="checkbox"
                    checked={selectedFilters[key]}
                    onChange={() => handleCheckboxChange(key)}
                    value={key}
                  />
                  <CloseIcon />
                </label>
              </div>
            )}
          </div>
        ))}
        <div onClick={toggleActive} className={`filter-results-less ${isActive ? 'active' : ''}`}>
          Less
          <Arrow />
        </div>
      </div>

      <div
        onClick={toggleActive}
        className={`filter-results-more ${isActive ? 'active' : ''}`}
        style={{ opacity: opacityValue }}
      >
        More
        <span>{countMore}</span>
        <Arrow />
      </div>
      {isAllFalse ? (
        <div style={{ display: 'none' }}></div>
      ) : (
        <div className="filter-result-clear" onClick={clear}>
          Clear
        </div>
      )}
    </div>
  );
};

export default FilterLogs;
