import React, { useState } from 'react';
import Header from '../../components/header/Header';
// import AiNavbar from "../../components/AiGenerationNavbar/AiNavbar";
import axios from 'axios';
import Cookies from 'js-cookie';
import AiNavbar from '../../components/ai-navbar/AiNavbar';
import config from '../../config';

export const AiCreativeGeneration = () => {
  const [userId, setUserId] = useState('8e9dbc87-8f7c-4703-b212-b051e2f20078');
  const [siteId, setSiteId] = useState('12281c08-8b20-4411-b0e9-918af0cca179');
  const [creativeThemeId, setCreativeThemeId] = useState('861a67df-5c53-4ec6-802d-a79620957a0f');
  const [adData, setAdData] = useState('');
  const [generationResult, setResult] = useState('');

  const handleUserIdChange = (e) => {
    const newValue = e.target.value;
    setUserId(newValue);
  };

  const handleSiteIdChange = (e) => {
    const newValue = e.target.value;
    setSiteId(newValue);
  };

  const handleCreativeThemeIdChange = (e) => {
    const newValue = e.target.value;
    setCreativeThemeId(newValue);
  };

  const handleAdDataChange = (e) => {
    const newValue = e.target.value;
    setAdData(newValue);
  };

  const generateCreative = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('authToken')}`
      };

      const response = await axios.post(
        `${config.apiBaseUrl}admins/ai/creatives`,
        {
          userId,
          siteId,
          creativeThemeId,
          adData: adData || undefined
        },
        { headers }
      );

      const { data } = response.data;

      setResult(JSON.stringify(data, null, '\t'));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Header />
      {/* <AiNavbar/> */}
      <div className="content">
        <div className="container">
          <AiNavbar />
          <div className="aiPrompts">
            <h3>AI Creative Generation</h3>
            <div className="aiPrompts-inputs">
              <div className="input">
                <span>User id</span>
                <input
                  type="text"
                  value={userId}
                  width="320px"
                  onChange={handleUserIdChange}
                  placeholder="User id"
                />
              </div>

              <div className="input">
                <span>Site id</span>
                <input
                  type="text"
                  value={siteId}
                  width="320px"
                  onChange={handleSiteIdChange}
                  placeholder="Site id"
                />
              </div>

              <div className="input">
                <span>Creative theme id</span>
                <input
                  type="text"
                  value={creativeThemeId}
                  width="320px"
                  onChange={handleCreativeThemeIdChange}
                  placeholder="Creative theme id"
                />
              </div>

              <div className="input">
                <span>Ad data *optional</span>
                <input
                  type="text"
                  value={adData}
                  onChange={handleAdDataChange}
                  placeholder="Ad data *optional"
                />
              </div>
            </div>

            <div className="input" style={{ marginBottom: '32rem' }}>
              <span>Result</span>
              <textarea
                placeholder="Result"
                name="aiResult"
                rows="30"
                cols="150"
                value={generationResult}
              >
                {generationResult}
              </textarea>
            </div>

            <button className="btn" onClick={generateCreative}>
              Generate
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
