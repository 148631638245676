import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import './style.scss';
import ProductImages from '../product-images/ProductImages';
import ProductAds from '../product-ads/ProductAds';
import ProductCreatives from '../product-creatives/ProductCreatives';
import ProductVideos from '../product-videos/ProductVideos';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../../../svg/ArrowBack';
import ArrowNext from '../../../../svg/ArrowNext';

export default function ModalProduct({ openBlock, setOpenBlock, data }) {
  const styleBlock = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    outline: 'none',
    maxHeight: '90vh',
    height: '95vh',
    // overflow: 'hidden',
    borderRadius: '16rem'
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };
  const [activeTab, setActiveTab] = useState('Images');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Modal
      keepMounted
      open={openBlock}
      onClose={handleCloseBlock}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className="modal">
      <Box sx={styleBlock} className="modal-container">
        <div className="creatives-modal-close" onClick={handleCloseBlock}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10 10L26 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M26 10L10 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <div className="products-modal">
            <div className="products-modal-header">
              <div className="products-modal-header__title">Product info</div>
            </div>
            <div className="products-modal-body">
              <div className="products-modal-content">
                {activeTab === 'Images' && <ProductImages data={data} />}
                {activeTab === 'Ads' && <ProductAds data={data} />}
                {activeTab === 'Creatives' && <ProductCreatives data={data} />}
                {activeTab === 'Videos' && <ProductVideos data={data} />}
              </div>
              <div className="products-modal-info">
                <div className="products-modal-info-item">
                  <span>Name</span>
                  <p>{data.title}</p>
                </div>
                <div className="products-modal-info-item">
                  <span>Product link</span>
                  <p>{data.productUrl}</p>
                </div>
                <div className="products-modal-info-item">
                  <span>Link to Ad</span>
                  <p>{data.promotionUrl}</p>
                </div>
                <div className="products-modal-info-wrap">
                  <div className="products-modal-info-item">
                    <span>Product type</span>
                    <p>Physical Product</p>
                  </div>

                  <div className="products-modal-info-item">
                    <span>Old price</span>
                    {data.oldPrice && <p>${data.oldPrice?.toFixed(2)}</p>}
                  </div>
                  <div className="products-modal-info-item">
                    <span>Sale Price</span>
                    <p>${data.price?.toFixed(2)}</p>
                  </div>
                  <div className="products-modal-info-item">
                    <span>CTA</span>
                    <p>{data.callToAction}</p>
                  </div>
                </div>
                <div className="products-modal-info-item">
                  <span>Description</span>
                  <p>{data.description}</p>
                </div>
                <div className="products-modal-info-stat">
                  <div className="products-modal-info-stat-item">
                    <span>Clicks</span>
                    <h4>
                      {data.statistics?.visitors?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </h4>
                    <strong>Unique viewers</strong>
                  </div>
                  <div className="products-modal-info-stat-item">
                    <span>Views</span>
                    <h4>
                      {data.statistics?.reach?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </h4>
                    <strong>Total views</strong>
                  </div>
                  <div className="products-modal-info-stat-item">
                    <span>CTR</span>
                    <h4>${data.statistics?.ctr}%</h4>
                    <strong>Link clicks</strong>
                  </div>
                  <div className="products-modal-info-stat-item">
                    <span>Spent</span>
                    <h4>${data.statistics?.spend?.toFixed(2)}</h4>
                    <strong>Per impression</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="products-modal-footer">
              <div className="table"></div>
              <div className="modal-action">
                <div
                  className="modal__cancel"
                  // onClick={
                  //     handleCloseReset

                  // }
                >
                  Cancel
                </div>
                <div
                  className="modal__send"
                  // onClick={(e) => {

                  //   resetPassword(e)
                  // }}
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
