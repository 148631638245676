import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import './sidebar.scss';
import UserInfo from '../../pages/UserInfo/UserInfo';

import Promotions from '../../pages/promotions/Promotions';
import Creatives from '../../pages/creatives/Creatives';
import Notification from '../../pages/notification/Notification';
import SendHistory from '../../pages/sendHistory/SendHistory';
import Audience from '../../pages/audience/Audience';
import PromotionsSidebar from '../../pages/promotionsPageNew/components/promotions-sidebar/PromotionsSidebar';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const SideBarPromotionsHistory = ({ onSetSidebarOpen, snackbarOpen, setSnackbarOpen, value }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <div className="sidebar-wrapper">
        <div className="sidebar-nav">
          <div className="sidebar-close" onClick={() => onSetSidebarOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M6 6L18 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
              <path d="M18 6L6 18" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        <div className="sidebar-right">
          <PromotionsSidebar />
        </div>
      </div>
    </Box>
  );
};

export default SideBarPromotionsHistory;
