import React from 'react';
import Fancybox from './fancybox';

export default function PromotionItem({ promotion }) {
  return (
    <Fancybox>
      <div className="promotionsNew-AllPromotions-creative">
        <div className="promotionsNew-AllPromotions-creative-active">
          <span></span>
        </div>
        <div className="promotionsNew-AllPromotions-creative-top">
          {promotion.videoUrl ? (
            <div className="promotionsNew-AllPromotions-creative-img promotionsNew-AllPromotions-creative-video">
              <a data-fancybox={promotion.headline} href={promotion.videoUrl}>
                <img src={promotion.imageUrl} alt="" />
              </a>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M28.1275 12.5943C30.0704 13.5568 30.0704 16.4379 28.1275 17.4005L4.06828 29.3197C2.37373 30.1592 0.417969 28.8717 0.417969 26.9166L0.41797 3.07818C0.41797 1.12312 2.37372 -0.164397 4.06828 0.675108L28.1275 12.5943Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : (
            <div className="promotionsNew-AllPromotions-creative-img">
              <img src={promotion.imageUrl} alt="" />
            </div>
          )}

          <div className="promotionsNew-AllPromotions-creative-info">
            <div
              className={`promotionsNew-AllPromotions-status ${promotion.effectiveStatus === 'ACTIVE' ? 'promotionsNew-AllPromotions-status--Active' : ''} ${promotion.effectiveStatus === 'REJECTED' || promotion.effectiveStatus === 'DISAPPROVED' ? 'promotionsNew-AllPromotions-status--Rejected' : ''} ${promotion.effectiveStatus === 'PENDING_REVIEW' || promotion.effectiveStatus === 'IN_PROCESS' ? 'promotionsNew-AllPromotions-status--Pending' : ''}`}>
              <span></span>
              {promotion.effectiveStatus.charAt(0).toUpperCase() +
                promotion.effectiveStatus.slice(1).toLowerCase()}
            </div>
            <div className="promotionsNew-AllPromotions-creative-desc">
              <div className="promotionsNew-AllPromotions-creative-text promotionsNew-AllPromotions-creative-text--head">
                <span>Headline text</span>
                <p>{promotion.headline}</p>
              </div>
              <div className="promotionsNew-AllPromotions-creative-text">
                <span>Primary text</span>
                <p
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '350rem'
                  }}>
                  {promotion.primaryText}
                </p>
              </div>
              <div className="promotionsNew-AllPromotions-creative-text">
                <span>Call to action</span>
                <p>
                  <a href={promotion.leadFormUrl} target="_blank" rel="noopener noreferrer">
                    {promotion.actionButton.replace('_', ' ').toLowerCase()}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="promotionsNew-AllPromotions-creative-bottom">
          <div className="promotionsNew-AllPromotions-creative-bottom-item">
            <strong>{promotion.reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
            <span>Unique viewers</span>
          </div>
          <div className="promotionsNew-AllPromotions-creative-bottom-item">
            <strong>
              {promotion.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </strong>
            <span>Total views</span>
          </div>
          <div className="promotionsNew-AllPromotions-creative-bottom-item">
            <strong>${promotion.spend.toFixed(2)}</strong>
            <span>Spent</span>
          </div>
          <div className="promotionsNew-AllPromotions-creative-bottom-item">
            <strong>{promotion.visitors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
            <span>Link clicks</span>
          </div>
          <div className="promotionsNew-AllPromotions-creative-bottom-item">
            <strong>${promotion.cpc.toFixed(2)}</strong>
            <span>Per impression</span>
          </div>
          <div className="promotionsNew-AllPromotions-creative-bottom-item">
            <strong>{promotion.ctr.toFixed(1)}%</strong>
            <span>Click-through rate</span>
          </div>
          <div className="promotionsNew-AllPromotions-creative-bottom-item">
            <strong>${promotion.cpm.toFixed(2)}</strong>
            <span>Per 1k impressions</span>
          </div>
        </div>
      </div>
    </Fancybox>
  );
}
