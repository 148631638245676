import React, { useState, useEffect } from 'react';
import FilterLogs from '../../components/filter/FilterLogs';
import Header from '../../components/header/Header';
import Search from '../../components/search/Search';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import '../users/users.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import './logs.scss';
import Cookies from 'js-cookie';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import config from '../../config';

const fetchLogsData = async (activePage, selectedSort, filter) => {
  const url = `${config.apiBaseUrl}admins/logs`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const skip = activePage * 100 - 100;
  const params = {
    skip: skip,
    limit: 100,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, { headers, params });
  return response.data;
};

const fetchAdmins = async () => {
  const url = `${config.apiBaseUrl}admins`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

const Logs = () => {
  function createData(
    date,
    adminName,
    adminEmail,
    userName,
    userEmail,
    color,
    action,
    description
  ) {
    return { date, adminName, adminEmail, userName, userEmail, color, action, description };
  }
  // const rows = [
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado', 'keren.alvar12@gmail.com', 'red', 'Refund', '<b>$200.00</b> from the advertising cabinet'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'gray', 'Unsubscribe', 'from Plus tariff'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'gray', 'Change email', 'from <b>keren.alvar13@gmail.com</b> to <b>keren.alvar12@gmail.com</b>'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'red', 'Subscription Refund', '<b>$89.00</b> from the Growth tariff plan'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'red', 'Delete User Account', '<b>Keren Alvarado </b> keren.alvar12@gmail.com'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'gray', 'Changed Domain ', 'from <b>keren.alvar13@gmail.com</b> to <b>keren.alvar12@gmail.com</b>'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'red', 'Delete Website', '<b>https://awcertificacion.zee.am</b>'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'gray', 'Unsubscribe', 'from Plus tariff'),
  //     createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'Keren Alvarado','keren.alvar12@gmail.com', 'gray', 'Unsubscribe', 'from Plus tariff'),
  // ];
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState('0');
  const pages = Math.ceil(total / 100);
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const sorts = [
    { value: 'createdAt', label: 'Created Date' }
    // { value: 'email', label: 'Email' },
    // { value: 'phone', label: 'Phone' },
    // { value: 'name', label: 'Name' },
  ];

  const defaultValue = { value: 'createdAt', label: 'Created Date' };
  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });

  const handleSelectChange = (selectedOption) => {
    if (
      selectedOption.value == 'email' ||
      selectedOption.value == 'name' ||
      selectedOption.value == 'phone'
    ) {
      setSelectedSort({ [selectedOption.value]: 'asc' });
    } else {
      setSelectedSort({ [selectedOption.value]: 'desc' });
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const filter = {};

  // if (searchTerm !== "") {
  //     filter.search = searchTerm;
  // }

  const [activeFilters, setActiveFilters] = useState({});
  const [additionalValues, setAdditionalValues] = useState([]);
  const [fromData, setFormData] = useState('');
  const [toData, setToData] = useState('');

  if (activeFilters.createdDate == true) {
    filter.createdAt = { from: fromData, to: toData };
  }

  const selectedValues = additionalValues.filter((value) => activeFilters[value.name]);

  if (selectedValues.length !== 0) {
    filter.adminId = selectedValues.map((admin) => admin.id);
  }

  const {
    isLoading: adminsLoading,
    error: adminsError,
    data: adminsData
  } = useQuery(['admins'], () => fetchAdmins());

  useEffect(() => {
    if (!adminsLoading && !adminsError && adminsData) {
      setAdditionalValues(adminsData.data);
    }
  }, [adminsLoading, adminsData]);

  const {
    isLoading: isLoading,
    error: error,
    data: logsData
  } = useQuery(['logsData', activePage, selectedSort, filter], () =>
    fetchLogsData(activePage, selectedSort, filter)
  );
  const [loading, setLoading] = useState(false);

  const rowsLoading = [
    createData(),
    createData(),
    createData(),
    createData(),
    createData(),
    createData(),
    createData(),
    createData(),
    createData(),
    createData(),
    createData()
  ];

  useEffect(() => {
    if (isLoading) {
      setRows(rowsLoading);
      setLoading(true);
    }
    if (!isLoading && !error && logsData && logsData.items) {
      setTotal(logsData.total);
      const data = logsData.items.map((log) => {
        const dateString = log.createdAt;
        const dateObject = new Date(dateString);

        const formattedDate = dateObject.toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        });
        let color = 'gray';

        function snakeCaseToTitleCase(str) {
          return str
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }

        let type = snakeCaseToTitleCase(log.type);

        let text;

        const generateText = (newData, oldData) => {
          const changes = Object.keys(newData)
            .map((key) => {
              const oldValue = oldData[key];
              const newValue = newData[key];

              if (oldValue !== newValue) {
                return `<b>${oldValue}</b> to <b>${newValue}</b>`;
              }

              return null;
            })
            .filter(Boolean);

          return `From ${changes.join(', ')}`;
        };

        if (log.type === 'UPDATE_USER') {
          text = generateText(log.data.newData, log.data.oldData);
        }

        return createData(
          formattedDate,
          log.admin.name,
          log.admin.email,
          log.user.name,
          log.user.email,
          color,
          type,
          text
        );
      });

      setRows(data);
      setLoading(false);
    }
  }, [isLoading, error, logsData]);

  return (
    <div className="main">
      <Header />
      <div className="content">
        <div className="container">
          <div className="users-search">
            <Search onSearchChange={handleSearchChange} />
            <FilterLogs
              setActiveFilters={setActiveFilters}
              additionalValues={additionalValues}
              setFormData={setFormData}
              setToData={setToData}
            />
          </div>
          <div className="table">
            <div className="table-top">
              <div className="table-top__total">Total users result: {total}</div>
              <div className="pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={pages}
                    siblingCount={1}
                    page={activePage}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem slots={{ previous: ArrowBack, next: ArrowNext }} {...item} />
                    )}
                  />
                </Stack>
                <div className="pagination__page">100/Page</div>
              </div>
              <SelectCustom
                sorts={sorts}
                onSelectChange={handleSelectChange}
                defaultValue={defaultValue}
              />
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date and time</TableCell>
                    <TableCell>Admin account</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Change</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row" style={{ width: '240rem' }}>
                        {loading ? (
                          <Skeleton variant="rounded" style={{ width: '100%' }} height={17} />
                        ) : (
                          <>{row.date}</>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="table-admin">
                          <strong>
                            {loading ? (
                              <Skeleton
                                variant="rounded"
                                style={{ width: '100%', marginBottom: '6rem' }}
                                height={17}
                              />
                            ) : (
                              <>{row.adminName}</>
                            )}
                          </strong>
                          <span>
                            {loading ? (
                              <Skeleton variant="rounded" style={{ width: '100%' }} height={17} />
                            ) : (
                              <>{row.adminName}</>
                            )}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="table-admin">
                          <strong>
                            {loading ? (
                              <Skeleton
                                variant="rounded"
                                style={{ width: '100%', marginBottom: '6rem' }}
                                height={17}
                              />
                            ) : (
                              <>{row.userName}</>
                            )}
                          </strong>
                          <span>
                            {loading ? (
                              <Skeleton variant="rounded" style={{ width: '100%' }} height={17} />
                            ) : (
                              <>{row.userEmail}</>
                            )}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell style={{ width: '100%' }}>
                        <div className="table-action">
                          {loading ? (
                            <Skeleton variant="rounded" style={{ width: '100%' }} height={17} />
                          ) : (
                            <>
                              <span className={`table-action-${row.color}`}>{row.action}</span>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row.description
                                }}
                              ></div>
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logs;
