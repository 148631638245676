import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../../../svg/ArrowBack';
import ArrowNext from '../../../../svg/ArrowNext';
import Skeleton from '@mui/material/Skeleton';
import SelectCustom from '../../../../components/selectCustom/SelectCustom';

export default function PromotionsTable({
  pages,
  activePage,
  handlePageChange,
  sorts,
  handleSelectChange,
  defaultValue,
  rows,
  loading,
  status,
  onSetOpen
}) {
  return (
    <div className="table">
      <div className="table-top">
        <div className="pagination">
          <Stack spacing={2}>
            <Pagination
              count={pages}
              siblingCount={1}
              page={activePage}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem slots={{ previous: ArrowBack, next: ArrowNext }} {...item} />
              )}
            />
          </Stack>
          <div className="pagination__page">100/Page</div>
        </div>
        <SelectCustom
          sorts={sorts}
          onSelectChange={handleSelectChange}
          defaultValue={defaultValue}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {status === 'deffered' && <TableCell>Status</TableCell>}
              <TableCell>User email</TableCell>
              <TableCell>Creative</TableCell>
              <TableCell>Primary text</TableCell>
              <TableCell>Headline text</TableCell>
              <TableCell>Website link</TableCell>
              <TableCell>Daily budget</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Locations</TableCell>
              <TableCell>Date</TableCell>
              {status != 'deffered' && <TableCell>Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from({ length: 10 }).map((_, index) => (
                  <TableRow className="table-row">
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'180rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell
                    // onClick={() => handleOpenImg(row.index)}
                    >
                      <Skeleton
                        variant="rounded"
                        width={'64rem'}
                        height={'64rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'160rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'160rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'170rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'60rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'60rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'120rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'80rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        width={'80rem'}
                        height={'17.6rem'}
                        style={{ marginBottom: '0' }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : rows.map((row) => (
                  <TableRow className="table-row">
                    {status === 'deffered' && (
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {row.promationStatus === 'ERROR' ? (
                            <div className="icon-red"></div>
                          ) : (
                            <div className="icon-gray"></div>
                          )}
                          {row.promationStatus === 'ERROR' ? (
                            <span>Error</span>
                          ) : row.promationStatus === 'PROCESSING' ? (
                            <span>Processing</span>
                          ) : (
                            <span>Skipped</span>
                          )}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <span style={{ width: '180rem' }}>{row.email}</span>
                    </TableCell>
                    <TableCell
                    // onClick={() => handleOpenImg(row.index)}
                    >
                      <div className="table-product__singleImg" style={{ width: '64rem' }}>
                        {row.promationAds &&
                          (row.promationAds.length > 0 ? (
                            <>
                              {row.promationAds[0] &&
                                row.promationAds[0].images &&
                                row.promationAds[0].images.length > 0 && (
                                  <img
                                    style={{ width: '64rem', height: '64rem', minWidth: '64rem' }}
                                    src={`${row.promationAds[0].images[0].url}`}
                                    alt=""
                                  />
                                )}
                              {row.promationAds[0] &&
                                row.promationAds[0].videos &&
                                row.promationAds[0].videos.length > 0 && (
                                  <img
                                    style={{ width: '64rem', height: '64rem', minWidth: '64rem' }}
                                    src={`${row.promationAds[0].videos[0].coverUrl}`}
                                    alt=""
                                  />
                                )}
                            </>
                          ) : (
                            <img
                              style={{ width: '64rem', height: '64rem', minWidth: '64rem' }}
                              src={`${row.promationImg[0]}`}
                              alt=""
                            />
                          ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      {row.promationAds &&
                        (row.promationAds.length > 0 ? (
                          <span style={{ width: '160rem' }}>{row.promationAds[0].primaryText}</span>
                        ) : (
                          <span style={{ width: '160rem' }}>{row.promationPrimaryText}</span>
                        ))}
                    </TableCell>
                    <TableCell>
                      {row.promationAds &&
                        (row.promationAds.length > 0 ? (
                          <span style={{ width: '160rem' }}>{row.promationAds[0].headline}</span>
                        ) : (
                          <span style={{ width: '160rem' }}>{row.promationHeadlineText}</span>
                        ))}
                    </TableCell>
                    <TableCell>
                      <span style={{ width: '170rem' }}>{row.promationSite}</span>
                    </TableCell>
                    <TableCell>
                      <span>
                        $
                        {typeof row.promationDailyBudget === 'number'
                          ? row.promationDailyBudget.toFixed(2)
                          : ''}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>{row.promationDuration} days</span>
                    </TableCell>
                    <TableCell>
                      <span style={{ width: '120rem' }}>{row.promationLocation}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.promationDate}</span>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {status === 'rejected' && <div className="icon-red"></div>}
                        {status === 'rejected' && <span>Rejected</span>}

                        {status === 'approved' && <div className="icon-green"></div>}
                        {status === 'approved' && <span>Succeeded</span>}
                        {status === 'all' && (
                          <>
                            {row.promationStatus === 'REJECTED' ? (
                              <div className="icon-red"></div>
                            ) : row.promationStatus === 'PENDING' ? (
                              <div className="icon-green"></div>
                            ) : row.promationStatus === 'ERROR' ? (
                              <div className="icon-red"></div>
                            ) : row.promationStatus === 'SKIPED' ? (
                              <div className="icon-gray"></div>
                            ) : (
                              <div className="icon-green"></div>
                            )}
                          </>
                        )}
                        {status === 'all' && (
                          <>
                            {row.promationStatus === 'REJECTED' ? (
                              <span>Rejected</span>
                            ) : row.promationStatus === 'PENDING' ? (
                              <span>On moderation</span>
                            ) : row.promationStatus === 'ERROR' ? (
                              <span>Error</span>
                            ) : row.promationStatus === 'SKIPED' ? (
                              <span>Skiped</span>
                            ) : (
                              <span>Succeeded</span>
                            )}
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
