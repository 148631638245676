import './navbar.scss';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
const Navbar = () => {
  const role = Cookies.get('role');
  return (
    <div className="navbar">
      <ul>
        {role === 'admin' && (
          <>
            <li className="active">
              <NavLink to="/users">
                Users
              </NavLink>
            </li>
            <li>
              <NavLink to="/promotions">
                Promotions
              </NavLink>
            </li>
            <li>
              <NavLink to="/promotions-history">
                Promotions History
              </NavLink>
            </li>
            <li>
              <NavLink to="/ai/ai-prompts">
                AiPromps
              </NavLink>
            </li>
            <li>
              <NavLink to="/admins">
                Admins
              </NavLink>
            </li>
            {/* <li>
                            <NavLink to='/newsletters'>
                                Newsletters
                            </NavLink>
                        </li> */}
            <li>
              <NavLink to="/logs">
                Logs
              </NavLink>
            </li>
            {/* <li>
                            <NavLink to='/analytics'>
                                Analytics
                            </NavLink>
                        </li> */}
          </>
        )}
        {role === 'support' && (
          <>
            <li className="active">
              <NavLink to="/users">
                Users
              </NavLink>
            </li>
          </>
        )}
        {role === 'ad_manager' && (
          <>
            <li className="active">
              <NavLink to="/users">
                Users
              </NavLink>
            </li>
            <li>
              <NavLink to="/promotions">
                Promotions
              </NavLink>
            </li>
          </>
        )}
        {role === 'ai_prompt_engineer' && (
          <>
            <li>
              <NavLink to="/ai/ai-prompts">
                AiPromps
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Navbar;
