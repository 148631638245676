import React, { useState, useEffect } from 'react';
import './admins.scss';
import SelectAdminsSibebar from '../../components/selectCustom/SelectAdminsSibebar';
import { useQuery } from 'react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const fetchGetRoles = async () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const url = `${config.apiBaseUrl}permissions/roles`;

  const response = await axios.get(url, {
    headers
  });
  return response.data;
};

const fetchGetPermissons = async () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const url = `${config.apiBaseUrl}permissions`;

  const response = await axios.get(url, {
    headers
  });
  return response.data;
};

const fetchGetPermissonsId = async (id) => {
  if (!id) {
    return null;
  }
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const url = `${config.apiBaseUrl}permissions/admins/${id}`;

  const response = await axios.get(url, {
    headers
  });
  return response.data;
};

const AdminsSidebar = ({
  value,
  handleOpenReset,
  handleOpen,
  activeUserData,
  defaultValue,
  setDefaultValue,
  setSnackbarOpen,
  setSnackbarText,
  setSnackbarCount,
  onSetSidebarOpen
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [btnDisable, setBtnDisable] = useState(true);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(file);
  };

  const handleSelectChange = (selectedOption) => {
    setDefaultValue(selectedOption);
  };

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [create, setCreate] = useState();
  const [update, setUpdate] = useState();
  const [isActive, setIsActive] = useState();
  const [newAdmin, setNewAdmin] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (activeUserData && activeUserData.length > 0) {
      setName(activeUserData[1]);
      setEmail(activeUserData[2]);
      setCreate(activeUserData[3]);
      setUpdate(activeUserData[4]);
      setIsActive(activeUserData[5]);
      setNewAdmin(false);
      setBtnDisable(true);
    } else {
      setName('');
      setEmail('');
      setCreate('');
      setUpdate('');
      setNewAdmin(true);
      setBtnDisable(true);
    }
  }, [activeUserData]);

  const handleChangeName = (e) => {
    setName(e.target.value);

    if (!newAdmin) {
      setBtnDisable(e.target.value === '');
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (!newAdmin) {
      setBtnDisable(e.target.value === '');
    }
  };
  const handleChangeIsActive = (e) => {
    setIsActive(e.target.checked);
    setBtnDisable(false);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const {
    isLoading: isRolesLoading,
    error: rolesError,
    data: rolesData
  } = useQuery(['roles'], () => fetchGetRoles());

  useEffect(() => {
    if (isRolesLoading) {
    }
    if (!isRolesLoading && !rolesError && rolesData) {
      setOptions(generateOptions(rolesData.items));
    }
  }, [isRolesLoading, rolesError, rolesData]);

  const generateOptions = (users) => {
    const getColorByLength = (length) => {
      if (length <= 5) return 'yellow';
      if (length <= 10) return 'green';
      if (length <= 15) return 'orange';
      return 'blue';
    };

    const capitalizeWords = (str) => {
      return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return users.map((user) => {
      const name = capitalizeWords(user.name);
      const color = getColorByLength(name.length);
      return {
        value: user.name,
        label: name,
        color: color
      };
    });
  };

  const CreateAdmin = async () => {
    setBtnDisable(true);

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('authToken')}`
      };
      const requestBody = {
        name,
        email,
        password,
        photo: 'img1.png',
        roleName: defaultValue.value
      };
      const response = await axios.post(`${config.apiBaseUrl}admins`, requestBody, { headers });
      const { data } = response.data;
      setSnackbarOpen(true);
      setSnackbarText(`New admin <span>successfully created</span>`);
      setSnackbarCount(10);
      onSetSidebarOpen(false, '');
    } catch (e) {
      console.error(e);
    }
  };

  const updateAdmin = async () => {
    setBtnDisable(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('authToken')}`
      };
      const requestBody = {
        name,
        email,
        photo: 'img1.png',
        roleName: defaultValue.value,
        isActive: isActive
      };
      const response = await axios.patch(
        `${config.apiBaseUrl}admins/${activeUserData[0]}`,
        requestBody,
        { headers }
      );
      const { data } = response.data;
      setSnackbarOpen(true);
      setSnackbarText(`${activeUserData[0]} <span>successfully updated</span>`);
      setSnackbarCount(10);
      onSetSidebarOpen(false, '');
      updatePermitions();
    } catch (e) {
      console.error(e);
    }
  };

  const updatePermitions = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('authToken')}`
      };
      const requestBody = {
        add: toAdd,
        remove: toRemove
      };
      const response = await axios.patch(
        `${config.apiBaseUrl}permissions/admins/${activeUserData[0]}`,
        requestBody,
        { headers }
      );
      const { data } = response.data;
    } catch (e) {
      console.error(e);
    }
  };

  const buttonAnimation = (element) => {
    const tl = gsap.timeline();
    tl.to(element, {
      duration: 0.085,
      scale: 0.85,
      opacity: 0.85,
      ease: 'power4.out'
    });
    tl.to(element, {
      duration: 0.55,
      scale: 1,
      opacity: 1,
      ease: 'elastic.out(1,0.6)'
    });
  };

  const {
    isLoading: isPermissonsLoading,
    error: permissonsError,
    data: permissonsData
  } = useQuery(['permissons'], () => fetchGetPermissons());

  const [permissions, setPermissons] = useState([]);

  useEffect(() => {
    if (isPermissonsLoading) {
    }
    if (!isPermissonsLoading && !permissonsError && permissonsData) {
      setPermissons(permissonsData.items);
    }
  }, [isPermissonsLoading, permissonsError, permissonsData]);

  const {
    isLoading: isPermissonsIdLoading,
    error: permissonsIdError,
    data: permissonsIdData
  } = useQuery(['permissonsId', activeUserData], () => fetchGetPermissonsId(activeUserData[0]));

  const [permissionsId, setPermissonsId] = useState([]);

  useEffect(() => {
    if (isPermissonsIdLoading) {
    }
    if (!isPermissonsIdLoading && !permissonsIdError && permissonsIdData) {
      setPermissonsId(permissonsIdData.items);
    }
  }, [isPermissonsIdLoading, permissonsIdError, permissonsIdData]);

  const isPermissionChecked = (permissionName) => {
    return permissionsId.some((perm) => perm.permission === permissionName);
  };

  const groupPermissionsBySubject = (permissions) => {
    return permissions.reduce((grouped, permission) => {
      const { subject, name, description } = permission;
      const isChecked = isPermissionChecked(name); // Check if the permission is checked

      if (!grouped[subject]) {
        grouped[subject] = [];
      }

      grouped[subject].push({
        ...permission,
        isChecked
      });

      return grouped;
    }, {});
  };

  const [checkedPermissions, setCheckedPermissions] = useState({});

  useEffect(() => {
    setCheckedPermissions(groupPermissionsBySubject(permissions));
  }, [permissions, permissionsId]);

  const handleCheckboxChange = (subject, name) => {
    setCheckedPermissions((prevState) => {
      const updatedSubjectGroup = prevState[subject].map((perm) =>
        perm.name === name ? { ...perm, isChecked: !perm.isChecked } : perm
      );

      return {
        ...prevState,
        [subject]: updatedSubjectGroup
      };
    });
    setBtnDisable(false);
  };

  const getCheckedPermissionNames = () => {
    const checkedNames = [];
    for (const subject in checkedPermissions) {
      checkedPermissions[subject].forEach((perm) => {
        if (perm.isChecked) {
          checkedNames.push(perm.name);
        }
      });
    }
    return checkedNames;
  };

  const calculateAddRemovePermissions = () => {
    const checkedNames = getCheckedPermissionNames();

    const currentPermissions = new Set(permissionsId.map((perm) => perm.permission));
    const checkedSet = new Set(checkedNames);

    const toAdd = checkedNames.filter((name) => !currentPermissions.has(name));
    const toRemove = permissionsId
      .filter((perm) => !checkedSet.has(perm.permission))
      .map((perm) => perm.permission);

    return { toAdd, toRemove };
  };

  const { toAdd, toRemove } = calculateAddRemovePermissions();

  useEffect(() => {
    if (newAdmin) {
      if (!name || !email || !password || !confirmPassword || !defaultValue.value) {
        return setBtnDisable(true);
      }
      if (password !== confirmPassword) {
        return setBtnDisable(true);
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        return setBtnDisable(true);
      }
      setBtnDisable(false);
    }
  }, [name, email, password, confirmPassword, defaultValue.value, checkedPermissions]);
  return (
    <div className="admins-sidebar">
      <div className="admins-sidebar-top">
        Admin id: {activeUserData[0]}
        {value === 0 && !newAdmin && (
          <div className="admins-sidebar-top-right">
            <div className="admins-sidebar-top-active">
              Account active
              <label>
                <input type="checkbox" checked={isActive} onChange={handleChangeIsActive} />
                <div className="admins-sidebar-top-checkbox">
                  <span></span>
                </div>
              </label>
            </div>
            <ul>
              <li onClick={(e) => handleOpenReset(e)}>
                <svg
                  style={{ pointerEvents: 'none' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.1028 9.5547V8.16487C12.1028 6.97143 11.1314 6 9.93794 6C8.74451 6 7.77307 6.97143 7.77307 8.16487V9.5547C7.32972 9.66438 7 10.0624 7 10.5399V12.978C7 13.5403 7.45583 13.9961 8.01807 13.9961H11.8585C12.4207 13.9961 12.8765 13.5403 12.8765 12.978V10.5399C12.8759 10.0624 12.5462 9.66438 12.1028 9.5547ZM9.93794 7.18293C10.4792 7.18293 10.9199 7.62365 10.9199 8.16487V9.52185H8.956V8.16487C8.956 7.62365 9.39673 7.18293 9.93794 7.18293Z"
                    fill="black"
                  />
                  <path
                    d="M18.2444 9.24454C17.8271 9.24454 17.4889 9.58276 17.4889 10C17.4889 14.1294 14.1294 17.4889 10.0002 17.4889C5.87062 17.4889 2.5111 14.1294 2.5111 10C2.5111 5.87062 5.87062 2.51128 10 2.51128C11.5793 2.51128 13.098 3.0079 14.3571 3.90916H13.3597C12.9425 3.90916 12.6041 4.24738 12.6041 4.6648C12.6041 5.08204 12.9425 5.42026 13.3597 5.42026H16.2687C16.6859 5.42026 17.0241 5.08204 17.0241 4.6648V1.75564C17.0241 1.33822 16.6861 1 16.2687 1C15.8513 1 15.513 1.33822 15.513 1.75564V2.88694C13.9485 1.67338 12.0156 1 10 1C7.5961 1 5.33584 1.93618 3.6361 3.6361C1.936 5.33602 1 7.5961 1 10C1 12.4039 1.936 14.6642 3.6361 16.3639C5.33584 18.064 7.5961 19 10 19C12.4037 19 14.664 18.064 16.3637 16.3639C18.0638 14.6642 19 12.4039 19 10C19 9.58276 18.6616 9.24454 18.2444 9.24454Z"
                    fill="black"
                  />
                </svg>
                <span>Reset password</span>
              </li>
              <li onClick={(e) => handleOpen(e)} className="delete">
                <svg
                  style={{ pointerEvents: 'none' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99944 1.07227C11.6968 1.07227 12.8031 1.95339 13.1578 3.57193L17.4994 3.57227C17.8939 3.57227 18.2137 3.89206 18.2137 4.28655C18.2137 4.68104 17.8939 5.00084 17.4994 5.00084H16.7102L15.5585 15.9394C15.4437 17.03 14.5241 17.858 13.4275 17.858H6.57143C5.47481 17.858 4.55515 17.03 4.44035 15.9394L3.28873 5.00084H2.49944C2.10495 5.00084 1.78516 4.68104 1.78516 4.28655C1.78516 3.89206 2.10495 3.57227 2.49944 3.57227L6.84105 3.57193C7.19574 1.95339 8.30205 1.07227 9.99944 1.07227ZM15.273 5.00084H4.72516L5.86107 15.7899C5.89934 16.1534 6.20589 16.4294 6.57143 16.4294H13.4275C13.793 16.4294 14.0995 16.1534 14.1378 15.7899L15.273 5.00084ZM9.99944 6.42941C10.3939 6.42941 10.7137 6.7492 10.7137 7.14369V14.2866C10.7137 14.681 10.3939 15.0008 9.99944 15.0008C9.60495 15.0008 9.28516 14.681 9.28516 14.2866V7.14369C9.28516 6.7492 9.60495 6.42941 9.99944 6.42941ZM12.8923 6.4303C13.2863 6.45 13.5897 6.78537 13.57 7.17936L13.2128 14.3222C13.1931 14.7162 12.8578 15.0196 12.4638 14.9999C12.0698 14.9802 11.7663 14.6449 11.786 14.2509L12.1432 7.10802C12.1629 6.71403 12.4983 6.4106 12.8923 6.4303ZM7.10663 6.4303C7.50063 6.4106 7.83599 6.71403 7.85569 7.10802L8.21284 14.2509C8.23254 14.6449 7.92911 14.9802 7.53511 14.9999C7.14111 15.0196 6.80575 14.7162 6.78605 14.3222L6.4289 7.17936C6.4092 6.78537 6.71263 6.45 7.10663 6.4303ZM9.99944 2.50084C9.09465 2.50084 8.5711 2.8252 8.31932 3.57195H11.6796C11.4278 2.8252 10.9042 2.50084 9.99944 2.50084Z"
                    fill="white"
                  />
                </svg>
                <span>Delete account</span>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="admins-sidebar-info">
        <div className="admins-sidebar-info__title">Administrator information</div>
        <div className="admins-sidebar-info-wrapper">
          <div className="admins-sidebar-info__input">
            <span>Name*</span>
            <input type="text" placeholder="Add name " value={name} onChange={handleChangeName} />
          </div>
          <div className="admins-sidebar-info__input">
            <span>User email*</span>
            <input type="text" placeholder="Add Email" value={email} onChange={handleChangeEmail} />
          </div>

          {newAdmin ? (
            <>
              <div className="admins-sidebar-info__input">
                <span>Password*</span>
                <div className="input">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChangePassword}
                    placeholder="Create password"
                  />
                  <i onClick={toggleShowPassword}>
                    <svg
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0006 0.710938C14.7102 0.710938 19.0363 4.29116 19.0363 6.99665C19.0363 9.70215 14.7102 13.2824 10.0006 13.2824C5.29094 13.2824 0.964844 9.70215 0.964844 6.99665C0.964844 4.29116 5.29094 0.710938 10.0006 0.710938ZM10.0006 2.28237C6.10006 2.28237 2.53627 5.2317 2.53627 6.99665C2.53627 8.7616 6.10006 11.7109 10.0006 11.7109C13.9011 11.7109 17.4648 8.7616 17.4648 6.99665C17.4648 5.2317 13.9011 2.28237 10.0006 2.28237ZM10.0006 3.85379C11.7363 3.85379 13.1434 5.2609 13.1434 6.99665C13.1434 8.7324 11.7363 10.1395 10.0006 10.1395C8.26481 10.1395 6.8577 8.7324 6.8577 6.99665C6.8577 5.2609 8.26481 3.85379 10.0006 3.85379ZM10.0006 5.42522C9.13268 5.42522 8.42913 6.12878 8.42913 6.99665C8.42913 7.86453 9.13268 8.56808 10.0006 8.56808C10.8684 8.56808 11.572 7.86453 11.572 6.99665C11.572 6.12878 10.8684 5.42522 10.0006 5.42522Z"
                        fill="#A7A8AA"
                      />
                    </svg>
                  </i>
                </div>
              </div>
              <div className="admins-sidebar-info__input">
                <span>Confirm password*</span>
                <div className="input">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={handleChangeConfirmPassword}
                    placeholder="Confirm password "
                  />
                  <i onClick={toggleShowPassword}>
                    <svg
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0006 0.710938C14.7102 0.710938 19.0363 4.29116 19.0363 6.99665C19.0363 9.70215 14.7102 13.2824 10.0006 13.2824C5.29094 13.2824 0.964844 9.70215 0.964844 6.99665C0.964844 4.29116 5.29094 0.710938 10.0006 0.710938ZM10.0006 2.28237C6.10006 2.28237 2.53627 5.2317 2.53627 6.99665C2.53627 8.7616 6.10006 11.7109 10.0006 11.7109C13.9011 11.7109 17.4648 8.7616 17.4648 6.99665C17.4648 5.2317 13.9011 2.28237 10.0006 2.28237ZM10.0006 3.85379C11.7363 3.85379 13.1434 5.2609 13.1434 6.99665C13.1434 8.7324 11.7363 10.1395 10.0006 10.1395C8.26481 10.1395 6.8577 8.7324 6.8577 6.99665C6.8577 5.2609 8.26481 3.85379 10.0006 3.85379ZM10.0006 5.42522C9.13268 5.42522 8.42913 6.12878 8.42913 6.99665C8.42913 7.86453 9.13268 8.56808 10.0006 8.56808C10.8684 8.56808 11.572 7.86453 11.572 6.99665C11.572 6.12878 10.8684 5.42522 10.0006 5.42522Z"
                        fill="#A7A8AA"
                      />
                    </svg>
                  </i>
                </div>
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="admins-sidebar-info__input">
                <span>Create at</span>
                <input type="text" value={create} />
              </div>
              <div className="admins-sidebar-info__input">
                <span>Update at</span>
                <input type="text" value={update} />
              </div>
            </>
          )}

          <div className="admins-sidebar-info__input admins-sidebar-info__input--file">
            <span>Photo</span>
            <label>
              <input type="file" accept="image/*" onChange={handlePhotoChange} />
              {selectedPhoto ? (
                <div>
                  <img src={URL.createObjectURL(selectedPhoto)} alt="Selected" />
                </div>
              ) : (
                <svg
                  width="148"
                  height="148"
                  viewBox="0 0 148 148"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="147" height="147" rx="10.5" fill="white" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="147"
                    height="147"
                    rx="10.5"
                    stroke="#CAD4DD"
                    stroke-dasharray="4 4"
                  />
                  <g clipPath="url(#clip0_8_567)">
                    <rect x="73" y="64" width="1.5" height="20" rx="0.75" fill="#8F969C" />
                    <rect
                      x="84"
                      y="73"
                      width="1.5"
                      height="20"
                      rx="0.75"
                      transform="rotate(90 84 73)"
                      fill="#8F969C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8_567">
                      <rect width="20" height="20" fill="white" transform="translate(64 64)" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="admins-sidebar-role">
        <div className="admins-sidebar-role__title">Role & Permissions</div>
        <div className="admins-sidebar-role-top">
          <span>Role</span>
          <SelectAdminsSibebar
            onSelectChange={handleSelectChange}
            defaultValue={defaultValue}
            options={options}
          />
        </div>

        <div className="admins-sidebar-role-wrapper">
          {Object.keys(checkedPermissions).map((subject) => (
            <div className="admins-sidebar-role-column" key={subject}>
              <div className="admins-sidebar-role-item__title">{subject}</div>
              <div className="admins-sidebar-role-item">
                {checkedPermissions[subject].map((permission) => (
                  <label key={permission.name}>
                    <input
                      type="checkbox"
                      checked={permission.isChecked || false}
                      onChange={() => handleCheckboxChange(subject, permission.name)}
                    />
                    <div className="admins-sidebar-role-item__icon">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.32813 6.04723L1.96523 3.68433C1.79088 3.50999 1.50822 3.50999 1.33388 3.68433C1.15954 3.85867 1.15954 4.14133 1.33388 4.31567L4.01245 6.99424C4.18679 7.16859 4.46946 7.16859 4.6438 6.99424L10.0009 1.6371C10.1753 1.46276 10.1753 1.1801 10.0009 1.00576C9.8266 0.831415 9.54394 0.831415 9.3696 1.00576L4.32813 6.04723Z"
                          fill="white"
                          stroke="white"
                          strokeWidth="0.78125"
                        />
                      </svg>
                    </div>
                    <span>{permission.description}</span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {newAdmin ? (
        <div className={`admins-sidebar__btn ${btnDisable ? 'disable' : ''}`} onClick={CreateAdmin}>
          Create
        </div>
      ) : (
        <div className={`admins-sidebar__btn ${btnDisable ? 'disable' : ''}`} onClick={updateAdmin}>
          Save
        </div>
      )}
    </div>
  );
};

export default AdminsSidebar;
