import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

export const fetchAdminsData = async (activePage, selectedSort) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const skip = activePage * 10 - 10;
  const url = `${config.apiBaseUrl}admins`;

  const params = {
    skip: skip,
    limit: 100,
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, {
    headers,
    params
  });
  return response.data.data;
};
