import React from 'react';
import './style.scss';
import Fancybox from '../../../promotionsNew/components/modal-Creatives/fancybox';

function VideoImages({ data }) {
  return (
    <Fancybox>
      <div className="videos-images">
        <div className="videos-images-container">
          <div>
            <div className="videos-images__title">Images</div>
            <div className="videos-images-wrapper">
              {data?.data?.media?.map((img, index) => (
                <div className="videos-images__img">
                  <img src={img.url} alt="" />
                  <span>{index}</span>
                </div>
              ))}

              {/* <div className="videos-images__img videos-images__video " >
                            <img src='/img/pi2.png' alt="" />
                            <span>2</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="18" height="18" rx="9" fill="white" />
                                <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="black" stroke-opacity="0.05" />
                                <path d="M13.2002 9.50565C13.5999 9.70366 13.5999 10.2963 13.2002 10.4943L8.25092 12.9463C7.90233 13.119 7.5 12.8541 7.5 12.452L7.5 7.54805C7.5 7.14586 7.90233 6.881 8.25092 7.0537L13.2002 9.50565Z" fill="black" />
                            </svg>
                            <i>0:16</i>
                        </div> */}
            </div>
          </div>
          <div>
            <div className="videos-images__title">Video</div>
            <a href={data?.url} data-fancybox="gallery" className="videos-images-video">
              <img src={data?.coverUrl} alt="" />
            </a>
          </div>
        </div>
      </div>
    </Fancybox>
  );
}

export default VideoImages;
