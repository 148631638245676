import React, { useEffect, useState } from 'react';
import PromotionsFilter from '../promotions-filter/PromotionsFilter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../../../svg/ArrowBack';
import ArrowNext from '../../../../svg/ArrowNext';
import SelectCustom from '../../../../components/selectCustom/SelectCustom';
import './style.scss';
import { fetchAdminsList } from '../promotions-filter/PromotionsApi';
import { useQuery, useQueryClient } from 'react-query';

export default function PromotionsSidebar() {
  const {
    isLoading: isAdminsListDataLoading,
    error: adminsListDataError,
    data: adminsListData
  } = useQuery(['adminsList'], () => fetchAdminsList());

  const [moderId, setModerId] = useState('');

  const [moderators, setmoderators] = useState([]);

  useEffect(() => {
    setmoderators(
      moderId != ''
        ? adminsListData
            ?.filter((admin) => admin.id === moderId)
            .map((admin) => ({
              ...admin
            }))
        : adminsListData?.map((admin) => ({
            ...admin
          })) || []
    );
  }, [adminsListData, moderId]);

  const defaultValue = { value: 'createdAt', label: 'Newest' };
  const sorts = [
    { value: 'createdAt', label: 'Newest' },
    { value: 'createdAt1', label: 'Oldest' }
  ];
  return (
    <div className="promotions-sidebar-wrapper">
      <div className="promotions-sidebar-filter">
        <PromotionsFilter showStatus={false} setModerId={setModerId} />
      </div>

      <div className="table">
        <div className="table-top">
          <div className="promotions-sidebar__title">Moderators</div>
          <SelectCustom
            sorts={sorts}
            // onSelectChange={handleSelectChange}
            defaultValue={defaultValue}
          />
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Moderator</TableCell>
                <TableCell>Approved</TableCell>
                <TableCell>Rejected</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {moderators.map((moderator, index) => (
                <TableRow className="table-row">
                  <TableCell>
                    <span>{moderator.email}</span>
                  </TableCell>
                  <TableCell>
                    <span className="promotions-sidebar-table-row">
                      <div className="green"></div>
                      {moderator.APPROVED}

                      {moderator.APPROVED > moderator.REJECTED && (
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_9076_13435)">
                            <path
                              d="M8.00696 3.008V13.0013M4.01562 7.01067L8.00029 3L11.985 7.01067"
                              stroke="#17CC37"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_9076_13435">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      {moderator.APPROVED < moderator.REJECTED && (
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.99304 12.992L7.99304 2.99867M11.9844 8.98933L7.99971 13L4.01504 8.98933"
                            stroke="#F43535"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="promotions-sidebar-table-row">
                      <div className="red"></div>
                      {moderator.REJECTED}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div className="pagination">
                    <Stack spacing={2}>
                        <Pagination
                            count={25}
                            siblingCount={1}
                            page={1}
                            // onChange={handlePageChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{ previous: ArrowBack, next: ArrowNext }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                    <div className="pagination__page">100/Page</div>
                </div> */}
      </div>
    </div>
  );
}
