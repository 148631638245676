import React, { useState, useEffect } from 'react';
import './videos.scss';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import Cookies from 'js-cookie';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import config from '../../config';
import ProductsInfo from './components/videos-info/VideosInfo';
import ModalVideos from './components/modal-videos/ModalVideos';
import VideosTable from './components/videos-table/VideosTable';
import VideosInfo from './components/videos-info/VideosInfo';
import Loader from '../../components/loader';
import ResponseError from '../../components/response-error';
import EmptyList from '../../components/empty-list';

const limit = 400;

const fetchUserData = async (activeUserId, activePage, selectedSort) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/advideos`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const skip = activePage * limit - limit;
  const params = {
    skip: skip,
    limit: limit,
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, { headers, params });
  return response.data;
};

const fetchStatData = async (activeUserId) => {
  if (!activeUserId) {
    return null;
  }
  const url = `${config.apiBaseUrl}admins/users/${activeUserId}/external-products-statistic`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

const Videos = ({ activeUserId, isActive, setCount }) => {
  const [activePage, setActivePage] = useState(1);
  const [rows, setRows] = useState([]);

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const sorts = [
    { value: 'createdAt', label: 'Created Date' }
    // { value: 'price', label: 'Price' },
    // { value: 'oldPrice', label: 'Old Price' },
    // { value: 'title', label: 'Title' },
  ];

  const defaultValue = { value: 'createdAt', label: 'Created Date' };

  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });

  const handleSelectChange = (selectedOption) => {
    if (
      selectedOption.value == 'price' ||
      selectedOption.value == 'oldPrice' ||
      selectedOption.value == 'title'
    ) {
      setSelectedSort({ [selectedOption.value]: 'asc' });
    } else {
      setSelectedSort({ [selectedOption.value]: 'desc' });
    }
  };

  const [total, setTotal] = useState('');
  const pages = Math.ceil(total / limit);

  const {
    isLoading: isLoading,
    error: error,
    data: videosData,
    refetch,
    isFetching
  } = useQuery(['videosData', activeUserId, activePage, selectedSort], () =>
    fetchUserData(activeUserId, activePage, selectedSort)
  );

  const {
    isLoading: productsStatLoading,
    error: statError,
    data: productsStatData
  } = useQuery(['productsStatData', activeUserId], () => fetchStatData(activeUserId));

  const [showBlock, setShowBlock] = useState(true);

  useEffect(() => {
    if (!isLoading && videosData) {
      setTotal(videosData.data.total);
      setCount(videosData.data.total);
    }
  }, [videosData]);

  // useEffect(() => {
  //     getUsers();
  //     getStats();
  // }, [activeUserId, selectedSort]);

  const openWeb = (website) => {
    window.open(website, '_blank');
  };

  const [openBlock, setOpenBlock] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleOpenBlock = (row) => {
    setOpenBlock(true);
    setModalData(row);
  };

  if (!isActive) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <ResponseError refetch={refetch} />;
  }
  if (!videosData?.data?.items || !videosData?.data?.items.length) {
    return <EmptyList />;
  }

  return (
    <div className="videos">
      {/* <VideosInfo data={productsStatData} /> */}
      <div className="table">
        <div className="table-top">
          {/* <div className="sidebars-tab__title">Video</div> */}
          <div className="table-top-right">
            <SelectCustom
              sorts={sorts}
              onSelectChange={handleSelectChange}
              defaultValue={defaultValue}
            />
          </div>
        </div>
        <VideosTable
          rows={videosData?.data?.items}
          isLoading={isLoading || isFetching}
          handleOpenBlock={handleOpenBlock}
          error={error}
          refetch={refetch}
        />
        {pages > 1 && (
          <div className="table-bottom">
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={pages}
                  siblingCount={1}
                  page={activePage}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem slots={{ previous: ArrowBack, next: ArrowNext }} {...item} />
                  )}
                />
              </Stack>

              <div className="pagination__page">20/Page</div>
            </div>
          </div>
        )}
      </div>
      <ModalVideos data={modalData} openBlock={openBlock} setOpenBlock={setOpenBlock} />
    </div>
  );
};

export default Videos;
