import React from 'react';
import './select.scss';
import Select from 'react-select';
import Arrow from '../../svg/Arrow';

const SelectCustom = ({ sorts, onSelectChange, defaultValue }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided
      // Add any necessary styles for the control (root element) here.
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#fff' : state.isFocused ? '#F2F4F6' : '#fff',
      color: state.isSelected ? '#000' : state.isFocused ? '#626264' : '#A7A8AA',
      whiteSpace: 'nowrap',
      padding: '12rem 20rem',
      borderBottom: '1rem solid #F2F4F6',
      fontFamily: 'Italian Plate No2 Expanded',
      fontWeight: 500,
      fontSize: '14rem',
      ':last-child': {
        borderBottom: 'none',
        marginBottom: '0'
      },
      ':active': {
        backgroundColor: '#F2F4F6'
      }
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      overflowY: 'hidden',
      padding: '0',
      borderRadius: '8rem',
      border: '1rem solid var(--Gray-light, #F2F4F6)',
      boxShadow: '0rem 7rem 41rem -6rem rgba(0, 0, 0, 0.08)'
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      width: '100%'
    }),
    singleValue: () => ({
      color: '#000',
      fontFamily: 'Italian Plate No2 Expanded',
      fontSize: '14rem',
      fontWeight: 600,
      gridArea: '1/1/2/3',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: ' nowrap',
      paddingLeft: '48rem',
      width: '134rem'
    }),
    group: (provided) => ({
      ...provided,
      paddingTop: 0
    })
  };

  const customComponents = {
    DropdownIndicator: (props) => (
      <div className="custom-dropdown-indicator">
        <Arrow width="16rem" height="16rem" />
      </div>
    )
  };

  return (
    <div className="select">
      <div className="select-label">Sort by:</div>
      <Select
        options={sorts}
        styles={customStyles}
        components={customComponents}
        defaultValue={defaultValue}
        className="select-item"
        onChange={(selectedOption) => onSelectChange(selectedOption)}
      />
    </div>
  );
};

export default SelectCustom;
