import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import './style.scss';
import InputMask from 'react-input-mask';
import { useQuery, useQueryClient } from 'react-query';
import { fetchAdminsList } from './PromotionsApi';

export default function PromotionsFilter({ showStatus, onSetOpen, setModerId }) {
  const {
    isLoading: isAdminsListDataLoading,
    error: adminsListDataError,
    data: adminsListData
  } = useQuery(['adminsList'], () => fetchAdminsList());

  const [checkboxesStatus, setCheckboxesStatus] = useState([
    { id: 1, label: 'All', checked: false },
    { id: 2, label: 'Rejected', checked: false }
  ]);

  const [isStatusShow, setIsStatusShow] = useState(false);

  const dropdownStatusRef = useRef(null);

  const handleClickOutsideStatus = (event) => {
    if (dropdownStatusRef.current && !dropdownStatusRef.current.contains(event.target)) {
      setIsStatusShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideStatus);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideStatus);
    };
  }, []);

  const handleCheckboxStatusChange = (id) => {
    setCheckboxesStatus(
      checkboxesStatus.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: true } : { ...checkbox, checked: false }
      )
    );
  };

  const statusHide = () => {
    setIsStatusShow(false);
  };

  const statusShow = () => {
    setIsStatusShow(true);
  };

  const [checkboxesModerators, setCheckboxesModerators] = useState([]);

  useEffect(() => {
    setCheckboxesModerators(
      adminsListData?.map((admin) => ({
        id: admin.id,
        label: admin.name,
        checked: false
      }))
    );
  }, [adminsListData]);

  const [isModeratorsShow, setIsModeratorsShow] = useState(false);

  const dropdownModeratorsRef = useRef(null);

  const handleClickOutsideModerators = (event) => {
    if (dropdownModeratorsRef.current && !dropdownModeratorsRef.current.contains(event.target)) {
      setIsModeratorsShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideModerators);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModerators);
    };
  }, []);

  const handleCheckboxModeratorsChange = (id) => {
    if (setModerId) {
      setModerId(id);
    }
    setCheckboxesModerators(
      checkboxesModerators.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: true } : { ...checkbox, checked: false }
      )
    );
  };

  const moderatorsHide = () => {
    setIsModeratorsShow(false);
  };

  const moderatorsShow = () => {
    setIsModeratorsShow(true);
  };

  const [startDate, setStartDate] = useState(new Date('2023-01-1'));
  const [endDate, setEndDate] = useState(new Date());
  const [endDateRequest, setEndDateRequest] = useState(new Date());

  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const monthStart = padZero(startDate.getMonth() + 1);
  const dayStart = padZero(startDate.getDate());
  const yearStart = startDate.getFullYear();
  const yearStartShort = padZero(startDate.getFullYear() % 100);

  const startDateFormattedDate = `${dayStart}.${monthStart}.${yearStart}`;

  const monthEnd = padZero(endDateRequest.getMonth() + 1);
  const dayEnd = padZero(endDateRequest.getDate());
  const yearEnd = endDateRequest.getFullYear();
  const yearEndShort = padZero(endDateRequest.getFullYear() % 100);
  const endDateFormattedDate = `${dayEnd}.${monthEnd}.${yearEnd}`;

  const [dateChange, setDateChange] = useState(false);
  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    setStartDate(firstDayOfMonth);
  }, []);

  const [isBlockVisible, setIsBlockVisible] = useState(false);

  const dropdownDateRef = useRef(null);

  const handleClickOutsideDate = (event) => {
    if (dropdownDateRef.current && !dropdownDateRef.current.contains(event.target)) {
      setIsBlockVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDate);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDate);
    };
  }, []);

  const dateHide = () => {
    setIsBlockVisible(false);
  };

  const dateShow = () => {
    setIsBlockVisible(true);
  };

  const dataPickerLogoutRef = useRef(null);

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate) {
      setEndDateRequest(newEndDate);
      setDateChange(true);
    }
  };

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const handleInputChange = (e, setTime) => {
    setTime(e.target.value);
  };

  const [isTimeShow, setIsTimeShow] = useState(false);

  const dropdownTimeRef = useRef(null);

  const handleClickOutsideTime = (event) => {
    if (dropdownTimeRef.current && !dropdownTimeRef.current.contains(event.target)) {
      setIsTimeShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideTime);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTime);
    };
  }, []);

  const timeHide = () => {
    setIsTimeShow(false);
  };

  const timeShow = () => {
    setIsTimeShow(true);
  };

  const handleClear = () => {
    setStartTime('');
    setEndTime('');
    setDateChange(false);
    if (setModerId) {
      setModerId('');
    }
    setCheckboxesModerators(
      checkboxesModerators.map((checkbox) => ({
        ...checkbox,
        checked: false
      }))
    );
    setCheckboxesStatus(checkboxesStatus.map((checkbox) => ({ ...checkbox, checked: false })));
  };
  return (
    <div className="promotions-filter">
      {showStatus && (
        <div className="promotions-filter-select">
          <div className="promotions-filter-select-wrap" onClick={statusShow}>
            <span>Ad status</span>
            {checkboxesStatus.find((checkbox) => checkbox.checked) && (
              <strong>{checkboxesStatus.find((checkbox) => checkbox.checked)?.label}</strong>
            )}
            <div className={`promotions-filter-select__arrow ${isStatusShow && 'active'}`}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 6L8 11L3 6"
                  stroke="#ABABAB"
                  strokeWidth="1.9"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
          {isStatusShow && (
            <div className="promotions-filter-dropdown" ref={dropdownStatusRef}>
              <div className="promotions-filter-dropdown__title">Ad status</div>
              {checkboxesStatus.map((checkbox) => (
                <div
                  key={checkbox.id}
                  className={`promotions-filter-checkbox ${checkbox.checked ? 'active' : ''}`}
                  onClick={() => handleCheckboxStatusChange(checkbox.id)}
                >
                  <div className="promotions-filter-checkbox__icon">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66016 7.04723L1.29726 4.68433C1.12292 4.50999 0.840253 4.50999 0.665912 4.68433C0.491571 4.85867 0.491571 5.14133 0.665912 5.31567L3.34448 7.99424C3.51883 8.16859 3.80149 8.16859 3.97583 7.99424L9.33297 2.6371C9.50731 2.46276 9.50731 2.1801 9.33297 2.00576C9.15863 1.83141 8.87597 1.83141 8.70163 2.00576L3.66016 7.04723Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.78125"
                      />
                    </svg>
                  </div>
                  <span>{checkbox.label}</span>
                </div>
              ))}
              <div className="promotions-filter-dropdown__btn" onClick={statusHide}>
                Select
              </div>
            </div>
          )}
        </div>
      )}
      <div className="promotions-filter-select">
        <div className="promotions-filter-select-wrap" onClick={moderatorsShow}>
          <span>Moder</span>
          {checkboxesModerators.find((checkbox) => checkbox.checked) && (
            <strong>{checkboxesModerators.find((checkbox) => checkbox.checked)?.label}</strong>
          )}
          <div className={`promotions-filter-select__arrow ${isModeratorsShow && 'active'}`}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13 6L8 11L3 6" stroke="#ABABAB" strokeWidth="1.9" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        {isModeratorsShow && (
          <div className="promotions-filter-dropdown" ref={dropdownModeratorsRef}>
            <div className="promotions-filter-dropdown__title">Moderators</div>
            <div className="promotions-filter-dropdown-wrap">
              {' '}
              {checkboxesModerators.map((checkbox) => (
                <div
                  key={checkbox.id}
                  className={`promotions-filter-checkbox ${checkbox.checked ? 'active' : ''}`}
                  onClick={() => handleCheckboxModeratorsChange(checkbox.id)}
                >
                  <div className="promotions-filter-checkbox__icon">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66016 7.04723L1.29726 4.68433C1.12292 4.50999 0.840253 4.50999 0.665912 4.68433C0.491571 4.85867 0.491571 5.14133 0.665912 5.31567L3.34448 7.99424C3.51883 8.16859 3.80149 8.16859 3.97583 7.99424L9.33297 2.6371C9.50731 2.46276 9.50731 2.1801 9.33297 2.00576C9.15863 1.83141 8.87597 1.83141 8.70163 2.00576L3.66016 7.04723Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.78125"
                      />
                    </svg>
                  </div>
                  <span>{checkbox.label}</span>
                </div>
              ))}
            </div>
            <div className="promotions-filter-dropdown__btn" onClick={moderatorsHide}>
              Select
            </div>
          </div>
        )}
      </div>
      <div className="promotions-filter-select">
        <div className="promotions-filter-select-wrap" onClick={dateShow}>
          <span>Date</span>
          {dateChange && (
            <strong>
              {' '}
              {startDateFormattedDate} - {endDateFormattedDate}
            </strong>
          )}
          <div className="promotions-filter-select__icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_9068_13800)">
                <path
                  d="M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M2 6.00016H14M12.6667 2.66683H3.33333C2.59667 2.66683 2 3.2635 2 4.00016V12.6668C2 13.4035 2.59667 14.0002 3.33333 14.0002H12.6667C13.4033 14.0002 14 13.4035 14 12.6668V4.00016C14 3.2635 13.4033 2.66683 12.6667 2.66683Z"
                  stroke="#ABABAB"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_9068_13800">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        {isBlockVisible && (
          <div className="promotions-filter-dropdown" ref={dropdownDateRef}>
            <div className="analytics-top-date-block" ref={dataPickerLogoutRef}>
              <DatePicker
                selected={startDate}
                onChange={handleChange}
                selectsRange
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                inline
              />
            </div>
            <div className="promotions-filter-dropdown__btn" onClick={dateHide}>
              Select
            </div>
          </div>
        )}
      </div>
      <div className="promotions-filter-select">
        <div className="promotions-filter-select-wrap" onClick={timeShow}>
          <span>Time</span>
          {startTime && endTime && (
            <strong>
              {startTime}-{endTime}
            </strong>
          )}

          <div className={`promotions-filter-select__arrow ${isTimeShow && 'active'}`}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13 6L8 11L3 6" stroke="#ABABAB" strokeWidth="1.9" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        {isTimeShow && (
          <div className="promotions-filter-dropdown" ref={dropdownTimeRef}>
            <div className="promotions-filter-dropdown__title" style={{ textAlign: 'center' }}>
              Time Diapason
            </div>
            <div className="promotions-filter-dropdown-time">
              <InputMask
                mask="99:99"
                value={startTime}
                onChange={(e) => handleInputChange(e, setStartTime)}
                placeholder="__:__"
              ></InputMask>

              <span>—</span>
              <InputMask
                placeholder="__:__"
                mask="99:99"
                value={endTime}
                onChange={(e) => handleInputChange(e, setEndTime)}
              ></InputMask>
            </div>
            <div className="promotions-filter-dropdown__btn" onClick={timeHide}>
              Select
            </div>
          </div>
        )}
      </div>
      <div style={{ marginLeft: 'auto' }}></div>
      {(checkboxesModerators.find((checkbox) => checkbox.checked) ||
        checkboxesStatus.find((checkbox) => checkbox.checked) ||
        dateChange ||
        (startTime && endTime)) && (
        <div className="promotions-filter-clear" onClick={handleClear}>
          Clear
        </div>
      )}
      {showStatus && (
        <div
          className="promotions-filter-moderators"
          onClick={() => {
            onSetOpen(true);
          }}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="white" />
            <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#F2F4F6" />
            <g clipPath="url(#clip0_9144_13430)">
              <path
                d="M15.975 28.025C15.365 26.811 15 25.452 15 24C15 19.026 19.026 15 24 15C28.974 15 33 19.026 33 24C33 25.45 32.637 26.81 32.023 28.023M18 30.584C19.569 29.059 21.692 28.121 24.023 28.121C26.331 28.121 28.431 29.04 30 30.537C28.431 32.062 26.308 33 23.977 33C21.669 33 19.569 32.081 18 30.584Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M26.1213 19.8787C27.2929 21.0502 27.2929 22.9497 26.1213 24.1213C24.9497 25.2929 23.0502 25.2929 21.8787 24.1213C20.7071 22.9497 20.7071 21.0502 21.8787 19.8787C23.0502 18.7071 24.9497 18.7071 26.1213 19.8787Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9144_13430">
                <rect width="24" height="24" fill="white" transform="translate(12 12)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
}
