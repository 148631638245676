import React from 'react';
import { NavLink } from 'react-router-dom';

export default function AiNavbar() {
  return (
    <div className="aiPrompts-nav">
      <NavLink to="/ai/ai-prompts">
        AI Prompts
      </NavLink>
      <NavLink to="/ai/ads">
        AI Ads Generation
      </NavLink>

      <NavLink to="/ai/creatives">
        AI Creative Generation
      </NavLink>
      <NavLink to="/ai/video-script">
        AiVideoScriptGeneration
      </NavLink>
    </div>
  );
}
