import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import Search from '../../components/search/Search';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import Cookies from 'js-cookie';
import PromotionsPageBlockNew from './PromotionsPageBlockNew';
import config from '../../config';

const fetchPromationData = async (activePage, selectedSort, filter) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`
  };

  const skip = activePage * 10 - 10;
  const url = `${config.apiBaseUrl}admins/facebook-ads`;

  const params = {
    skip: skip,
    limit: 10,
    // filter:  JSON.stringify({"createdAt": {"from": "12-17-2023", "to": "12-18-2023"}}),
    // filter:  JSON.stringify({"pushToken":true}),
    // filter: JSON.stringify({createdAt: '21.10.23'}),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(selectedSort)
  };
  const response = await axios.get(url, {
    headers,
    params
  });
  return response.data.data;
};

const PromotionsPageAll = ({ status, promationsPageTotal }) => {
  const [total, setTotal] = useState('0');
  const pages = Math.ceil(total / 10);
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  function createData(
    email,
    promationImg,
    promationPrimaryText,
    promationHeadlineText,
    promationSite,
    promationBudget,
    promationDailyBudget,
    promationDuration,
    promationLocation,
    promationDate,
    promationStatus,
    promationId,
    promationAction,
    index,
    promationRejectTitle,
    promationRejectDescription,
    promationRejectedAt,
    adminEmail,
    userId,
    leadFormUrl,
    promationTime,
    promationAds,
    promotionData
  ) {
    return {
      email,
      promationImg,
      promationPrimaryText,
      promationHeadlineText,
      promationSite,
      promationBudget,
      promationDailyBudget,
      promationDuration,
      promationLocation,
      promationDate,
      promationStatus,
      promationId,
      promationAction,
      index,
      promationRejectTitle,
      promationRejectDescription,
      promationRejectedAt,
      adminEmail,
      userId,
      leadFormUrl,
      promationTime,
      promationAds,
      promotionData
    };
  }

  const [rows, setRows] = useState([]);

  const sorts = [
    { value: 'createdAt', label: 'Newest' },
    { value: 'createdAt1', label: 'Oldest' }
  ];

  const defaultValue = { value: 'createdAt', label: 'Newest' };
  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });
  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value == 'createdAt1') {
      setSelectedSort({ ['createdAt']: 'asc' });
    } else {
      setSelectedSort({ ['createdAt']: 'desc' });
    }
  };
  const [activeFilters, setActiveFilters] = useState({});
  const getActivePlatform = (filters) => {
    const activePlatform = Object.keys(filters).find(
      (key) => filters[key] === true && (key === 'android' || key === 'iOS')
    );
    return activePlatform || null;
  };

  const activePlatform = getActivePlatform(activeFilters);

  const [searchTerm, setSearchTerm] = useState('');

  const filter = {};

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  if (searchTerm !== '') {
    filter.email = searchTerm;
  }
  if (status == 'rejected') {
    filter.status = 'REJECTED';
  }

  if (status == 'approved') {
    filter.status = 'APPROVED';
  }

  if (status == 'deffered') {
    filter.status = ['ERROR', 'SKIPED', 'PROCESSING'];
  }

  if (activePlatform !== null) {
    filter.os = activePlatform;
  }

  if (activeFilters.createdDate == true) {
    filter.createdAt = { from: '', to: '' };
  }

  const queryClient = useQueryClient();
  const refetch = () => {
    queryClient.refetchQueries(['promationAllData', activePage, selectedSort, filter]);
  };

  const {
    isLoading: isPromationAllLoading,
    error: promationAllError,
    data: promationAllData
  } = useQuery(['promationAllData', activePage, selectedSort, filter], () =>
    fetchPromationData(activePage, selectedSort, filter)
  );

  useEffect(() => {
    if (
      !isPromationAllLoading &&
      !promationAllError &&
      promationAllData &&
      promationAllData.items
    ) {
      setTotal(promationAllData.total);
      const data = promationAllData.items.map((ads, index) => {
        const inputDateString = ads.createdAt;
        const date = new Date(inputDateString);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
        var hours = date.getHours();
        var minutes = date.getMinutes();

        hours = (hours < 10 ? '0' : '') + hours;
        minutes = (minutes < 10 ? '0' : '') + minutes;

        var formattedTime = hours + ':' + minutes;

        const targetingLocations = ads.body.targetingLocations;
        let adminEmail = '';
        if (ads.admin) {
          adminEmail = ads.admin.email;
        }
        let promationAds = [];
        if (ads.body.ads) {
          promationAds = ads.body.ads;
        }

        return createData(
          ads.user.email,
          ads.images,
          ads.body.primaryText,
          ads.body.headline,
          ads.body.websiteUrl,
          ads.body.budget,
          ads.body.dailyBudget,
          ads.body.budget / ads.body.dailyBudget,
          targetingLocations.map((location) => location.name).join(', '),
          formatDateString(ads.createdAt),
          ads.status,
          ads.id,
          ads.body.actionButton,
          index,
          ads.rejectTitle,
          ads.rejectDescription,
          formatDate(ads.moderatedAt),
          adminEmail,
          ads.userId,
          ads.body.leadFormUrl,
          formattedTime,
          promationAds,
          ads
        );
      });

      setRows(data);
    }
  }, [isPromationAllLoading, promationAllError, promationAllData]);

  const formatDateString = (inputDateString) => {
    const date = new Date(inputDateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    return formattedDate;
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year} at ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

    return formattedDate;
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 1473,
    width: '475rem',
    outline: 'none'
  };
  const styleBlock = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1720rem',
    outline: 'none',
    maxHeight: '90vh',
    height: '90vh',
    overflow: 'hidden',
    borderRadius: '16rem'
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [openBlock, setOpenBlock] = useState(false);

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };

  const [blockIndex, setblockIndex] = useState(0);

  const handleOpenBlock = (index) => {
    setOpenBlock(true);
    setblockIndex(index);
  };

  const handleOpenImg = (index) => {
    setblockIndex(index);
    setOpen(true);
  };

  return (
    <div className="main promotionsPage">
      <Header />
      <div className="content">
        <div className="container">
          <div className="users-search">
            <Search onSearchChange={handleSearchChange} />
            {/* <Filter setActiveFilters={setActiveFilters} additionalValues={additionalValues} setFormData={setFormData} setToData={setToData} /> */}
          </div>

          <div className="table">
            <div className="table-top">
              <div className="promotionsPage-nav">
                <NavLink to="/promotions/moderation">On moderation {promationsPageTotal}</NavLink>
                <NavLink to="/promotions/deffered">Deffered</NavLink>
                <NavLink to="/promotions/rejected">Rejected</NavLink>
                <NavLink to="/promotions/approved">Approved</NavLink>
                <NavLink to="/promotions/all">All</NavLink>
              </div>

              <div className="pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={pages}
                    siblingCount={1}
                    page={activePage}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem slots={{ previous: ArrowBack, next: ArrowNext }} {...item} />
                    )}
                  />
                </Stack>
                <div className="pagination__page">100/Page</div>
              </div>
              <SelectCustom
                sorts={sorts}
                onSelectChange={handleSelectChange}
                defaultValue={defaultValue}
              />
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {status === 'deffered' && <TableCell>Status</TableCell>}

                    <TableCell>User email</TableCell>
                    <TableCell>Creative</TableCell>
                    <TableCell>Primary text</TableCell>
                    <TableCell>Headline text</TableCell>
                    <TableCell>Website link</TableCell>
                    <TableCell>Daily budget</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Locations</TableCell>
                    <TableCell>Date</TableCell>
                    {status != 'deffered' && <TableCell>Status</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isPromationAllLoading
                    ? Array.from({ length: 10 }).map((row) => (
                        <TableRow className="table-row">
                          {status === 'deffered' && (
                            <TableCell onClick={() => handleOpenBlock(row.index)}>
                              <Skeleton
                                variant="rounded"
                                width={'80rem'}
                                height={'17.6rem'}
                                style={{ marginBottom: '0' }}
                              />
                            </TableCell>
                          )}
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'180rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenImg(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'64rem'}
                              height={'64rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'160rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'160rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'170rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'60rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'60rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'120rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'80rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <Skeleton
                              variant="rounded"
                              width={'80rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : rows.map((row) => (
                        <TableRow className="table-row">
                          {status === 'deffered' && (
                            <TableCell onClick={() => handleOpenBlock(row.index)}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {row.promationStatus === 'ERROR' ? (
                                  <div className="icon-red"></div>
                                ) : (
                                  <div className="icon-gray"></div>
                                )}
                                {row.promationStatus === 'ERROR' ? (
                                  <span>Error</span>
                                ) : row.promationStatus === 'PROCESSING' ? (
                                  <span>Processing</span>
                                ) : (
                                  <span>Skipped</span>
                                )}
                              </div>
                            </TableCell>
                          )}
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <span style={{ width: '180rem' }}>{row.email}</span>
                          </TableCell>
                          <TableCell onClick={() => handleOpenImg(row.index)}>
                            <div className="table-product__singleImg" style={{ width: '64rem' }}>
                              {row.promationAds &&
                                (row.promationAds.length > 0 ? (
                                  <>
                                    {row.promationAds[0] &&
                                      row.promationAds[0].images &&
                                      row.promationAds[0].images.length > 0 && (
                                        <img
                                          style={{
                                            width: '64rem',
                                            height: '64rem',
                                            minWidth: '64rem'
                                          }}
                                          src={`${row.promationAds[0].images[0].url}`}
                                          alt=""
                                        />
                                      )}
                                    {row.promationAds[0] &&
                                      row.promationAds[0].videos &&
                                      row.promationAds[0].videos.length > 0 && (
                                        <img
                                          style={{
                                            width: '64rem',
                                            height: '64rem',
                                            minWidth: '64rem'
                                          }}
                                          src={`${row.promationAds[0].videos[0].coverUrl}`}
                                          alt=""
                                        />
                                      )}
                                  </>
                                ) : (
                                  <img
                                    style={{ width: '64rem', height: '64rem', minWidth: '64rem' }}
                                    src={`${row.promationImg[0]}`}
                                    alt=""
                                  />
                                ))}
                            </div>
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            {row.promationAds &&
                              (row.promationAds.length > 0 ? (
                                <span style={{ width: '160rem' }}>
                                  {row.promationAds[0].primaryText}
                                </span>
                              ) : (
                                <span style={{ width: '160rem' }}>{row.promationPrimaryText}</span>
                              ))}
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            {row.promationAds &&
                              (row.promationAds.length > 0 ? (
                                <span style={{ width: '160rem' }}>
                                  {row.promationAds[0].headline}
                                </span>
                              ) : (
                                <span style={{ width: '160rem' }}>{row.promationHeadlineText}</span>
                              ))}
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <span style={{ width: '170rem' }}>{row.promationSite}</span>
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <span>
                              $
                              {typeof row.promationDailyBudget === 'number'
                                ? row.promationDailyBudget.toFixed(2)
                                : ''}
                            </span>
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <span>{row.promationDuration.toFixed(2)} days</span>
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <span style={{ width: '120rem' }}>{row.promationLocation}</span>
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <span>{row.promationDate}</span>
                          </TableCell>
                          <TableCell onClick={() => handleOpenBlock(row.index)}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {status === 'rejected' && <div className="icon-red"></div>}
                              {status === 'rejected' && <span>Rejected</span>}
                              {status === 'approved' && <div className="icon-green"></div>}
                              {status === 'approved' && <span>Succeeded</span>}
                              {status === 'all' && (
                                <>
                                  {row.promationStatus === 'REJECTED' ? (
                                    <div className="icon-red"></div>
                                  ) : row.promationStatus === 'PENDING' ? (
                                    <div className="icon-green"></div>
                                  ) : row.promationStatus === 'ERROR' ? (
                                    <div className="icon-red"></div>
                                  ) : row.promationStatus === 'SKIPED' ? (
                                    <div className="icon-gray"></div>
                                  ) : row.promationStatus === 'PROCESSING' ? (
                                    <div className="icon-gray"></div>
                                  ) : (
                                    <div className="icon-green"></div>
                                  )}
                                </>
                              )}

                              {status === 'all' && (
                                <>
                                  {row.promationStatus === 'REJECTED' ? (
                                    <span>Rejected</span>
                                  ) : row.promationStatus === 'PENDING' ? (
                                    <span>On moderation</span>
                                  ) : row.promationStatus === 'ERROR' ? (
                                    <span>Error</span>
                                  ) : row.promationStatus === 'SKIPED' ? (
                                    <span>Skiped</span>
                                  ) : row.promationStatus === 'PROCESSING' ? (
                                    <span>Processing</span>
                                  ) : (
                                    <span>Succeeded</span>
                                  )}
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal"
        onClick={handleClose}>
        <Box sx={style} className="modal-container">
          <div
            className="creatives-modal-close"
            // onClick={handleClose}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10 10L26 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
              <path d="M26 10L10 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>
          <div
            className="creatives-modal__img"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1,1fr)',
              alignItems: 'center',
              gridGap: '24rem'
            }}>
            {rows[blockIndex] && rows[blockIndex].promationAds ? (
              rows[blockIndex].promationAds.length > 0 ? (
                <>
                  {rows[blockIndex].promationAds[0].images &&
                    rows[blockIndex].promationAds[0].images.length > 0 && (
                      <img
                        src={rows[blockIndex]?.promationAds[0]?.images[0]?.url || ''}
                        onClick={(e) => e.stopPropagation()}
                        alt=""
                      />
                    )}
                  {rows[blockIndex].promationAds[0].videos &&
                    rows[blockIndex].promationAds[0].videos.length > 0 && (
                      <img
                        src={`${rows[blockIndex]?.promationAds[0]?.videos[0]?.coverUrl}`}
                        onClick={(e) => e.stopPropagation()}
                        alt=""
                      />
                    )}
                </>
              ) : (
                <img
                  src={rows[blockIndex]?.promationImg[0] || ''}
                  onClick={(e) => e.stopPropagation()}
                  alt=""
                />
              )
            ) : null}
          </div>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openBlock}
        onClose={handleCloseBlock}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal">
        <Box sx={styleBlock} className="modal-container">
          <div className="creatives-modal-close" onClick={handleCloseBlock}>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10 10L26 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
              <path d="M26 10L10 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>
          {rows.length && (
            <PromotionsPageBlockNew
              refetch={refetch}
              blockStatus={rows[blockIndex]?.promationStatus || ''}
              email={rows[blockIndex]?.email || ''}
              promationImg={rows[blockIndex]?.promationImg || ''}
              promationSite={rows[blockIndex]?.promationSite || ''}
              promationDate={rows[blockIndex]?.promationDate || ''}
              promationBudget={rows[blockIndex]?.promationBudget || ''}
              promationDailyBudget={rows[blockIndex]?.promationDailyBudget || ''}
              promationDuration={rows[blockIndex]?.promationDuration || ''}
              promationAction={rows[blockIndex]?.promationAction || ''}
              promationLocation={rows[blockIndex]?.promationLocation || ''}
              promationPrimaryText={rows[blockIndex]?.promationPrimaryText || ''}
              promationHeadlineText={rows[blockIndex]?.promationHeadlineText || ''}
              promationId={rows[blockIndex]?.promationId || ''}
              promationRejectTitle={rows[blockIndex]?.promationRejectTitle || ''}
              promationRejectDescription={rows[blockIndex]?.promationRejectDescription || ''}
              promationRejectedAt={rows[blockIndex]?.promationRejectedAt || ''}
              adminEmail={rows[blockIndex]?.adminEmail || ''}
              activeUserId={rows[blockIndex]?.userId || ''}
              leadFormUrl={rows[blockIndex]?.leadFormUrl || ''}
              promationTime={rows[blockIndex]?.promationTime || ''}
              promationAds={rows[blockIndex]?.promationAds || ''}
              promationData={rows[blockIndex]?.promotionData || ''}
            />
          )}
        </Box>
      </Modal>
    </div>
    // promationImg, promationPrimaryText, promationHeadlineText, promationSite, promationBudget, promationDuration, promationLocation, promationDate, promationStatus, promationId, promationAction, index };
  );
};

export default PromotionsPageAll;
