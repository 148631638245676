import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import './style.scss';
import useFormattedDate from '../../../../hooks/useFormattedDate';

function ProductCreatives({ data }) {
  const isLoading = false;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 475
  };
  return (
    <div className="products-creatives">
      {data.creatives.map((creative, index) => (
        <div className="creatives-item" key={index}>
          <div className="creatives-item__img">
            {isLoading ? (
              <Skeleton variant="rounded" style={{ ...style, width: '100%', padding: 0 }} />
            ) : (
              <img
                style={{ ...style, width: '100%', padding: 0 }}
                src={creative.creativeImages[0].url}
              />
            )}

            <div className="creatives-item__ai">
              <span>v1.0</span>
            </div>
          </div>

          <div>
            <div className="creatives-item-info">
              <div className="creatives-item-top">
                {isLoading ? (
                  <Skeleton variant="rounded" width={'100rem'} height={'15rem'} />
                ) : (
                  <div className="creatives-item__social">Facebook Ads</div>
                )}
                {isLoading ? (
                  <Skeleton variant="rounded" width={'80rem'} height={'15rem'} />
                ) : (
                  <div className="creatives-item__size">
                    {creative.creativeImages[0].width}x{creative.creativeImages[0].height}
                  </div>
                )}
              </div>
              {isLoading ? (
                <Skeleton
                  variant="rounded"
                  style={{ marginBottom: '16rem' }}
                  width="100%"
                  height={'42rem'}
                />
              ) : (
                <div className="creatives-item__title">{creative?.data.title}</div>
              )}

              {isLoading ? (
                <Skeleton
                  variant="rounded"
                  style={{ marginBottom: '24rem' }}
                  width="100%"
                  height={15}
                />
              ) : (
                <div className="creatives-item__date">
                  Creation date: {useFormattedDate(creative.createdAt)}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductCreatives;
