import React from 'react';

const ArrowBack = () => {
  return (
    <svg
      style={{ width: '16rem', height: '16rem' }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M10 13L5 8L10 3" stroke="#ABABAB" strokeWidth="1.9" strokeLinecap="round" />
    </svg>
  );
};

export default ArrowBack;
