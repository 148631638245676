import Spinner from './Spinner';
import './index.scss';

const Loader = () => {
  return (
    <div className="loader">
      <Spinner />
      <div className="loader__title">Loading...</div>
    </div>
  );
};
export default Loader;
