import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import './admins.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import SideBarAdmins from '../../components/sidebar/SideBarAdmins';
import Snackbar from '../../components/snackbar/Snackbar';
import { fetchAdminsData } from './AdminsApi';

const Admins = ({}) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarCount, setSnackbarCount] = useState(10);

  const [total, setTotal] = useState('0');
  const pages = Math.ceil(total / 10);
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const [activeUserData, setActiveUserData] = useState([]);

  const [activeTab, setActiveTab] = useState('all');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  function createData(image, name, email, role, color, create, update, id, isActive) {
    return { image, name, email, role, color, create, update, id, isActive };
  }

  const [rows, setRows] = useState([]);

  const rowsLoading = [
    createData(
      'a1.png',
      'Dmitriy Yurievich',
      'dima@zeely.app',
      'Administrator',
      'blue',
      '01.05.2024',
      '09.05.2024',
      'XXXX-XXXX-XXXX-XXXX'
    ),
    createData(
      'a2.png',
      'Alina Bondarenko',
      'alina@zeely.app',
      'Administrator',
      'blue',
      '01.05.2024',
      '09.05.2024',
      'XXXX-XXXX-XXXX-XXXX'
    ),
    createData(
      'a3.png',
      'Yaroslav Samoiliuk ',
      'yaroslav@zeely.app',
      'Ad Moderation Support',
      'green',
      '01.05.2024',
      '10.05.2024',
      'XXXX-XXXX-XXXX-XXXX'
    ),
    createData(
      'a4.png',
      'Nikolay Ivanitskiy',
      'nikolay@zeely.app',
      'Administrator',
      'blue',
      '01.05.2024',
      '09.05.2024',
      'XXXX-XXXX-XXXX-XXXX'
    ),
    createData(
      'a5.png',
      'Arsenii Yurchenko',
      'senya@zeely.app',
      'Customer Support ',
      'yellow',
      '01.05.2024',
      '10.05.2024',
      'XXXX-XXXX-XXXX-XXXX'
    ),
    createData(
      'a6.png',
      'Veronika Gridyaeva',
      'veronika@zeely.app',
      'Advanced Support',
      'orange',
      '01.05.2024',
      '10.05.2024',
      'XXXX-XXXX-XXXX-XXXX'
    )
  ];

  const sorts = [
    { value: 'createdAt', label: 'Newest' },
    { value: 'createdAt1', label: 'Oldest' }
  ];

  const defaultValue = { value: 'createdAt', label: 'Newest' };

  const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: 'desc' });

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value == 'createdAt1') {
      setSelectedSort({ ['createdAt']: 'asc' });
    } else {
      setSelectedSort({ ['createdAt']: 'desc' });
    }
  };

  const {
    isLoading: isAdminsLoading,
    error: adminsError,
    data: adminsData
  } = useQuery(['adminsData', activePage, selectedSort], () =>
    fetchAdminsData(activePage, selectedSort)
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRows(rowsLoading);
    if (isAdminsLoading) {
      setRows(rowsLoading);
      setLoading(true);
    }
    if (!isAdminsLoading && !adminsError && adminsData) {
      const filteredAdmins = adminsData.filter((admin) => {
        if (activeTab === 'administrator') {
          return admin.roleName === 'admin';
        }
        if (activeTab === 'moderators') {
          return admin.roleName != 'admin';
        }
        return true;
      });

      const data = filteredAdmins.map((admin) =>
        createData(
          'a1.png',
          admin.name,
          admin.email,
          [capitalizeWords(admin.roleName), admin.roleName],
          getColorByLength(admin.roleName.length),
          formatDateString(admin.createdAt),
          formatDateString(admin.updatedAt),
          admin.id,
          admin.isActive
        )
      );
      setRows(data);
      setLoading(false);
    }
  }, [isAdminsLoading, adminsError, adminsData, activeTab]);

  const getColorByLength = (length) => {
    if (length <= 5) return 'yellow';
    if (length <= 10) return 'green';
    if (length <= 15) return 'orange';
    return 'blue';
  };

  const capitalizeWords = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatDateString = (inputDateString) => {
    const date = new Date(inputDateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    return formattedDate;
  };

  const [defaultValueSideBar, setDefaultValueSideBar] = useState({ value: '', label: '' });

  const onSetSidebarOpen = (open, id, name, email, create, update, role, isActive) => {
    setSidebarOpen(open);
    if (id) {
      setActiveUserData([id, name, email, create, update, isActive]);
      if (role) {
        setDefaultValueSideBar({ value: role[1], label: role[0] });
      }
    } else {
      setActiveUserData([]);
      setDefaultValueSideBar({ value: '', label: '' });
    }
  };

  function getFirstLetterUpperCase(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase();
  }

  const [open, setOpen] = useState(false);
  const handleDelete = (open, id) => {
    setOpen(open);
    setActiveUserData([id]);
  };

  return (
    <Sidebar
      sidebar={
        <SideBarAdmins
          open={open}
          setOpen={setOpen}
          onSetSidebarOpen={onSetSidebarOpen}
          activeUserData={activeUserData}
          defaultValue={defaultValueSideBar}
          setDefaultValue={setDefaultValueSideBar}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarText={setSnackbarText}
          setSnackbarCount={setSnackbarCount}
        />
      }
      open={sidebarOpen}
      onSetOpen={onSetSidebarOpen}
      pullRight={true}
      docked={false}
      sidebarClassName="sidebar"
    >
      <div className="main promotionsPage">
        <Header />
        <div className="content">
          <div className="container">
            <div className="admins-action">
              <div className="admins-action-item" onClick={() => onSetSidebarOpen(true)}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 25.9048L16 6.09525"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M25.9047 16L6.09521 16"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <div>
                  <div className="admins-action-item__title">Create new admin</div>
                  <p>
                    Create a mass distribution of push messages for all users or a separate audience
                  </p>
                </div>
              </div>
              <div className="admins-action-item">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 25.9048L16 6.09525"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M25.9047 16L6.09521 16"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <div>
                  <div className="admins-action-item__title">Manage roles</div>
                  <p>
                    Create a mass distribution of push messages for all users or a separate audience
                  </p>
                </div>
              </div>
            </div>

            <div className="table">
              <div className="table-top">
                <div className="promotionsPage-nav">
                  <a
                    className={activeTab === 'all' ? 'active' : ''}
                    onClick={() => handleTabClick('all')}
                  >
                    All
                  </a>
                  <a
                    className={activeTab === 'administrator' ? 'active' : ''}
                    onClick={() => handleTabClick('administrator')}
                  >
                    Administrator
                  </a>
                  <a
                    className={activeTab === 'moderators' ? 'active' : ''}
                    onClick={() => handleTabClick('moderators')}
                  >
                    Moderators
                  </a>
                </div>

                <div className="pagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={pages}
                      siblingCount={1}
                      page={activePage}
                      onChange={handlePageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBack, next: ArrowNext }}
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                  <div className="pagination__page">100/Page</div>
                </div>
                <SelectCustom
                  sorts={sorts}
                  onSelectChange={handleSelectChange}
                  defaultValue={defaultValue}
                />
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Photo</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>User email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Created at</TableCell>
                      <TableCell>Updated at</TableCell>
                      <TableCell>Admin id</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow className="table-row">
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'52rem'}
                              height={'52rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <div className="admin-table_img">
                              {getFirstLetterUpperCase(row.name)}
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'200rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <span style={{ width: '200rem' }}>{row.name}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'200rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <span style={{ width: '200rem' }}>{row.email}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'200rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <div style={{ width: '200rem' }} className={'table-subscription'}>
                              <span className={`icon-${row.color}`}></span>
                              {row.role[0]}
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'160rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <span style={{ width: '160rem' }}>{row.create}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'200rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <span style={{ width: '160rem' }}>{row.update}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'200rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <span style={{ width: '160rem' }}>{row.id}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="rounded"
                              width={'80rem'}
                              height={'17.6rem'}
                              style={{ marginBottom: '0' }}
                            />
                          ) : (
                            <span>
                              <svg
                                onClick={() =>
                                  onSetSidebarOpen(
                                    true,
                                    row.id,
                                    row.name,
                                    row.email,
                                    row.create,
                                    row.update,
                                    row.role,
                                    row.isActive
                                  )
                                }
                                style={{ width: '38rem', height: '38rem', marginRight: '10rem' }}
                                width="38"
                                height="38"
                                viewBox="0 0 38 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="37"
                                  height="37"
                                  rx="9.5"
                                  fill="white"
                                />
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="37"
                                  height="37"
                                  rx="9.5"
                                  stroke="#F2F4F6"
                                />
                                <g clipPath="url(#clip0_0_122)">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M19.3518 15.0477L13.332 21.0674C13.1074 21.2921 12.9658 21.5865 12.9306 21.9023L12.5337 25.4637L16.0982 25.0692C16.4143 25.0342 16.7092 24.8926 16.9341 24.6677L22.9529 18.6488L19.3518 15.0477ZM24.0843 17.5175L20.4832 13.9163L22.1936 12.2058C22.7404 11.6591 23.6268 11.6591 24.1735 12.2058L25.7948 13.8271C26.3415 14.3738 26.3415 15.2602 25.7948 15.807L24.0843 17.5175ZM25.3049 11.0744C24.1333 9.90287 22.2338 9.90287 21.0623 11.0744L12.2007 19.9361C11.7192 20.4175 11.4159 21.0484 11.3404 21.7251L10.8605 26.0318C10.7895 26.6687 11.3274 27.207 11.9643 27.1365L16.2742 26.6595C16.9517 26.5845 17.5835 26.2811 18.0655 25.7991L26.9262 16.9383C28.0977 15.7668 28.0977 13.8673 26.9262 12.6957L25.3049 11.0744Z"
                                    fill="black"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_0_122">
                                    <rect
                                      width="20"
                                      height="20"
                                      fill="white"
                                      transform="translate(9 9)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <svg
                                onClick={() => handleDelete(true, row.id)}
                                style={{ width: '38rem', height: '38rem' }}
                                width="38"
                                height="38"
                                viewBox="0 0 38 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="38" height="38" rx="10" fill="#F43535" />
                                <path
                                  d="M19 10.0714C20.6974 10.0714 21.8037 10.9526 22.1584 12.5711L26.5 12.5714C26.8945 12.5714 27.2143 12.8912 27.2143 13.2857C27.2143 13.6802 26.8945 14 26.5 14H25.7107L24.5591 24.9386C24.4443 26.0292 23.5246 26.8571 22.428 26.8571H15.572C14.4754 26.8571 13.5557 26.0292 13.4409 24.9386L12.2893 14H11.5C11.1055 14 10.7857 13.6802 10.7857 13.2857C10.7857 12.8912 11.1055 12.5714 11.5 12.5714L15.8416 12.5711C16.1963 10.9526 17.3026 10.0714 19 10.0714ZM24.2736 14H13.7257L14.8616 24.7891C14.8999 25.1526 15.2064 25.4286 15.572 25.4286H22.428C22.7935 25.4286 23.1001 25.1526 23.1384 24.7891L24.2736 14ZM19 15.4286C19.3945 15.4286 19.7143 15.7484 19.7143 16.1429V23.2857C19.7143 23.6802 19.3945 24 19 24C18.6055 24 18.2857 23.6802 18.2857 23.2857V16.1429C18.2857 15.7484 18.6055 15.4286 19 15.4286ZM21.8928 15.4295C22.2868 15.4492 22.5902 15.7845 22.5705 16.1785L22.2134 23.3214C22.1937 23.7154 21.8583 24.0188 21.4643 23.9991C21.0703 23.9794 20.7669 23.644 20.7866 23.25L21.1437 16.1072C21.1634 15.7132 21.4988 15.4098 21.8928 15.4295ZM16.1072 15.4295C16.5012 15.4098 16.8365 15.7132 16.8562 16.1072L17.2134 23.25C17.2331 23.644 16.9297 23.9794 16.5357 23.9991C16.1417 24.0188 15.8063 23.7154 15.7866 23.3214L15.4295 16.1785C15.4098 15.7845 15.7132 15.4492 16.1072 15.4295ZM19 11.5C18.0952 11.5 17.5717 11.8244 17.3199 12.5711H20.6801C20.4283 11.8244 19.9048 11.5 19 11.5Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        {snackbarOpen && (
          <Snackbar
            snackbarOpen={snackbarOpen}
            setSnackbarOpen={setSnackbarOpen}
            snackbarText={snackbarText}
            snackbarCount={snackbarCount}
            setSnackbarCount={setSnackbarCount}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default Admins;
